import React, { useState } from "react";
import { Modal, Card, Typography, message } from "antd";
import { deleteWebsiteById } from "../../api/Website";
import { useDispatch } from "react-redux";
import { fetchWebsites } from "../../store/websitesSlice";

function DeleteWebsiteModal({ isOpen, onClose, selectedWebsite }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleDeleteWebsite = async () => {
    setLoading(true);
    try {
      await deleteWebsiteById(selectedWebsite.id);
      message.success("Website deleted successfully!");
      dispatch(fetchWebsites());
      onClose();
    } catch (error) {
      message.error("Failed to delete website. Please try again.");
    }
    setLoading(false);
  };

  return (
    <Modal
      title="Delete Website"
      open={isOpen}
      onOk={handleDeleteWebsite}
      onCancel={onClose}
      okText="Delete Website"
      cancelText="Cancel"
      confirmLoading={loading}
      okButtonProps={{ danger: true }}
    >
      Are you sure you want to permanently delete this website?
      <Card title="Selected Website" size="small" style={{ marginTop: 8 }}>
        <div>
          <Typography.Text strong>Website Name: </Typography.Text>
          <span>{selectedWebsite?.name}</span>
        </div>
        <div>
          <Typography.Text strong>Website URL: </Typography.Text>
          <span>{selectedWebsite?.url}</span>
        </div>
      </Card>
    </Modal>
  );
}

export default DeleteWebsiteModal;

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getPages } from "../api/Pages";

export const usePages = (pagination, filters) => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [pages, setPages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingPages, setLoadingPages] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const fetchPages = useCallback(async () => {
    if (!selectedWebsiteId) return;

    setLoadingPages(true);

    const params = {
      page: pagination.current,
      page_size: pagination.pageSize,
      search: filters.searchTerm,
      last_days: filters.lastDays, // Assuming you want to filter by the date range
      ordering: filters.sortOrder
        ? `${filters.sortOrder === "asc" ? "" : "-"}${filters.sortField}`
        : undefined,
    };

    try {
      const data = await getPages(selectedWebsiteId, params);
      console.log("Data: ", data);
      setPages(data.results);
      setTotalPages(data.count);
      setFilteredData(data.results);
    } catch (error) {
      console.error("Failed to fetch pages:", error);
    } finally {
      setLoadingPages(false);
    }
  }, [
    selectedWebsiteId,
    pagination.current,
    pagination.pageSize,
    filters.searchTerm,
    filters.lastDays,
    filters.sortOrder,
    filters.sortField,
  ]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  return {
    pages,
    loadingPages,
    totalPages,
    filteredData,
    setFilteredData,
  };
};

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getPageRankedKeywords } from "../api/Pages";

export const usePageRankedKeywords = (
  pageId,
  pagination,
  filters,
  last_days
) => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [rankedKeywords, setRankedKeywords] = useState([]);
  const [totalRankedKeywords, setTotalRankedKeywords] = useState(0);
  const [loadingRankedKeywords, setLoadingRankedKeywords] = useState(false);
  const [error, setError] = useState(null);

  const fetchRankedKeywords = useCallback(async () => {
    if (!pageId) return;

    setLoadingRankedKeywords(true);

    const params = {
      page: pagination.current,
      page_size: pagination.pageSize,
      ordering: filters.sortOrder
        ? `${filters.sortOrder === "asc" ? "" : "-"}${filters.sortField}`
        : undefined,
      filters: { last_days: last_days },
    };

    try {
      const data = await getPageRankedKeywords(pageId, params);
      console.log("Data: ", data);
      setRankedKeywords(data.results);
      setTotalRankedKeywords(data.count);
    } catch (error) {
      console.error("Failed to fetch ranked keywords:", error);
      setError(error);
    } finally {
      setLoadingRankedKeywords(false);
    }
  }, [
    pageId,
    pagination.current,
    pagination.pageSize,
    filters.sortOrder,
    filters.sortField,
    last_days,
  ]);

  useEffect(() => {
    fetchRankedKeywords();
  }, [fetchRankedKeywords]);

  return {
    rankedKeywords,
    loadingRankedKeywords,
    totalRankedKeywords,
    error,
  };
};

// editexperimentmodal.js
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchKeywords } from "../../store/keywordsSlice"; // Adjust the path according to your structure
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Modal,
  Button,
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Typography,
  message,
} from "antd";
import moment from "moment";
import { getRelatedChangeLogs } from "../../api/Changelog";
import { updateExperimentById, getExperimentById } from "../../api/Experiments";

const EditExperimentModal = ({
  isOpen,
  onClose,
  selectedExperimentId,
  fetchExperiments,
}) => {
  const dispatch = useDispatch();
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const { keywords, status: keywordsStatus } = useSelector(
    (state) => state.keywords
  );
  const [form] = Form.useForm();
  const [testPeriod, setTestPeriod] = useState(14);
  const [customPeriod, setCustomPeriod] = useState(false);
  const [adjustDates, setAdjustDates] = useState(true);
  const [controlStartDate, setControlStartDate] = useState();
  const [controlEndDate, setControlEndDate] = useState();
  const [testStartDate, setTestStartDate] = useState();
  const [testEndDate, setTestEndDate] = useState([]);
  const [controlPages, setControlPages] = useState([]);
  const [testPages, setTestPages] = useState();
  const [relatedChangeLogs, setRelatedChangeLogs] = useState([]);
  const [firstChangeLogDate, setFirstChangeLogDate] = useState(null);
  const [lastChangeLogDate, setLastChangeLogDate] = useState(null);
  const [experimentName, setExperimentName] = useState("");
  const [experimentChangelogs, setExperimentChangelogs] = useState([]);
  const [hypothesis, setHypothesis] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const notFoundContent =
    keywordsStatus === "loading" ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spin style={{ marginRight: 8 }} />
        Loading keywords...
      </div>
    ) : (
      "No keywords found"
    );

  useEffect(() => {
    if (isOpen && keywordsStatus === "idle") {
      dispatch(fetchKeywords({})); // Fetch all keywords if they haven't been fetched yet
    }
  }, [dispatch, isOpen, keywordsStatus]);

  useEffect(() => {
    const fetchExperimentData = async () => {
      if (selectedExperimentId && isOpen) {
        setLoading(true);
        try {
          const experimentData = await getExperimentById(selectedExperimentId);
          setExperimentName(experimentData.experiment_name);
          setHypothesis(experimentData.hypothesis);
          setControlStartDate(experimentData.periods.control_period.start);
          setControlEndDate(experimentData.periods.control_period.end);
          setTestStartDate(experimentData.periods.test_period.start);
          setTestEndDate(experimentData.periods.test_period.end);
          setControlPages(
            experimentData.pages.control_period.map((page) => page.id)
          );
          setTestPages(experimentData.pages.test_period.map((page) => page.id));
          setSelectedKeywords(
            experimentData.target_keywords.map(
              (keyword) => keyword.keyword_text
            )
          );
          setExperimentChangelogs(experimentData.changelogs);
          await fetchChangeLogs(
            selectedWebsiteId,
            experimentData.changelogs[0].page.url,
            experimentData.periods.control_period.start,
            experimentData.periods.test_period.end,
            experimentData.changelogs.map((log) => log.id)
          );
          form.setFieldsValue({
            experimentName: experimentData.experiment_name,
            hypothesis: experimentData.hypothesis,
          });
        } catch (error) {
          console.error("Failed to fetch experiment data:", error);
          message.error("Failed to load experiment data.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchExperimentData();
  }, [selectedExperimentId, isOpen, form]);

  useEffect(() => {
    if (selectedExperimentId && isOpen) {
      const fetchAndUpdateLogs = async () => {
        setLoading(true);
        try {
          await fetchChangeLogs(
            selectedWebsiteId,
            experimentChangelogs[0].page.url,
            controlStartDate,
            testEndDate,
            experimentChangelogs.map((log) => log.id)
          );
        } catch (error) {
          console.error("Failed to fetch changelogs:", error);
          message.error("Failed to load changelogs.");
        } finally {
          setLoading(false);
        }
      };
      fetchAndUpdateLogs();
    }
  }, [controlStartDate, controlEndDate, testStartDate, testEndDate]);

  const fetchChangeLogs = async (
    websiteId,
    pageUrl,
    controlStartDate,
    testEndDate,
    knownChangeLogIds
  ) => {
    const allChangeLogs = await getRelatedChangeLogs(
      websiteId,
      pageUrl,
      controlStartDate,
      testEndDate
    );
    const mappedLogs = allChangeLogs.map((log) => ({
      ...log,
      isEnabled: knownChangeLogIds.includes(log.id),
    }));
    setRelatedChangeLogs(mappedLogs);

    const enabledLogs = mappedLogs.filter((log) => log.isEnabled);
    if (enabledLogs.length > 0) {
      const sortedLogs = enabledLogs.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      setFirstChangeLogDate(moment(sortedLogs[0].created_at));
      setLastChangeLogDate(
        moment(sortedLogs[sortedLogs.length - 1].created_at)
      );
    } else {
      setFirstChangeLogDate(null);
      setLastChangeLogDate(null);
    }
  };

  useEffect(() => {
    calculateDates(
      relatedChangeLogs.filter((log) => log.isEnabled),
      adjustDates
    );
  }, [relatedChangeLogs, adjustDates, customPeriod, testPeriod]);

  const toggleChangeLogEnabled = (id) => {
    setRelatedChangeLogs((prevLogs) =>
      prevLogs.map((log) =>
        log.id === id ? { ...log, isEnabled: !log.isEnabled } : log
      )
    );
  };

  const calculateDates = (logs, adjust) => {
    console.log("Ja 0");
    if (adjust) {
      console.log("Ja 1");
      if (logs.length > 0) {
        const sortedLogs = logs.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        const earliestDate = moment(sortedLogs[0].created_at);
        const latestDate = moment(sortedLogs[sortedLogs.length - 1].created_at);

        setControlStartDate(
          earliestDate.clone().subtract(testPeriod, "days").format("YYYY-MM-DD")
        );
        setControlEndDate(
          earliestDate.clone().subtract(1, "days").format("YYYY-MM-DD")
        );
        setTestStartDate(
          latestDate.clone().add(1, "days").format("YYYY-MM-DD")
        );
        setTestEndDate(
          latestDate.clone().add(testPeriod, "days").format("YYYY-MM-DD")
        );
      }
    } else {
      if (logs.length > 0) {
        const sortedLogs = logs.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        const changeDate = moment(sortedLogs[0].created_at);
        console.log("changeDate: ", changeDate);
        setControlStartDate(
          changeDate.clone().subtract(testPeriod, "days").format("YYYY-MM-DD")
        );
        setControlEndDate(
          changeDate.clone().subtract(1, "days").format("YYYY-MM-DD")
        );
        setTestStartDate(
          changeDate.clone().add(1, "days").format("YYYY-MM-DD")
        );
        setTestEndDate(
          changeDate.clone().add(testPeriod, "days").format("YYYY-MM-DD")
        );
      }
    }
  };

  const handleChangeKeywords = (newSelectedKeywords) => {
    setSelectedKeywords(newSelectedKeywords);
  };

  const handleUpdateExperiment = async () => {
    const values = await form.validateFields();
    const experimentData = {
      experiment_name: values.experimentName,
      hypothesis: hypothesis,
      control_start_date: controlStartDate,
      control_end_date: controlEndDate,
      test_start_date: testStartDate,
      test_end_date: testEndDate,
      target_keywords: selectedKeywords,
      changelogs: relatedChangeLogs
        .filter((log) => log.isEnabled)
        .map((log) => log.id),
      control_pages: controlPages,
      test_pages: testPages,
      website_id: selectedWebsiteId,
    };

    try {
      await updateExperimentById(selectedExperimentId, experimentData);
      message.success("Experiment updated successfully!");
      fetchExperiments();
      form.resetFields();
      onClose();
    } catch (error) {
      message.error("Failed to update experiment. Please try again.");
      console.log(error);
    }
  };

  return (
    <Modal
      title="Edit Experiment"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={onClose}
      okText="Update Experiment"
      cancelText="Cancel"
      width={1000}
    >
      <Form form={form} onFinish={handleUpdateExperiment}>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Card title="Experiment Name and Goals">
            <Typography.Text strong>Experiment Name:</Typography.Text>
            <Form.Item
              name="experimentName"
              rules={[{ required: true, message: "Please enter a name!" }]}
            >
              <Input placeholder="Experiment name" />
            </Form.Item>
            <Typography.Text strong>Hypothesis:</Typography.Text>
            <ReactQuill
              theme="snow"
              value={hypothesis}
              onChange={(content) => setHypothesis(content)}
              style={{ marginBottom: 8 }}
            />
            <Typography.Text strong>Target Keywords:</Typography.Text>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Select or add keywords"
              onChange={handleChangeKeywords}
              tokenSeparators={[","]}
              options={keywords.map((keyword) => ({
                label: keyword.keyword_text,
                value: keyword.keyword_text,
              }))}
              value={selectedKeywords.map((keyword) => ({
                label: keyword,
                value: keyword,
              }))}
              notFoundContent={notFoundContent}
            />
          </Card>
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>Experiment Period</span>
                <Switch
                  checkedChildren="Adjust Dates"
                  unCheckedChildren="Fixed Dates"
                  checked={adjustDates}
                  onChange={(checked) => setAdjustDates(checked)}
                />
              </div>
            }
            style={{ marginBottom: 16 }}
          >
            <Form.Item>
              <Radio.Group
                defaultValue={14}
                onChange={(e) => {
                  if (e.target.value === "custom") {
                    setCustomPeriod(true);
                  } else {
                    setCustomPeriod(false);
                    setTestPeriod(e.target.value);
                  }
                }}
                style={{ width: "100%" }}
              >
                <Radio.Button
                  value={7}
                  style={{ width: "25%", textAlign: "center" }}
                >
                  7 Days
                </Radio.Button>
                <Radio.Button
                  value={14}
                  style={{ width: "25%", textAlign: "center" }}
                >
                  14 Days
                </Radio.Button>
                <Radio.Button
                  value={30}
                  style={{ width: "25%", textAlign: "center" }}
                >
                  30 Days
                </Radio.Button>
                <Radio.Button
                  value="custom"
                  style={{ width: "25%", textAlign: "center" }}
                >
                  {customPeriod ? (
                    <Input
                      size="small"
                      type="number"
                      min={1}
                      value={testPeriod}
                      onChange={(e) => {
                        setTestPeriod(parseInt(e.target.value, 10));
                      }}
                      suffix="Days"
                      style={{ marginTop: 3 }}
                    />
                  ) : (
                    "Custom"
                  )}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div style={{ flex: "1 1 300px", textAlign: "center" }}>
                <Typography.Text strong>Control Period: </Typography.Text>
                <Typography.Text>
                  From {controlStartDate} to {controlEndDate}
                </Typography.Text>
              </div>
              <div style={{ flex: "1 1 300px", textAlign: "center" }}>
                <Typography.Text strong>Test Period: </Typography.Text>
                <Typography.Text>
                  From {testStartDate} to {testEndDate}
                </Typography.Text>
              </div>
            </div>
          </Card>
          <Card title="Related Changelogs for Page and Period">
            <Table
              columns={[
                {
                  title: "Date",
                  dataIndex: "created_at",
                  key: "date",
                  render: (text, record) => (
                    <span
                      style={record.isEnabled ? {} : { color: "lightgrey" }}
                    >
                      {moment(text).format("DD/MM/YYYY")}
                    </span>
                  ),
                },
                {
                  title: "Type",
                  dataIndex: ["changetype", "name"],
                  key: "type",
                  render: (text, record) => (
                    <span
                      style={{
                        color: record.isEnabled ? "inherit" : "lightgrey",
                      }}
                    >
                      {text}
                    </span>
                  ),
                },
                {
                  title: "Action",
                  key: "action",
                  render: (text, record) => (
                    <Button
                      type={record.isEnabled ? "danger" : "primary"}
                      onClick={() => toggleChangeLogEnabled(record.id)}
                    >
                      {record.isEnabled ? "Disable" : "Enable"}
                    </Button>
                  ),
                },
              ]}
              dataSource={relatedChangeLogs.filter(
                (log) => log.id !== selectedExperimentId.id
              )}
              rowKey="id"
              bordered
              pagination={false}
              expandedRowRender={(log) => (
                <div style={{ color: log.isEnabled ? "inherit" : "lightgrey" }}>
                  <Row>
                    <Col xs={24} md={12}>
                      <p>
                        <strong style={{ fontWeight: 500 }}>Page URL: </strong>
                        <span>{log.page.url}</span>
                      </p>
                      <p>
                        <strong style={{ fontWeight: 500 }}>Index: </strong>
                        <span>{log.index}</span>
                      </p>
                    </Col>
                    <Col xs={24} md={12}>
                      <p>
                        <strong style={{ fontWeight: 500 }}>Old Value: </strong>
                        <span>{log.old_value}</span>
                      </p>
                      <p>
                        <strong style={{ fontWeight: 500 }}>New Value: </strong>
                        <span>{log.new_value}</span>
                      </p>
                    </Col>
                  </Row>
                </div>
              )}
            />
          </Card>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditExperimentModal;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, fetchUserProfile } from "../store/userSlice";
import { useResponsive } from "../hooks/useResponsive";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from "antd";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [form] = Form.useForm();
  const { lgUp } = useResponsive();

  const handleSubmit = () => {
    dispatch(userLogin({ credentials: { username, password }, rememberMe }))
      .unwrap()
      .then(() => {
        dispatch(fetchUserProfile());
        navigate("/dashboard");
      })
      .catch((error) => {
        setErrorMessage(error); // Show error message from Redux state if needed
      });
  };

  return (
    <Row style={{ minWidth: "100%", height: "100vh" }}>
      {lgUp && (
        <Col
          md={12}
          style={{
            backgroundColor: "#0652DD",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/images/logo-white.png"
            alt="Logo"
            style={{
              top: "20px",
              left: "20px",
              maxWidth: 150,
              position: "absolute",
            }}
          />
          <img
            src="/images/illustration.png" // Make sure to have an illustration image here
            alt="Illustration"
            style={{ maxWidth: "75%", margin: "0 auto" }}
          />
          <Typography.Title level={2} style={{ color: "white" }}>
            Welcome back
          </Typography.Title>
        </Col>
      )}
      <Col
        md={lgUp ? 12 : 24}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: 400, width: "100%", margin: "0 auto" }}>
          {!lgUp && (
            <img
              src="/images/logo.png"
              alt="Logo"
              style={{
                maxWidth: "75%",
                marginBottom: "20px",
              }}
            />
          )}
          <Typography.Title level={1}>Login</Typography.Title>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                >
                  Remember Me
                </Checkbox>
                <a href="/password-reset">Forgot password?</a>
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", marginRight: 8, marginBottom: 8 }}
              >
                Login
              </Button>
              <div style={{ textAlign: "center" }}>
                Not registered? <a href="/register">Create account</a>
              </div>
            </Form.Item>
            {errorMessage && <Alert message={errorMessage} type="error" />}
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default Login;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Form, Input, Badge, Button, message } from "antd";
//import { createKeywords } from "../../api/Keywords";

function AddKeywordModal({ isOpen, onClose }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [lineCount, setLineCount] = useState(0);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      //await createKeywords(values);
      message.success("Keyword(s) added successfully!");
      form.resetFields();
      onClose();
    } catch (error) {
      message.error("Failed to add keyword(s). Please try again.");
    }
    setLoading(false);
  };

  const handleTextAreaChange = (e) => {
    const value = e.target.value;
    const valueWithNewlines = value.split(",").join("\n");
    const nonEmptyRows = valueWithNewlines
      .split("\n")
      .filter((row) => row.trim() !== "");
    setLineCount(nonEmptyRows.length);
  };

  return (
    <Modal
      title="Add New Keywords"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={onClose}
      okText="Add Keywords"
      cancelText="Cancel"
    >
      <Form form={form} onFinish={handleFormSubmit} layout="vertical">
        <Form.Item
          label={
            <>
              <span>Keywords</span>
              <Badge
                count={lineCount}
                overflowCount={5000}
                style={{ marginLeft: "10px", backgroundColor: "#0652DD" }}
              />
            </>
          }
          name="keywords_text"
          rules={[
            { required: true, message: "Please enter at least one keyword" },
          ]}
        >
          <Input.TextArea
            rows={4}
            onChange={handleTextAreaChange}
            placeholder="Enter each keyword on a new line"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddKeywordModal;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Input, Select, Segmented, Table, Typography } from "antd";
import { usePages } from "../hooks/usePages";
import PageColumns from "./Pages/PageColumns";
import AddPageModal from "./Pages/AddPageModal";
import ImportPageModal from "./Pages/ImportPageModal";
import UploadPageModal from "./Pages/UploadPageModal";

const { Search } = Input;
const { Option } = Select;

const Pages = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);

  // Set up initial pagination settings
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [filters, setFilters] = useState({
    searchTerm: "",
    lastDays: 7,
  });

  const { pages, loadingPages, totalPages, setFilteredData } = usePages(
    pagination,
    filters
  );

  const handleSearchChange = (e) => {
    setFilters((prev) => ({ ...prev, searchTerm: e.target.value }));
  };

  const handleLastDaysChange = (value) => {
    setFilters((prev) => ({ ...prev, lastDays: value }));
  };

  const handleSortChange = (pagination, filters, sorter) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    if (sorter.order) {
      const newSortField = Array.isArray(sorter.field)
        ? sorter.field.join("__")
        : sorter.field;
      const newSortOrder =
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : null;
      setFilters((prev) => ({
        ...prev,
        sortField: newSortField,
        sortOrder: newSortOrder,
      }));
    }
  };

  return (
    <div>
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        Pages
      </Typography.Title>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Search
          placeholder="Search pages"
          onChange={handleSearchChange}
          style={{ flexGrow: 1, marginRight: 8 }}
        />
        <Segmented
          options={[
            { label: "Last 7 days", value: 7 },
            { label: "Last 14 days", value: 14 },
            { label: "Last 30 days", value: 30 },
          ]}
          defaultValue={7}
          onChange={handleLastDaysChange}
          style={{ width: 300, marginRight: 8 }} // Adjust width as needed
        />
        <Button
          type="primary"
          onClick={() => setIsAddModalVisible(true)}
          style={{ marginRight: 8 }}
        >
          Add
        </Button>
        <Button
          type="primary"
          onClick={() => setIsImportModalVisible(true)}
          style={{ marginRight: 8 }}
        >
          Import
        </Button>
        <Button
          type="primary"
          onClick={() => setIsUploadModalVisible(true)}
          style={{ marginRight: 8 }}
        >
          Upload
        </Button>
        <Button type="primary">Export</Button>
      </div>
      <Table
        columns={PageColumns()}
        dataSource={pages}
        rowKey="id"
        loading={loadingPages}
        onChange={handleSortChange}
        pagination={{
          current: pagination.current,
          pagisize: pagination.pageSize,
          total: totalPages,
          onChange: (page, pageSize) =>
            setPagination({ current: page, pageSize }),
        }}
      />
      <AddPageModal
        isOpen={isAddModalVisible}
        onClose={() => setIsAddModalVisible(false)}
      />
      <ImportPageModal
        isOpen={isImportModalVisible}
        onClose={() => setIsImportModalVisible(false)}
      />
      <UploadPageModal
        isOpen={isUploadModalVisible}
        onClose={() => setIsUploadModalVisible(false)}
      />
    </div>
  );
};

export default Pages;

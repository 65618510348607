import { instance, handleError } from "./apiUtils";

export const getKeywords = async (websiteId, params) => {
  try {
    const response = await instance.get(`keywords/`, {
      params: {
        website_id: websiteId,
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userRegister } from "../store/userSlice";
import { useResponsive } from "../hooks/useResponsive";
import { Col, Form, Input, Button, Row, Typography, message } from "antd";

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.user.status);
  const [form] = Form.useForm();
  const { lgUp } = useResponsive();

  const handleSubmit = (values) => {
    dispatch(userRegister(values))
      .unwrap()
      .then(() => {
        message.success("Registration successful! Redirecting to login...");
        setTimeout(() => {
          navigate("/login");
        }, 2000); // Redirect after 2 seconds
      })
      .catch((error) => {
        message.error("Registration failed");
        console.error("Registration failed: ", error);
      });
  };

  return (
    <Row style={{ minWidth: "100%", height: "100vh" }}>
      {lgUp && (
        <Col
          md={12}
          style={{
            backgroundColor: "#0652DD",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/images/logo-white.png"
            alt="Logo"
            style={{
              top: "20px",
              left: "20px",
              maxWidth: 150,
              position: "absolute",
            }}
          />
          <img
            src="/images/illustration.png" // Make sure to have an illustration image here
            alt="Illustration"
            style={{ maxWidth: "75%", margin: "0 auto" }}
          />
          <Typography.Title level={2} style={{ color: "white" }}>
            Sign up for free
          </Typography.Title>
        </Col>
      )}
      <Col
        md={lgUp ? 12 : 24}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: 400, width: "100%", margin: "0 auto" }}>
          {!lgUp && (
            <img
              src="/images/logo.png"
              alt="Logo"
              style={{
                maxWidth: "75%",
                marginBottom: "20px",
              }}
            />
          )}
          <Typography.Title level={1}>Sign Up</Typography.Title>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder="Username" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              label="First Name"
              name="first_name" // Ensure field names match those expected by the API
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={status === "loading"}
                style={{ width: "100%", marginRight: 8, marginBottom: 8 }}
              >
                Register
              </Button>
              <div style={{ textAlign: "center" }}>
                Already have an account? <a href="/login">Login here</a>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default Register;

import axios from "axios";
import {
  instance,
  getAuthHeaders,
  handleError,
  API_BASE_URL,
} from "./apiUtils";

// Function to register a new user
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}register/`, userData);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

// Function to log in a user and retrieve tokens
export const loginUser = async (credentials, rememberMe = false) => {
  try {
    const response = await instance.post("token/", credentials);
    const storage = rememberMe ? localStorage : sessionStorage; // Choose storage based on 'rememberMe'

    storage.setItem("accessToken", response.data.access);
    storage.setItem("refreshToken", response.data.refresh);
    // Storing the token expiry time
    const expiresAt = new Date().getTime() + response.data.expiresIn * 1000;
    storage.setItem("tokenExpiresAt", expiresAt);

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle login-specific 401 error
      throw new Error("Invalid username or password");
    } else {
      // Rethrow the error for other handlers or logging
      throw error;
    }
  }
};

// Function to retrieve the profile information of the logged-in user
export const getUserProfile = async () => {
  try {
    const response = await instance.get("user/profile/", {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to change the logged-in users password
export const changePassword = async (passwordData) => {
  try {
    const response = await instance.post("change-password/", passwordData, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to update user profile information
export const updateUserProfile = async (profileData) => {
  try {
    const response = await instance.patch("user/profile/", profileData, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to request a password reset
export const requestPasswordReset = async (email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}password_reset/`, {
      email,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to reset a password with a token
export const confirmPasswordReset = async (userId, token, password) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}password_reset/confirm/${userId}/${token}/`,
      { password }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to log out a user
export const logoutUser = (callback) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("tokenExpiresAt");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("tokenExpiresAt");

  if (callback) callback(); // Execute the callback if provided
};

import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/userSlice";

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const tokenExpiresAt = useSelector((state) => state.user.tokenExpiresAt);
  const isTokenExpired = new Date().getTime() > tokenExpiresAt;

  if (!isAuthenticated || isTokenExpired) {
    dispatch(logout());
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;

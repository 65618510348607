import { instance, handleError } from "./apiUtils";

export const getChangeLogs = async (websiteId, params) => {
  try {
    const response = await instance.get(`changelogs/`, {
      params: {
        website_id: websiteId,
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getChangeLogsSummary = async (websiteId) => {
  try {
    const response = await instance.get(`changelogs/summary/`, {
      params: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getRelatedChangeLogs = async (
  websiteId,
  url,
  startDate,
  endDate
) => {
  try {
    const response = await instance.get("changelogs/related/", {
      params: {
        website_id: websiteId,
        url: url,
        startDate: startDate,
        endDate: endDate,
      },
    });
    console.log("Related Changelogs: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
    return [];
  }
};

export const getChangeLogById = async (changelogId) => {
  try {
    const response = await instance.get(`changelogs/${changelogId}/`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getChangeTypes = async () => {
  try {
    const response = await instance.get("/changetypes/");
    console.log(response.data);
    return response.data;
  } catch (error) {
    handleError(error);
    return [];
  }
};

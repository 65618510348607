import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getWebsiteStatistics } from "../api/Website";
import { useResponsive } from "../hooks/useResponsive";
import {
  Button,
  Card,
  Input,
  Space,
  Statistic,
  Segmented,
  Switch,
  Typography,
} from "antd";
import { Area, Line } from "@ant-design/charts";

const { Search } = Input;

function Dashboard() {
  const userProfile = useSelector((state) => state.user.profile);
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const { smUp, mdUp, lgUp } = useResponsive();

  const [data, setData] = useState([]);
  const [period, setPeriod] = useState("year");
  const [aggregation, setAggregation] = useState("day");
  const [showTrendLine, setShowTrendLine] = useState(true);

  useEffect(() => {
    if (!selectedWebsiteId) return;
    const fetchData = async () => {
      const response = await getWebsiteStatistics(
        selectedWebsiteId,
        { period },
        "record",
        aggregation
      );
      const formattedData = response.map((item) => ({
        ...item,
        truncated_date: new Date(item.truncated_date), // Ensure the dates are in Date object format
        dateNum: new Date(item.truncated_date).getTime(),
      }));
      setData(formattedData);
    };

    fetchData();
    console.log(data);
  }, [selectedWebsiteId, aggregation, period]);

  const calculateTrendline = (data, field) => {
    const n = data.length;
    const sumX = data.reduce((acc, curr) => acc + curr.dateNum, 0);
    const sumY = data.reduce((acc, curr) => acc + curr[field], 0);
    const sumXY = data.reduce(
      (acc, curr) => acc + curr.dateNum * curr[field],
      0
    );
    const sumXX = data.reduce(
      (acc, curr) => acc + curr.dateNum * curr.dateNum,
      0
    );

    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    const trendDirection = slope >= 0 ? "Positive" : "Negative";

    const trendData = data.map((item) => ({
      ...item,
      [field]: slope * item.dateNum + intercept, // Store trend data under a different key
      type: "Trend",
    }));

    return { trendDirection, trendData };
  };

  const getConfig = (field) => {
    let actualData = data.map((item) => ({
      ...item,
      type: "Actual",
      [field]: item[field],
    }));

    let trendData = [];
    let trendDirection = "Positive";
    let trendColor = "#52c41a";

    if (showTrendLine) {
      const trendResults = calculateTrendline(data, field); // Prepare trendline data
      trendDirection = trendResults.trendDirection;
      trendData = trendResults.trendData;
      trendColor = trendDirection === "Positive" ? "#52c41a" : "#ff4d4f";
    }

    console.log("DATA: ", [...actualData, ...trendData]);

    return {
      data: [...actualData, ...trendData],
      xField: "truncated_date",
      yField: field,
      seriesField: "type",
      animate: true,
      smooth: true,
      colorField: "type",
      legend: false,
      scale: {
        color: { domain: ["Actual", "Trend"], range: ["#1890ff", trendColor] },
      },
      interaction: {
        tooltip: {
          render: (e, { title, items }) => {
            const date = new Date(title);
            return (
              <div key={title}>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  {date.toLocaleDateString()}
                </Typography.Title>
                {items.map((item) => {
                  const { name, value, color } = item;

                  let roundedValue = value;
                  if (field === "ctr") {
                    roundedValue = value.toFixed(2); // Round to two decimal places
                  } else {
                    roundedValue = Math.round(value); // Round to the nearest whole number
                  }

                  return (
                    <div>
                      <div
                        style={{
                          margin: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              display: "inline-block",
                              width: 6,
                              height: 6,
                              borderRadius: "50%",
                              backgroundColor: color,
                              marginRight: 6,
                            }}
                          ></span>
                          <Typography.Text strong>{name}</Typography.Text>
                        </div>
                        <Typography.Text>{roundedValue}</Typography.Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          },
        },
      },
    };
  };

  const periodOptions = mdUp
    ? [
        { label: "1 Month", value: "month" },
        { label: "3 Months", value: "3months" },
        { label: "6 Months", value: "6months" },
        { label: "This Year", value: "yeartodate" },
        { label: "12 Months", value: "year" },
        { label: "All Time", value: "all" },
      ]
    : [
        { label: "1M", value: "month" },
        { label: "3M", value: "3months" },
        { label: "6M", value: "6months" },
        { label: "YTD", value: "yeartodate" },
        { label: "12M", value: "year" },
        { label: "All", value: "all" },
      ];

  return (
    <div>
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        Welcome, {userProfile ? userProfile.first_name : "User"}
      </Typography.Title>
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: smUp ? "row" : "column",
            justifyContent: "space-between",
            alignItems: smUp ? "center" : "flex-start",
          }}
        >
          <Typography.Title level={3}>Your Performance</Typography.Title>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: mdUp ? "auto" : "100%",
              justifyContent: lgUp ? "flex-end" : "space-between",
              alignItems: "center",
              marginTop: mdUp ? "0" : "20px",
            }}
          >
            <Segmented
              options={periodOptions}
              value={period}
              onChange={setPeriod}
              style={{ marginRight: 8 }}
            />
            <Segmented
              options={[
                { label: "Day", value: "day" },
                { label: "Week", value: "week" },
                { label: "Month", value: "month" },
              ]}
              value={aggregation}
              onChange={setAggregation}
            />
            <Switch
              checked={showTrendLine}
              onChange={() => setShowTrendLine(!showTrendLine)}
              checkedChildren="Show Trendline"
              unCheckedChildren="Hide Trendline"
            />
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            Impressions
          </Typography.Title>
          <Line {...getConfig("impressions")} />
        </div>
        <div style={{ marginTop: 20 }}>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            Clicks
          </Typography.Title>
          <Line {...getConfig("clicks")} />
        </div>
        <div style={{ marginTop: 20 }}>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            CTR
          </Typography.Title>
          <Line {...getConfig("ctr")} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

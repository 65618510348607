import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getKeywords } from "../api/Keywords";

export const fetchKeywords = createAsyncThunk(
  "keywords/fetchKeywords",
  async (params, { getState }) => {
    const { selectedWebsiteId } = getState().websites;
    let allKeywords = [];
    let page = 1;
    const pageSize = 1000;

    while (true) {
      const response = await getKeywords(selectedWebsiteId, {
        ...params,
        page,
        page_size: pageSize,
      });
      allKeywords = allKeywords.concat(response.results);
      if (!response.next) break;
      page++;
    }

    return allKeywords;
  }
);

const keywordsSlice = createSlice({
  name: "keywords",
  initialState: {
    keywords: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeywords.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchKeywords.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.keywords = action.payload;
      })
      .addCase(fetchKeywords.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default keywordsSlice.reducer;

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userSlice";
import teamsReducer from "./teamsSlice";
import websitesReducer from "./websitesSlice";
import keywordsReducer from "./keywordsSlice";

const persistConfig = {
  key: "root",
  storage,
};

const appReducer = combineReducers({
  user: userReducer,
  teams: teamsReducer,
  websites: websitesReducer,
  keywords: keywordsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

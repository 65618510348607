import React from "react";
import { useSelector } from "react-redux";
import { Tabs, Typography } from "antd";
import Financial from "./Settings/Financial";
import Teams from "./Settings/Teams";

const { TabPane } = Tabs;

function Settings() {
  const userProfile = useSelector((state) => state.user.profile);
  const { websites } = useSelector((state) => state.websites);

  return (
    <div>
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        Settings
      </Typography.Title>
      <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}>
        <TabPane tab="Financial" key="1">
          <Financial />
        </TabPane>
        <TabPane tab="Teams" key="2">
          <Teams />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Settings;

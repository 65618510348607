import React, { useState } from "react";
import { requestPasswordReset } from "../api/Auth"; // Make sure this path is correct
import { useResponsive } from "../hooks/useResponsive";
import { Row, Col, Form, Input, Button, Alert, Typography } from "antd";

function PasswordResetRequest() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const { lgUp } = useResponsive();

  // Handle the form submission
  const handleSubmit = async () => {
    try {
      // Attempt to send a password reset request
      const response = await requestPasswordReset(email);
      setMessage(response.message); // Display success message
      setError(""); // Clear any previous errors
    } catch (error) {
      setMessage(""); // Clear success message
      setError("Something went wrong. Please try again later."); // Show error message
    }
  };

  return (
    <Row style={{ minWidth: "100%", height: "100vh" }}>
      {lgUp && (
        <Col
          md={12}
          style={{
            backgroundColor: "#0652DD",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/images/logo-white.png"
            alt="Logo"
            style={{
              top: "20px",
              left: "20px",
              maxWidth: 150,
              position: "absolute",
            }}
          />
          <img
            src="/images/illustration.png" // Make sure to have an illustration image here
            alt="Illustration"
            style={{ maxWidth: "75%", margin: "0 auto" }}
          />
          <Typography.Title level={2} style={{ color: "white" }}>
            Reset your password
          </Typography.Title>
        </Col>
      )}
      <Col
        md={lgUp ? 12 : 24}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: 400, width: "100%", margin: "0 auto" }}>
          <Typography.Title level={1}>Forgot Password</Typography.Title>
          <Form onFinish={handleSubmit}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter your email address!" },
              ]}
            >
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 8 }}
              >
                Send Reset Link
              </Button>
              Or <a href="/login">login now!</a>
            </Form.Item>
            {message && <Alert message={message} type="success" />}
            {error && <Alert message={error} type="error" />}
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default PasswordResetRequest;

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getExperimentTargetKeywordsById } from "../api/Experiments";

export const useExperimentTargetKeywords = (
  experimentId,
  pagination,
  filters
) => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [targetKeywords, setTargetKeywords] = useState([]);
  const [totalTargetKeywords, setTotalTargetKeywords] = useState(0);
  const [loadingTargetKeywords, setLoadingTargetKeywords] = useState(false);
  const [error, setError] = useState(null);

  const fetchTargetKeywords = useCallback(async () => {
    if (!experimentId) return;

    setLoadingTargetKeywords(true);

    const params = {
      page: pagination.current,
      page_size: pagination.pageSize,
      ordering: filters.sortOrder
        ? `${filters.sortOrder === "asc" ? "" : "-"}${filters.sortField}`
        : undefined,
    };

    try {
      const data = await getExperimentTargetKeywordsById(experimentId, params);
      console.log("Data target key: ", data);
      setTargetKeywords(data.results);
      setTotalTargetKeywords(data.count);
    } catch (error) {
      console.error("Failed to fetch target keywords:", error);
      setError(error);
    } finally {
      setLoadingTargetKeywords(false);
    }
  }, [
    experimentId,
    pagination.current,
    pagination.pageSize,
    filters.sortOrder,
    filters.sortField,
  ]);

  useEffect(() => {
    fetchTargetKeywords();
  }, [fetchTargetKeywords]);

  return {
    targetKeywords,
    loadingTargetKeywords,
    totalTargetKeywords,
    error,
  };
};

import React from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const ExperimentColumns = ({
  setSelectedExperiment,
  setIsDeleteModalVisible,
  setIsEditModalVisible, // Add this line
}) => [
  {
    title: "Experiment Name",
    dataIndex: "experiment_name",
    key: "experiment_name",
    render: (text, record) => (
      <Link to={`/experiments/${record.id}`}>{text}</Link>
    ),
    sorter: true,
    defaultSortOrder: "ascend",
  },
  {
    title: "Control Start Date",
    dataIndex: "control_start_date",
    key: "control_start_date",
    sorter: true,
  },
  {
    title: "Control End Date",
    dataIndex: "control_end_date",
    key: "control_end_date",
    sorter: true,
  },
  {
    title: "Test Start Date",
    dataIndex: "test_start_date",
    key: "test_start_date",
    sorter: true,
  },
  {
    title: "Test End Date",
    dataIndex: "test_end_date",
    key: "test_end_date",
    sorter: true,
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <>
        <Tooltip
          title={
            record.successful === true
              ? "Experiment is marked as successful and cannot be edited."
              : record.successful === false
              ? "Experiment is marked as unsuccessful and cannot be edited."
              : ""
          }
        >
          <Button
            icon={<EditOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => {
              setSelectedExperiment(record);
              setIsEditModalVisible(true);
            }}
            disabled={record.successful === true || record.successful === false}
          >
            Edit
          </Button>
        </Tooltip>
        <Button
          icon={<DeleteOutlined />}
          onClick={() => {
            setSelectedExperiment(record);
            setIsDeleteModalVisible(true);
          }}
        >
          <span>Delete Experiment</span>
        </Button>
      </>
    ),
  },
];

export default ExperimentColumns;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Modal,
  Button,
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Typography,
  message,
  DatePicker,
} from "antd";
import moment from "moment";
import { getRelatedChangeLogs } from "../../api/Changelog";
import { createExperiment } from "../../api/Experiments";
import { fetchKeywords } from "../../store/keywordsSlice"; // Ensure this import is correct

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const CreateExperimentModal = ({ isOpen, onClose, experimentType }) => {
  const dispatch = useDispatch();
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const { keywords, status: keywordsStatus } = useSelector(
    (state) => state.keywords
  );
  const [form] = Form.useForm();
  const [testPeriod, setTestPeriod] = useState(14);
  const [customPeriod, setCustomPeriod] = useState(false);
  const [adjustDates, setAdjustDates] = useState(false);
  const [adjustPages, setAdjustPages] = useState(false);
  const [controlStartDate, setControlStartDate] = useState();
  const [controlEndDate, setControlEndDate] = useState();
  const [testStartDate, setTestStartDate] = useState();
  const [testEndDate, setTestEndDate] = useState();
  const [relatedChangeLogs, setRelatedChangeLogs] = useState([]);
  const [experimentName, setExperimentName] = useState("");
  const [hypothesis, setHypothesis] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [useChangeLogs, setUseChangeLogs] = useState(false);
  const [controlPages, setControlPages] = useState([""]);
  const [testPages, setTestPages] = useState([""]);
  const [changeRange, setChangeRange] = useState([]);

  const notFoundContent =
    keywordsStatus === "loading" ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spin style={{ marginRight: 8 }} />
        Loading keywords...
      </div>
    ) : (
      "No keywords found"
    );

  useEffect(() => {
    if (isOpen && keywordsStatus === "idle") {
      dispatch(fetchKeywords({})); // Fetch all keywords if they haven't been fetched yet
    }
  }, [dispatch, isOpen, keywordsStatus]);

  useEffect(() => {
    const fetchRelatedChangeLogs = async () => {
      if (experimentType.key === "single" && isOpen && useChangeLogs) {
        setLoading(true);
        setError(null);

        try {
          console.log("Date 1: ", changeRange[0]);
          console.log("Date 2: ", changeRange[1]);
          const fetchedLogs = await getRelatedChangeLogs(
            selectedWebsiteId,
            testPages[0],
            changeRange[0]
              .clone()
              .subtract(testPeriod, "days")
              .format("YYYY-MM-DD"),
            changeRange[1].clone().add(testPeriod, "days").format("YYYY-MM-DD")
          );
          const mappedLogs = fetchedLogs.map((log) => ({
            ...log,
            isEnabled: true,
          }));
          setRelatedChangeLogs(mappedLogs);
          calculateDates(mappedLogs, adjustDates);
        } catch (error) {
          console.error("Failed to fetch related changelogs:", error);
          setError("Failed to load related changelogs.");
          setRelatedChangeLogs([]);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchRelatedChangeLogs();
  }, [useChangeLogs, changeRange, testPeriod, adjustDates, testPages]);

  useEffect(() => {
    calculateDates(
      relatedChangeLogs.filter((log) => log.isEnabled),
      adjustDates
    );
  }, [changeRange, testPeriod, relatedChangeLogs, adjustDates]);

  const calculateDates = (logs, adjust) => {
    if (adjust) {
      if (logs.length > 0) {
        const sortedLogs = logs.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        const earliestDate = moment(sortedLogs[0].created_at);
        const latestDate = moment(sortedLogs[logs.length - 1].created_at);

        setControlStartDate(
          earliestDate.clone().subtract(testPeriod, "days").format("YYYY-MM-DD")
        );
        setControlEndDate(
          earliestDate.clone().subtract(1, "days").format("YYYY-MM-DD")
        );
        setTestStartDate(
          latestDate.clone().add(1, "days").format("YYYY-MM-DD")
        );
        setTestEndDate(
          latestDate.clone().add(testPeriod, "days").format("YYYY-MM-DD")
        );
      }
    } else if (changeRange && changeRange.length === 2) {
      const startDate = changeRange[0];
      const endDate = changeRange[1];

      setControlStartDate(
        startDate.clone().subtract(testPeriod, "days").format("YYYY-MM-DD")
      );
      setControlEndDate(
        startDate.clone().subtract(1, "days").format("YYYY-MM-DD")
      );
      setTestStartDate(endDate.clone().add(1, "days").format("YYYY-MM-DD"));
      setTestEndDate(
        endDate.clone().add(testPeriod, "days").format("YYYY-MM-DD")
      );
    } else {
      const changeDate = moment(); // Use appropriate date if needed
      setControlStartDate(
        changeDate.clone().subtract(testPeriod, "days").format("YYYY-MM-DD")
      );
      setControlEndDate(
        changeDate.clone().subtract(1, "days").format("YYYY-MM-DD")
      );
      setTestStartDate(changeDate.clone().add(1, "days").format("YYYY-MM-DD"));
      setTestEndDate(
        changeDate.clone().add(testPeriod, "days").format("YYYY-MM-DD")
      );
    }
  };

  const toggleChangeLogEnabled = (id) => {
    setRelatedChangeLogs((prevLogs) =>
      prevLogs.map((log) =>
        log.id === id ? { ...log, isEnabled: !log.isEnabled } : log
      )
    );
  };

  const handleChangeKeywords = (newSelectedKeywords) => {
    setSelectedKeywords(newSelectedKeywords);
  };

  const handleCreateExperiment = async () => {
    const values = await form.validateFields();
    const experimentData = {
      experiment_name: values.experimentName,
      experiment_type: experimentType.key,
      hypothesis: hypothesis,
      control_start_date: controlStartDate,
      control_end_date: controlEndDate,
      test_start_date: testStartDate,
      test_end_date: testEndDate,
      target_keywords: selectedKeywords,
      changelogs: useChangeLogs
        ? relatedChangeLogs.filter((log) => log.isEnabled).map((log) => log.id)
        : [],
      control_pages: controlPages,
      test_pages: testPages,
      website_id: selectedWebsiteId,
    };
    console.log("Save data: ", experimentData);

    try {
      await createExperiment(experimentData);
      message.success("Experiment created successfully!");
      setExperimentName("");
      setHypothesis("");
      setControlStartDate("");
      setControlEndDate("");
      setTestStartDate("");
      setTestEndDate("");
      setSelectedKeywords([]);
      form.resetFields();
      onClose();
    } catch (error) {
      message.error("Failed to create experiment. Please try again.");
    }
  };

  return (
    <Modal
      title={`Create ${experimentType.title}`}
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={onClose}
      okText="Create Experiment"
      cancelText="Cancel"
      width={1000}
    >
      <Form form={form} onFinish={handleCreateExperiment}>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Card title="Experiment Name and Goals">
            <Typography.Text strong>Experiment Name:</Typography.Text>
            <Form.Item
              name="experimentName"
              rules={[{ required: true, message: "Please enter a name!" }]}
            >
              <Input
                placeholder="Experiment name"
                style={{ marginBottom: 8 }}
              />
            </Form.Item>
            <Typography.Text strong>Hypothesis:</Typography.Text>
            <ReactQuill
              theme="snow"
              value={hypothesis}
              onChange={(content) => setHypothesis(content)}
              style={{ marginBottom: 8 }}
            />
            <Typography.Text strong>Keywords:</Typography.Text>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Select or add keywords"
              onChange={handleChangeKeywords}
              tokenSeparators={[","]}
              options={keywords.map((keyword) => ({
                label: keyword.keyword_text,
                value: keyword.keyword_text,
              }))}
              value={selectedKeywords}
              notFoundContent={notFoundContent}
            />
          </Card>
          {experimentType.key === "single" && (
            <>
              <Card title="Single Experiment Settings">
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>Page URL:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Input
                      value={controlPages[0]}
                      onChange={(e) => {
                        const newControlPages = [...controlPages];
                        newControlPages[0] = e.target.value.trim();
                        setControlPages(newControlPages);
                        setTestPages(newControlPages);
                      }}
                      placeholder="Enter page URL"
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>Change Period:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <RangePicker
                      style={{ width: "100%" }}
                      onChange={(dates) => {
                        setChangeRange(dates);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Typography.Text strong>
                      Use Related Changelogs?
                    </Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Switch
                      checked={useChangeLogs}
                      onChange={setUseChangeLogs}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Col>
                </Row>
                {useChangeLogs && (
                  <>
                    <Row style={{ marginTop: 10 }}>
                      <Col span={12}>
                        <Typography.Text strong>
                          Adjust Change Period By Changelogs
                        </Typography.Text>
                      </Col>
                      <Col span={12}>
                        <Switch
                          checkedChildren="Adjust Dates"
                          unCheckedChildren="Fixed Dates"
                          checked={adjustDates}
                          onChange={(checked) => setAdjustDates(checked)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Card>
            </>
          )}
          {experimentType.key === "group" && (
            <>
              <Card title="Group Experiment Settings">
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>Page URLs:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <TextArea
                      rows={4}
                      value={controlPages.join("\n")}
                      onChange={(e) => {
                        const value = e.target.value
                          .split(/[\n,]+/)
                          .map((url) => url.trim())
                          .join("\n");
                        const urls = value.split("\n").map((url) => url.trim());
                        setControlPages(urls);
                        setTestPages(urls);
                      }}
                      placeholder="Enter page URLs separated by commas or new lines"
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>Change Period:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <RangePicker
                      style={{ width: "100%" }}
                      onChange={(dates) => {
                        setChangeRange(dates);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </>
          )}
          {experimentType.key === "split" && (
            <>
              <Card title="Split Experiment Settings">
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>Control Pages:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <TextArea
                      rows={4}
                      value={controlPages.join("\n")}
                      onChange={(e) => {
                        const value = e.target.value
                          .split(/[\n,]+/)
                          .map((url) => url.trim())
                          .join("\n");
                        const urls = value.split("\n").map((url) => url.trim());
                        setControlPages(urls);
                      }}
                      placeholder="Enter control page URLs separated by commas or new lines"
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>Test Pages:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <TextArea
                      rows={4}
                      value={testPages.join("\n")}
                      onChange={(e) => {
                        const value = e.target.value
                          .split(/[\n,]+/)
                          .map((url) => url.trim())
                          .join("\n");
                        const urls = value.split("\n").map((url) => url.trim());
                        setTestPages(urls);
                      }}
                      placeholder="Enter test page URLs separated by commas or new lines"
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>Change Period:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <RangePicker
                      style={{ width: "100%" }}
                      onChange={(dates) => {
                        setChangeRange(dates);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </>
          )}
          {experimentType.key === "switch" && (
            <>
              <Card title="URL Switch Experiment Settings">
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>New URL:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Input
                      value={testPages[0]}
                      onChange={(e) => {
                        const newTestPages = [...testPages];
                        newTestPages[0] = e.target.value.trim();
                        setTestPages(newTestPages);
                      }}
                      placeholder="Enter new URL"
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>Former URLs:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <TextArea
                      rows={4}
                      value={controlPages.join("\n")}
                      onChange={(e) => {
                        const value = e.target.value
                          .split(/[\n,]+/)
                          .map((url) => url.trim())
                          .join("\n");
                        const urls = value.split("\n").map((url) => url.trim());
                        setControlPages(urls);
                      }}
                      placeholder="Enter former URLs separated by commas or new lines"
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col span={12}>
                    <Typography.Text strong>Change Period:</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <RangePicker
                      style={{ width: "100%" }}
                      onChange={(dates) => {
                        setChangeRange(dates);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Typography.Text strong>Use Changelog</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Switch
                      checked={useChangeLogs}
                      onChange={setUseChangeLogs}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Col>
                </Row>
                {useChangeLogs && (
                  <>
                    <Row style={{ marginTop: 10 }}>
                      <Col span={12}>
                        <Typography.Text strong>
                          Adjust Former Pages By Changelogs
                        </Typography.Text>
                      </Col>
                      <Col span={12}>
                        <Switch
                          checkedChildren="Adjust Pages"
                          unCheckedChildren="Fixed Pages"
                          checked={adjustPages}
                          onChange={(checked) => setAdjustPages(checked)}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Card>
            </>
          )}
          <Card title="Experiment Period" style={{ marginBottom: 16 }}>
            <Form.Item>
              <Radio.Group
                defaultValue={14}
                onChange={(e) => {
                  if (e.target.value === "custom") {
                    setCustomPeriod(true);
                  } else {
                    setCustomPeriod(false);
                    setTestPeriod(e.target.value);
                  }
                }}
                style={{ width: "100%" }}
              >
                <Radio.Button
                  value={7}
                  style={{ width: "25%", textAlign: "center" }}
                >
                  7 Days
                </Radio.Button>
                <Radio.Button
                  value={14}
                  style={{ width: "25%", textAlign: "center" }}
                >
                  14 Days
                </Radio.Button>
                <Radio.Button
                  value={30}
                  style={{ width: "25%", textAlign: "center" }}
                >
                  30 Days
                </Radio.Button>
                <Radio.Button
                  value="custom"
                  style={{ width: "25%", textAlign: "center" }}
                >
                  {customPeriod ? (
                    <Input
                      size="small"
                      type="number"
                      min={1}
                      value={testPeriod}
                      onChange={(e) => {
                        setTestPeriod(parseInt(e.target.value, 10));
                      }}
                      suffix="Days"
                      style={{ marginTop: 3 }}
                    />
                  ) : (
                    "Custom"
                  )}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div style={{ flex: "1 1 300px", textAlign: "center" }}>
                <Typography.Text strong>Control Period: </Typography.Text>
                <Typography.Text>
                  From {controlStartDate} to {controlEndDate}
                </Typography.Text>
              </div>
              <div style={{ flex: "1 1 300px", textAlign: "center" }}>
                <Typography.Text strong>Test Period: </Typography.Text>
                <Typography.Text>
                  From {testStartDate} to {testEndDate}
                </Typography.Text>
              </div>
            </div>
          </Card>
          {(experimentType.key === "single" ||
            experimentType.key === "switch") &&
            useChangeLogs && (
              <Card title="Related Changelogs for Page and Period">
                <Table
                  columns={[
                    {
                      title: "Date",
                      dataIndex: "created_at",
                      key: "date",
                      render: (text, record) => (
                        <span
                          style={record.isEnabled ? {} : { color: "lightgrey" }}
                        >
                          {moment(text).format("DD/MM/YYYY")}
                        </span>
                      ),
                    },
                    {
                      title: "Type",
                      dataIndex: ["changetype", "name"],
                      key: "type",
                      render: (text, record) => (
                        <span
                          style={{
                            color: record.isEnabled ? "inherit" : "lightgrey",
                          }}
                        >
                          {text}
                        </span>
                      ),
                    },
                    {
                      title: "Action",
                      key: "action",
                      render: (text, record) => (
                        <Button
                          type={record.isEnabled ? "danger" : "primary"}
                          onClick={() => toggleChangeLogEnabled(record.id)}
                        >
                          {record.isEnabled ? "Disable" : "Enable"}
                        </Button>
                      ),
                    },
                  ]}
                  dataSource={relatedChangeLogs}
                  rowKey="id"
                  rowStyle
                  bordered
                  pagination={false}
                  expandedRowRender={(log) => (
                    <div
                      style={{
                        color: log.isEnabled ? "inherit" : "lightgrey",
                      }}
                    >
                      <Row>
                        <Col xs={24} md={12}>
                          <p>
                            <strong style={{ fontWeight: 500 }}>
                              Page URL:{" "}
                            </strong>
                            <span>{log.page.url}</span>
                          </p>
                          <p>
                            <strong style={{ fontWeight: 500 }}>Index: </strong>
                            <span>{log.index}</span>
                          </p>
                        </Col>
                        <Col xs={24} md={12}>
                          <p>
                            <strong style={{ fontWeight: 500 }}>
                              Old Value:{" "}
                            </strong>
                            <span>{log.old_value}</span>
                          </p>
                          <p>
                            <strong style={{ fontWeight: 500 }}>
                              New Value:{" "}
                            </strong>
                            <span>{log.new_value}</span>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                />
              </Card>
            )}
        </Space>
      </Form>
    </Modal>
  );
};

export default CreateExperimentModal;

import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Divider,
  Spin,
  Table,
  Tooltip,
  Typography,
  Segmented,
  Progress,
  Switch,
  Col,
  Row,
} from "antd";
import moment from "moment";
import {
  getExperimentById,
  updateExperimentSuccessById,
} from "../../api/Experiments";
import { getPagesStatistics } from "../../api/Pages";
import { useExperimentTargetKeywords } from "../../hooks/useExperimentTargetKeywords";
import { useExperimentRankedKeywords } from "../../hooks/useExperimentRankedKeywords";
import {
  CheckCircleOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Line } from "@ant-design/charts";
import DOMPurify from "dompurify";
import EditExperimentModal from "./EditExperimentModal";

const ExperimentDetails = () => {
  const { experimentId } = useParams();
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [experiment, setExperiment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statisticsData, setStatisticsData] = useState([]);
  const [statsLoading, setStatsLoading] = useState(true);
  const [statsError, setStatsError] = useState(null);
  const [aggregation, setAggregation] = useState("day");
  const [showTrendLine, setShowTrendLine] = useState(true);
  const [showCombinedChart, setShowCombinedChart] = useState(false); // Nieuwe state voor de tweede schakelaar
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const [targetKeywordsPagination, setTargetKeywordsPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [targetKeywordsFilters, setTargetKeywordsFilters] = useState({
    sortField: "keyword_text",
    sortOrder: "asc",
  });
  const { targetKeywords, loadingTargetKeywords, totalTargetKeywords } =
    useExperimentTargetKeywords(
      experimentId,
      targetKeywordsPagination,
      targetKeywordsFilters
    );
  const [rankedKeywordsPagination, setRankedKeywordsPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [rankedKeywordsFilters, setRankedKeywordsFilters] = useState({
    sortField: "keyword_text",
    sortOrder: "asc",
  });
  const { rankedKeywords, loadingRankedKeywords, totalRankedKeywords } =
    useExperimentRankedKeywords(
      experimentId,
      rankedKeywordsPagination,
      rankedKeywordsFilters
    );

  const fetchExperimentData = async () => {
    setLoading(true);
    setError(null);
    try {
      const experimentData = await getExperimentById(experimentId);
      setExperiment(experimentData);
    } catch (error) {
      console.error("Failed to fetch experiment data:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSuccess = async (status) => {
    try {
      const result = await updateExperimentSuccessById(experimentId, status);
      setExperiment((prevExperiment) => ({
        ...prevExperiment,
        successful: status,
      }));
    } catch (error) {
      console.error("Failed to update experiment success status:", error);
    }
  };

  useEffect(() => {
    fetchExperimentData();
  }, [experimentId]);

  const handleTargetKeywordsSortChange = (pagination, filters, sorter) => {
    setTargetKeywordsPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    if (sorter.order) {
      const newSortField = Array.isArray(sorter.field)
        ? sorter.field.join("__")
        : sorter.field;
      const newSortOrder =
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : null;
      setTargetKeywordsFilters((prev) => ({
        ...prev,
        sortField: newSortField,
        sortOrder: newSortOrder,
      }));
    }
  };

  const handleRankedKeywordsSortChange = (pagination, filters, sorter) => {
    setRankedKeywordsPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    if (sorter.order) {
      const newSortField = Array.isArray(sorter.field)
        ? sorter.field.join("__")
        : sorter.field;
      const newSortOrder =
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : null;
      setRankedKeywordsFilters((prev) => ({
        ...prev,
        sortField: newSortField,
        sortOrder: newSortOrder,
      }));
    }
  };

  useEffect(() => {
    const fetchStatisticsData = async () => {
      if (!experiment) return;
      setStatsLoading(true);
      setStatsError(null);
      try {
        const pageIds = experiment.pages.test_period.map((page) => page.id);
        if (pageIds.length > 0) {
          const statsData = await getPagesStatistics(
            selectedWebsiteId,
            pageIds,
            {
              date_range: [
                experiment.periods.control_period.start,
                experiment.periods.test_period.end,
              ],
            },
            "record",
            aggregation,
            "true"
          );

          setStatisticsData(statsData);
        } else {
          setStatisticsData([]);
          setStatsError("Failed to fetch statistics data");
        }
      } catch (error) {
        console.error("Failed to fetch statistics data:", error);
        setStatsError(error);
      } finally {
        setStatsLoading(false);
      }
    };

    fetchStatisticsData();
  }, [experiment, aggregation, selectedWebsiteId]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!experiment) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography.Text type="danger">
          {error
            ? "Something went wrong while fetching the experiment details."
            : "Experiment not found."}
        </Typography.Text>
      </div>
    );
  }

  const calculateTrendline = (data, field) => {
    const n = data.length;
    const sumX = data.reduce((acc, curr) => acc + curr.dateNum, 0);
    const sumY = data.reduce((acc, curr) => acc + curr[field], 0);
    const sumXY = data.reduce(
      (acc, curr) => acc + curr.dateNum * curr[field],
      0
    );
    const sumXX = data.reduce(
      (acc, curr) => acc + curr.dateNum * curr.dateNum,
      0
    );

    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    const trendDirection = slope >= 0 ? "Positive" : "Negative";

    const trendData = data.map((item) => ({
      ...item,
      [field]: slope * item.dateNum + intercept,
      line: "Trend",
      type: "Trend",
    }));

    return { trendDirection, trendData };
  };

  const controlEndDate = moment(experiment.periods.control_period.end).format(
    "YYYY-MM-DD"
  );
  const testStartDate = moment(experiment.periods.test_period.start).format(
    "YYYY-MM-DD"
  );

  const getConfig = (field) => {
    let pagesStatisticsData = statisticsData.map((item) => ({
      ...item,
      type: item.type,
      line: "Data",
      dateNum: new Date(item.truncated_date).getTime(),
      [field]: item[field],
    }));

    let trendData = [];
    let trendDirection = "Positive";
    let trendColor = "#52c41a";

    if (showTrendLine) {
      const trendResults = calculateTrendline(
        pagesStatisticsData.filter((d) => d.type === "Actual"),
        field
      );
      trendDirection = trendResults.trendDirection;
      trendData = trendResults.trendData.map((item) => ({
        ...item,
        [field]: Math.max(item[field], 0),
      }));

      if (field === "position") {
        trendColor = trendDirection === "Positive" ? "#ff4d4f" : "#52c41a";
      } else {
        trendColor = trendDirection === "Positive" ? "#52c41a" : "#ff4d4f";
      }
    }

    return {
      data: [...pagesStatisticsData, ...trendData],
      xField: "truncated_date",
      yField: field,
      animate: true,
      smooth: true,
      seriesField: "type",
      colorField: "type",
      shapeField: "line",
      legend: false,
      style: {
        lineWidth: 2,
      },
      scale: {
        color: {
          domain: [
            "Actual",
            "Missing before",
            "Missing after",
            "Missing",
            "Trend",
          ],
          range: ["#1890ff", "#808080", "#808080", "#808080", trendColor],
        },
      },
      annotations: [
        {
          type: "lineX",
          xField: [
            moment(experiment.periods.control_period.end).format("YYYY-MM-DD"),
            "min",
          ],
          style: {
            stroke: "#ff4d4f",
            lineDash: [4, 4],
            lineWidth: 1,
          },
          text: {
            content: "End Control Period",
            position: "top",
            offsetY: -5,
            style: {
              textAlign: "center",
              fontSize: 12,
              fill: "#ff4d4f",
            },
          },
        },
        {
          type: "lineX",
          xField: [
            moment(experiment.periods.test_period.start).format("YYYY-MM-DD"),
            "min",
          ],
          style: {
            stroke: "#ff4d4f",
            lineDash: [4, 4],
            lineWidth: 1,
          },
          text: {
            content: "Start Test Period",
            position: "top",
            offsetY: -5,
            style: {
              textAlign: "center",
              fontSize: 12,
              fill: "#ff4d4f",
            },
          },
        },
      ],
      interaction: {
        tooltip: {
          render: (e, { title, items }) => {
            const titleParts = title.split(",");
            const titleDate = titleParts[0];
            const isMissing = items.some((item) => /Missing/i.test(item.name));

            let annotationText = [];
            let annotationColor = [];

            if (titleDate === controlEndDate) {
              annotationText.push("End Control Period");
              annotationColor.push("#ff4d4f");
            }
            if (titleDate === testStartDate) {
              annotationText.push("Start Test Period");
              annotationColor.push("#52c41a");
            }
            if (
              moment(titleDate).isBetween(
                controlEndDate,
                testStartDate,
                null,
                "()"
              )
            ) {
              annotationText.push("Change Period");
              annotationColor.push("#ffa500");
            }

            return (
              <div key={title}>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  {titleDate}
                </Typography.Title>
                {annotationText.map((text, index) => (
                  <Typography.Paragraph
                    key={index}
                    style={{ color: annotationColor[index] }}
                  >
                    {text}
                  </Typography.Paragraph>
                ))}
                {isMissing ? (
                  <Typography.Paragraph>
                    <WarningOutlined /> Missing Data
                  </Typography.Paragraph>
                ) : (
                  ""
                )}
                {items.map((item) => {
                  const { name, value, color } = item;

                  if (isMissing || (name !== "Actual" && name !== "Trend"))
                    return;
                  return (
                    <div>
                      <div
                        style={{
                          margin: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              display: "inline-block",
                              width: 6,
                              height: 6,
                              borderRadius: "50%",
                              backgroundColor: color,
                              marginRight: 6,
                            }}
                          ></span>
                          <Typography.Text strong>{name}</Typography.Text>
                        </div>
                        <Typography.Text>
                          {name === "Trend"
                            ? parseFloat(value).toFixed(2)
                            : value}
                        </Typography.Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          },
        },
      },
    };
  };

  const getCombinedConfig = () => {
    const metrics = ["impressions", "clicks", "ctr", "position"];
    const metricColors = {
      impressions: "#1890ff", // Blue
      clicks: "#52c41a", // Green
      ctr: "#faad14", // Yellow
      missing: "#d9d9d9", // Light gray for missing data
    };

    const missingBeforeData = statisticsData
      .filter((item) => item.type === "Missing before")
      .map((item) => ({
        truncated_date: item.truncated_date,
        dateNum: new Date(item.truncated_date).getTime(),
        type: item.type,
        metric: "missing",
        value: 0,
      }));
    const missingAfterData = statisticsData
      .filter((item) => item.type === "Missing after")
      .map((item) => ({
        truncated_date: item.truncated_date,
        dateNum: new Date(item.truncated_date).getTime(),
        type: item.type,
        metric: "missing",
        value: 0,
      }));

    const actualandtrendData = metrics.flatMap((metric) => {
      const pagesStatisticsData = statisticsData
        .filter(
          (item) =>
            item.type !== "Missing before" && item.type !== "Missing after"
        )
        .map((item) => ({
          truncated_date: item.truncated_date,
          dateNum: new Date(item.truncated_date).getTime(),
          type: `${item.type} ${metric}`,
          metric: metric,
          value: item[metric],
        }));

      if (showTrendLine) {
        const trendResults = calculateTrendline(
          pagesStatisticsData.filter((d) => d.type.includes("Actual")),
          "value"
        );
        const trendData = trendResults.trendData.map((item) => ({
          ...item,
          value: Math.max(item.value, 0),
          type: `Trend ${metric}`,
        }));
        return [...pagesStatisticsData, ...trendData];
      } else {
        return pagesStatisticsData;
      }

      return pagesStatisticsData;
    });

    const combinedData = [
      ...missingBeforeData,
      ...actualandtrendData,
      ...missingAfterData,
    ];

    console.log("Combined Data: ", combinedData);

    return {
      data: combinedData,
      xField: "truncated_date",
      yField: "value",
      seriesField: "type",
      colorField: "metric",
      legend: false,
      style: {
        lineWidth: 2,
      },
      scale: {
        y: { min: 0, nice: true },
        color: {
          domain: ["impressions", "clicks", "ctr", "position", "missing"],
          range: ["#1890ff", "#52c41a", "#faad14", "#ff69b4", "#808080"],
        },
      },
      annotations: [
        {
          type: "lineX",
          xField: [
            moment(experiment.periods.control_period.end).format("YYYY-MM-DD"),
            "min",
          ],
          style: {
            stroke: "#ff4d4f",
            lineDash: [4, 4],
            lineWidth: 1,
          },
          text: {
            content: "End Control Period",
            position: "top",
            offsetY: -5,
            style: {
              textAlign: "center",
              fontSize: 12,
              fill: "#ff4d4f",
            },
          },
        },
        {
          type: "lineX",
          xField: [
            moment(experiment.periods.test_period.start).format("YYYY-MM-DD"),
            "min",
          ],
          style: {
            stroke: "#ff4d4f",
            lineDash: [4, 4],
            lineWidth: 1,
          },
          text: {
            content: "Start Test Period",
            position: "top",
            offsetY: -5,
            style: {
              textAlign: "center",
              fontSize: 12,
              fill: "#ff4d4f",
            },
          },
        },
      ],
      interaction: {
        tooltip: {
          render: (e, { title, items }) => {
            const titleParts = title.split(",");
            const titleDate = titleParts[0];
            const isMissing = items.some((item) => /Missing/i.test(item.name));

            let annotationText = [];
            let annotationColor = [];

            if (titleDate === controlEndDate) {
              annotationText.push("End Control Period");
              annotationColor.push("#ff4d4f");
            }
            if (titleDate === testStartDate) {
              annotationText.push("Start Test Period");
              annotationColor.push("#52c41a");
            }
            if (
              moment(titleDate).isBetween(
                controlEndDate,
                testStartDate,
                null,
                "()"
              )
            ) {
              annotationText.push("Change Period");
              annotationColor.push("#ffa500");
            }

            return (
              <div>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  {titleDate}
                </Typography.Title>
                {annotationText.map((text, index) => (
                  <Typography.Paragraph
                    key={index}
                    style={{ color: annotationColor[index] }}
                  >
                    {text}
                  </Typography.Paragraph>
                ))}
                {isMissing ? (
                  <Typography.Paragraph>
                    <WarningOutlined /> Missing Data
                  </Typography.Paragraph>
                ) : (
                  ""
                )}
                {items.map((item) => {
                  if (
                    item.name == "value" ||
                    item.name == "truncated_date" ||
                    item.name.toLowerCase().startsWith("missing")
                  ) {
                    return null;
                  }

                  return (
                    <div key={item.name}>
                      <span
                        style={{
                          display: "inline-block",
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: item.color,
                          marginRight: 8,
                        }}
                      ></span>
                      <Typography.Text strong>{item.name}</Typography.Text>:{" "}
                      {item.value}
                    </div>
                  );
                })}
              </div>
            );
          },
        },
      },
      legend: {
        position: "top",
      },
    };
  };

  const totalTestDays =
    moment(experiment.periods.test_period.end).diff(
      moment(experiment.periods.test_period.start),
      "days"
    ) + 1;
  const daysPassed = experiment.periods.days_passed;
  const progressPercent = Math.min((daysPassed / totalTestDays) * 100, 100);

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      render: (text) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "Type",
      dataIndex: ["changetype", "name"],
      key: "type",
    },
    {
      title: "Page URL",
      dataIndex: ["page", "url"],
      key: "page_url",
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Old Value",
      dataIndex: "old_value",
      key: "old_value",
    },
    {
      title: "New Value",
      dataIndex: "new_value",
      key: "new_value",
    },
  ];

  const targetKeywordColumns = [
    {
      title: "Keyword",
      dataIndex: "keyword_text",
      key: "keyword_text",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: "Control Period",
      children: [
        {
          title: "Impressions",
          dataIndex: "control_total_impressions",
          key: "control_total_impressions",
          sorter: true,
        },
        {
          title: "Clicks",
          dataIndex: "control_total_clicks",
          key: "control_total_clicks",
          sorter: true,
        },
        {
          title: "CTR",
          dataIndex: "control_average_ctr",
          key: "control_average_ctr",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Position",
          dataIndex: "control_average_position",
          key: "control_average_position",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
      ],
    },
    {
      title: "Test Period",
      children: [
        {
          title: "Impressions",
          dataIndex: "test_total_impressions",
          key: "test_total_impressions",
          sorter: true,
        },
        {
          title: "Clicks",
          dataIndex: "test_total_clicks",
          key: "test_total_clicks",
          sorter: true,
        },
        {
          title: "CTR",
          dataIndex: "test_average_ctr",
          key: "test_average_ctr",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Position",
          dataIndex: "test_average_position",
          key: "test_average_position",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
      ],
    },
    {
      title: "Absolute Change",
      children: [
        {
          title: "Impressions",
          dataIndex: "absolute_impressions_diff",
          key: "absolute_impressions_diff",
          sorter: true,
        },
        {
          title: "Clicks",
          dataIndex: "absolute_clicks_diff",
          key: "absolute_clicks_diff",
          sorter: true,
        },
        {
          title: "CTR",
          dataIndex: "absolute_ctr_diff",
          key: "absolute_ctr_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Position",
          dataIndex: "absolute_position_diff",
          key: "absolute_position_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
      ],
    },
    {
      title: "Percentage Change",
      children: [
        {
          title: "Impressions",
          dataIndex: "percentage_impressions_diff",
          key: "percentage_impressions_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Clicks",
          dataIndex: "percentage_clicks_diff",
          key: "percentage_clicks_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "CTR",
          dataIndex: "percentage_ctr_diff",
          key: "percentage_ctr_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Position",
          dataIndex: "percentage_position_diff",
          key: "percentage_position_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
      ],
    },
  ];

  const rankedKeywordColumns = [
    {
      title: "Keyword",
      dataIndex: "keyword_text",
      key: "keyword_text",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: "Control Period",
      children: [
        {
          title: "Impressions",
          dataIndex: "control_total_impressions",
          key: "control_total_impressions",
          sorter: true,
        },
        {
          title: "Clicks",
          dataIndex: "control_total_clicks",
          key: "control_total_clicks",
          sorter: true,
        },
        {
          title: "CTR",
          dataIndex: "control_average_ctr",
          key: "control_average_ctr",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Position",
          dataIndex: "control_average_position",
          key: "control_average_position",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
      ],
    },
    {
      title: "Test Period",
      children: [
        {
          title: "Impressions",
          dataIndex: "test_total_impressions",
          key: "test_total_impressions",
          sorter: true,
        },
        {
          title: "Clicks",
          dataIndex: "test_total_clicks",
          key: "test_total_clicks",
          sorter: true,
        },
        {
          title: "CTR",
          dataIndex: "test_average_ctr",
          key: "test_average_ctr",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Position",
          dataIndex: "test_average_position",
          key: "test_average_position",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
      ],
    },
    {
      title: "Absolute Change",
      children: [
        {
          title: "Impressions",
          dataIndex: "absolute_impressions_diff",
          key: "absolute_impressions_diff",
          sorter: true,
        },
        {
          title: "Clicks",
          dataIndex: "absolute_clicks_diff",
          key: "absolute_clicks_diff",
          sorter: true,
        },
        {
          title: "CTR",
          dataIndex: "absolute_ctr_diff",
          key: "absolute_ctr_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Position",
          dataIndex: "absolute_position_diff",
          key: "absolute_position_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
      ],
    },
    {
      title: "Percentage Change",
      children: [
        {
          title: "Impressions",
          dataIndex: "percentage_impressions_diff",
          key: "percentage_impressions_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Clicks",
          dataIndex: "percentage_clicks_diff",
          key: "percentage_clicks_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "CTR",
          dataIndex: "percentage_ctr_diff",
          key: "percentage_ctr_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
        {
          title: "Position",
          dataIndex: "percentage_position_diff",
          key: "percentage_position_diff",
          render: (text) => parseFloat(text).toFixed(2),
          sorter: true,
        },
      ],
    },
  ];

  const isSignificant = (pValue) => {
    return pValue < 0.05;
  };

  return (
    <div>
      <Typography.Title
        level={2}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 0,
          marginBottom: 16,
        }}
      >
        <span>{experiment.experiment_name}</span>
        <div>
          <Tooltip
            title={
              experiment.successful === true
                ? "Experiment is marked as successful and cannot be edited."
                : experiment.successful === false
                ? "Experiment is marked as unsuccessful and cannot be edited."
                : ""
            }
          >
            <Button
              type="primary"
              style={{ marginRight: 8 }}
              onClick={() => setIsEditModalVisible(true)}
              disabled={experiment.successful !== null}
            >
              Edit
            </Button>
          </Tooltip>
          <Tooltip
            title={
              daysPassed < totalTestDays
                ? "Experiment is not yet finished."
                : ""
            }
          >
            <Segmented
              value={experiment.successful}
              onChange={handleUpdateSuccess}
              style={{ marginRight: 10 }}
              disabled={
                experiment.successful === null && daysPassed < totalTestDays
              }
              options={[
                {
                  label: "Not Determined",
                  value: null,
                  icon: <QuestionCircleOutlined />,
                },
                {
                  label: "Successful",
                  value: true,
                  icon: <CheckCircleOutlined />,
                },
                {
                  label: "Unsuccessful",
                  value: false,
                  icon: <CloseCircleOutlined />,
                },
              ]}
            />
          </Tooltip>
        </div>
      </Typography.Title>

      <Card title="Experiment Details" style={{ marginBottom: 16 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Progress
            type="circle"
            percent={progressPercent}
            format={() => `${daysPassed} / ${totalTestDays} Days`}
            style={{ marginRight: 24 }}
          />
          <div>
            <Typography.Paragraph>
              <strong>Control Period:</strong>{" "}
              {moment(experiment.periods.control_period.start).format(
                "YYYY-MM-DD"
              )}{" "}
              to{" "}
              {moment(experiment.periods.control_period.end).format(
                "YYYY-MM-DD"
              )}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong>Test Period:</strong>{" "}
              {moment(experiment.periods.test_period.start).format(
                "YYYY-MM-DD"
              )}{" "}
              to{" "}
              {moment(experiment.periods.test_period.end).format("YYYY-MM-DD")}
            </Typography.Paragraph>
            {experiment.target_keywords.length > 0 && (
              <Typography.Paragraph>
                <strong>Target keywords:</strong>{" "}
                {experiment.target_keywords
                  .map((keyword) => keyword.keyword_text)
                  .join(", ")}
              </Typography.Paragraph>
            )}
          </div>
        </div>

        <Divider />

        <Typography.Text strong>Hypothesis:</Typography.Text>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(experiment.hypothesis),
          }}
        />

        <Divider />

        {(experiment.experiment_type === "single" ||
          experiment.experiment_type === "group") && (
          <Row>
            <Col span={24}>
              <Typography.Text strong>
                {experiment.pages.test_period.length === 1 ? "Page:" : "Pages:"}
              </Typography.Text>
              <Table
                columns={[
                  {
                    title: "URL",
                    dataIndex: "url",
                    key: "url",
                    render: (text, record) => (
                      <>
                        <Link to={`/pages/${record.id}`} target="_blank">
                          {text}
                        </Link>{" "}
                        <Tooltip title="Go to page">
                          <a
                            href={text}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkOutlined />
                          </a>
                        </Tooltip>
                      </>
                    ),
                  },
                  {
                    title: "Title",
                    dataIndex: "title",
                    key: "title",
                  },
                ]}
                dataSource={experiment.pages.test_period}
                rowKey="id"
                pagination={false}
              />
            </Col>
          </Row>
        )}
        {experiment.experiment_type === "split" && (
          <Row>
            <Col span={12}>
              <Typography.Text strong>
                {experiment.pages.control_period.length === 1
                  ? "Control Page:"
                  : "Control Pages:"}
              </Typography.Text>
              <Table
                columns={[
                  {
                    title: "URL",
                    dataIndex: "url",
                    key: "url",
                    render: (text, record) => (
                      <>
                        <Link to={`/pages/${record.id}`} target="_blank">
                          {text}
                        </Link>{" "}
                        <Tooltip title="Go to page">
                          <a
                            href={text}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkOutlined />
                          </a>
                        </Tooltip>
                      </>
                    ),
                  },
                  {
                    title: "Title",
                    dataIndex: "title",
                    key: "title",
                  },
                ]}
                dataSource={experiment.pages.control_period}
                rowKey="id"
                pagination={false}
              />
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {experiment.pages.test_period.length === 1
                  ? "Test Page:"
                  : "Test Pages:"}
              </Typography.Text>
              <Table
                columns={[
                  {
                    title: "URL",
                    dataIndex: "url",
                    key: "url",
                    render: (text, record) => (
                      <Link to={`/pages/${record.id}`} target="_blank">
                        {text}
                      </Link>
                    ),
                  },
                  {
                    title: "Title",
                    dataIndex: "title",
                    key: "title",
                  },
                ]}
                dataSource={experiment.pages.test_period}
                rowKey="id"
                pagination={false}
              />
            </Col>
          </Row>
        )}
        {experiment.experiment_type === "switch" && (
          <Row>
            <Col span={24}>
              <Typography.Text strong>
                {experiment.pages.test_period.length === 1 ? "Page:" : "Pages:"}
              </Typography.Text>
              <Table
                columns={[
                  {
                    title: "URL",
                    dataIndex: "url",
                    key: "url",
                    render: (text, record) => (
                      <Link to={`/pages/${record.id}`} target="_blank">
                        {text}
                      </Link>
                    ),
                  },
                  {
                    title: "Title",
                    dataIndex: "title",
                    key: "title",
                  },
                ]}
                dataSource={experiment.pages.test_period}
                rowKey="id"
                pagination={false}
              />
            </Col>
          </Row>
        )}
      </Card>

      {experiment.experiment_type === "single" && (
        <>
          <Card title="Experiment Changelogs" style={{ marginBottom: 16 }}>
            <Table
              columns={columns}
              dataSource={experiment.changelogs}
              rowKey="id"
              pagination={false}
            />
          </Card>
        </>
      )}

      <Card title="Statistics Summary" style={{ marginBottom: 16 }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div style={{ marginTop: 20 }}>
              <Table
                dataSource={[
                  {
                    key: "totalImpressions",
                    metric: (
                      <span>
                        Total Impressions{" "}
                        <Tooltip
                          title={
                            isSignificant(
                              experiment.statistics.changes.pages.impressions
                                .p_value
                            )
                              ? "Statistically significant"
                              : "Not statistically significant"
                          }
                        >
                          {isSignificant(
                            experiment.statistics.changes.pages.impressions
                              .p_value
                          ) ? (
                            <CheckCircleOutlined style={{ color: "green" }} />
                          ) : (
                            <CloseCircleOutlined style={{ color: "red" }} />
                          )}
                        </Tooltip>
                      </span>
                    ),
                    control:
                      experiment.statistics.control_period.pages
                        .total_impressions,
                    test: experiment.statistics.test_period.pages
                      .total_impressions,
                    absoluteChange:
                      experiment.statistics.changes.pages.impressions.absolute_change.toFixed(
                        2
                      ),
                    percentageChange:
                      experiment.statistics.changes.pages.impressions.percentage_change.toFixed(
                        2
                      ),
                  },
                  {
                    key: "totalClicks",
                    metric: (
                      <span>
                        Total Clicks{" "}
                        <Tooltip
                          title={
                            isSignificant(
                              experiment.statistics.changes.pages.clicks.p_value
                            )
                              ? "Statistically significant"
                              : "Not statistically significant"
                          }
                        >
                          {isSignificant(
                            experiment.statistics.changes.pages.clicks.p_value
                          ) ? (
                            <CheckCircleOutlined style={{ color: "green" }} />
                          ) : (
                            <CloseCircleOutlined style={{ color: "red" }} />
                          )}
                        </Tooltip>
                      </span>
                    ),
                    control:
                      experiment.statistics.control_period.pages.total_clicks,
                    test: experiment.statistics.test_period.pages.total_clicks,
                    absoluteChange:
                      experiment.statistics.changes.pages.clicks.absolute_change.toFixed(
                        2
                      ),
                    percentageChange:
                      experiment.statistics.changes.pages.clicks.percentage_change.toFixed(
                        2
                      ),
                  },
                  {
                    key: "averageCtr",
                    metric: "Average Click Through Rate",
                    control:
                      experiment.statistics.control_period.pages.average_ctr.toFixed(
                        2
                      ),
                    test: experiment.statistics.test_period.pages.average_ctr.toFixed(
                      2
                    ),
                    absoluteChange:
                      experiment.statistics.changes.pages.ctr.absolute_change.toFixed(
                        2
                      ),
                    percentageChange:
                      experiment.statistics.changes.pages.ctr.percentage_change.toFixed(
                        2
                      ),
                  },
                  {
                    key: "averagePosition",
                    metric: "Average Position",
                    control:
                      experiment.statistics.control_period.pages.average_position.toFixed(
                        2
                      ),
                    test: experiment.statistics.test_period.pages.average_position.toFixed(
                      2
                    ),
                    absoluteChange:
                      experiment.statistics.changes.pages.position.absolute_change.toFixed(
                        2
                      ),
                    percentageChange:
                      experiment.statistics.changes.pages.position.percentage_change.toFixed(
                        2
                      ),
                  },
                  {
                    key: "impressionsPerDay",
                    metric: "Impressions Per Day",
                    control:
                      experiment.statistics.control_period.pages.impressions_per_day.toFixed(
                        2
                      ),
                    test: experiment.statistics.test_period.pages.impressions_per_day.toFixed(
                      2
                    ),
                    absoluteChange:
                      experiment.statistics.changes.pages.impressions_per_day.absolute_change.toFixed(
                        2
                      ),
                    percentageChange:
                      experiment.statistics.changes.pages.impressions_per_day.percentage_change.toFixed(
                        2
                      ),
                  },
                  {
                    key: "clicksPerDay",
                    metric: "Clicks Per Day",
                    control:
                      experiment.statistics.control_period.pages.clicks_per_day.toFixed(
                        2
                      ),
                    test: experiment.statistics.test_period.pages.clicks_per_day.toFixed(
                      2
                    ),
                    absoluteChange:
                      experiment.statistics.changes.pages.clicks_per_day.absolute_change.toFixed(
                        2
                      ),
                    percentageChange:
                      experiment.statistics.changes.pages.clicks_per_day.percentage_change.toFixed(
                        2
                      ),
                  },
                  {
                    key: "siteClicksPerDay",
                    metric: "Site Clicks Per Day",
                    control:
                      experiment.statistics.control_period.site.clicks_per_day.toFixed(
                        2
                      ),
                    test: experiment.statistics.test_period.site.clicks_per_day.toFixed(
                      2
                    ),
                    absoluteChange:
                      experiment.statistics.changes.site.clicks_per_day.absolute_change.toFixed(
                        2
                      ),
                    percentageChange:
                      experiment.statistics.changes.site.clicks_per_day.percentage_change.toFixed(
                        2
                      ),
                  },
                ]}
                columns={[
                  {
                    title: "Metric",
                    dataIndex: "metric",
                    key: "metric",
                    render: (text, record) => {
                      let color;
                      if (record.key === "averagePosition") {
                        color =
                          parseFloat(record.absoluteChange) < 0
                            ? "green"
                            : "red";
                      } else {
                        color =
                          parseFloat(record.absoluteChange) > 0
                            ? "green"
                            : "red";
                      }
                      return (
                        <span>
                          <div
                            style={{
                              color,
                              display: "inline",
                            }}
                          >
                            ●
                          </div>{" "}
                          {text}
                        </span>
                      );
                    },
                  },
                  {
                    title: "Control Period",
                    dataIndex: "control",
                    key: "control",
                  },
                  {
                    title: "Test Period",
                    dataIndex: "test",
                    key: "test",
                  },
                  {
                    title: "Absolute Change",
                    dataIndex: "absoluteChange",
                    key: "absoluteChange",
                  },
                  {
                    title: "Percentage Change",
                    dataIndex: "percentageChange",
                    key: "percentageChange",
                  },
                ]}
                pagination={false}
              />
            </div>
          </>
        )}
      </Card>
      {experiment.target_keywords.length > 0 && (
        <Card title="Target Keyword Statistics" style={{ marginBottom: 16 }}>
          <Table
            columns={targetKeywordColumns}
            dataSource={targetKeywords}
            rowKey="id"
            loading={loadingTargetKeywords}
            onChange={handleTargetKeywordsSortChange}
            pagination={{
              current: targetKeywordsPagination.current,
              pagisize: targetKeywordsPagination.pageSize,
              total: totalTargetKeywords,
              onChange: (page, pageSize) =>
                setTargetKeywordsPagination({ current: page, pageSize }),
            }}
          />
        </Card>
      )}
      <Card title="Ranked Keyword Statistics" style={{ marginBottom: 16 }}>
        <Table
          columns={rankedKeywordColumns}
          dataSource={rankedKeywords}
          rowKey="id"
          loading={loadingRankedKeywords}
          onChange={handleRankedKeywordsSortChange}
          pagination={{
            current: rankedKeywordsPagination.current,
            pagisize: rankedKeywordsPagination.pageSize,
            total: totalRankedKeywords,
            onChange: (page, pageSize) =>
              setRankedKeywordsPagination({ current: page, pageSize }),
          }}
        />
      </Card>
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Statistical Results</span>
            <div>
              <Switch
                checked={showTrendLine}
                onChange={() => setShowTrendLine(!showTrendLine)}
                checkedChildren="Show Trendline"
                unCheckedChildren="Hide Trendline"
                style={{ marginLeft: 16 }}
              />
              <Switch
                checked={showCombinedChart}
                onChange={() => setShowCombinedChart(!showCombinedChart)}
                checkedChildren="Combined"
                unCheckedChildren="Separate"
                style={{ marginLeft: 16 }}
              />
            </div>
          </div>
        }
      >
        {statsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : statsError ? (
          <Typography.Text type="danger">
            Something went wrong while fetching the statistics data.
          </Typography.Text>
        ) : (
          <>
            {showCombinedChart ? (
              <Line {...getCombinedConfig()} />
            ) : (
              <>
                <div style={{ marginTop: 8 }}>
                  <Typography.Title level={4} style={{ marginTop: 0 }}>
                    Impressions
                  </Typography.Title>
                  <Line {...getConfig("impressions")} />
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography.Title level={4} style={{ marginTop: 0 }}>
                    Clicks
                  </Typography.Title>
                  <Line {...getConfig("clicks")} />
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography.Title level={4} style={{ marginTop: 0 }}>
                    CTR
                  </Typography.Title>
                  <Line {...getConfig("ctr")} />
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography.Title level={4} style={{ marginTop: 0 }}>
                    Position
                  </Typography.Title>
                  <Line {...getConfig("position")} />
                </div>
              </>
            )}
          </>
        )}
      </Card>
      <EditExperimentModal
        isOpen={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
        selectedExperimentId={experiment?.id}
        fetchExperiments={fetchExperimentData}
      />
    </div>
  );
};

export default ExperimentDetails;

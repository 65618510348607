import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Modal,
  Button,
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Typography,
  message,
} from "antd";
import moment from "moment";
import { getRelatedChangeLogs } from "../../api/Changelog";
import { createExperiment } from "../../api/Experiments";
import { fetchKeywords } from "../../store/keywordsSlice"; // Voeg deze import toe

const CreateExperimentModal = ({ isOpen, onClose, selectedChangeLog }) => {
  const dispatch = useDispatch();
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const { keywords, status: keywordsStatus } = useSelector(
    (state) => state.keywords
  );
  const [form] = Form.useForm();
  const [testPeriod, setTestPeriod] = useState(14);
  const [customPeriod, setCustomPeriod] = useState(false);
  const [adjustDates, setAdjustDates] = useState(true);
  const [controlStartDate, setControlStartDate] = useState();
  const [controlEndDate, setControlEndDate] = useState();
  const [testStartDate, setTestStartDate] = useState();
  const [testEndDate, setTestEndDate] = useState();
  const [relatedChangeLogs, setRelatedChangeLogs] = useState([]);
  const [experimentName, setExperimentName] = useState("");
  const [hypothesis, setHypothesis] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const notFoundContent =
    keywordsStatus === "loading" ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spin style={{ marginRight: 8 }} />
        Loading keywords...
      </div>
    ) : (
      "No keywords found"
    );

  useEffect(() => {
    if (isOpen && keywordsStatus === "idle") {
      dispatch(fetchKeywords({})); // Fetch all keywords if they haven't been fetched yet
    }
  }, [dispatch, isOpen, keywordsStatus]);

  useEffect(() => {
    const fetchRelatedChangeLogs = async () => {
      if (selectedChangeLog && isOpen) {
        setLoading(true);
        setError(null);
        const changeData = moment(selectedChangeLog.created_at);
        const url = selectedChangeLog.page?.url;

        try {
          const fetchedLogs = await getRelatedChangeLogs(
            selectedWebsiteId,
            url,
            changeData
              .clone()
              .subtract(testPeriod, "days")
              .format("YYYY-MM-DD"),
            changeData.clone().add(testPeriod, "days").format("YYYY-MM-DD")
          );
          const mappedLogs = fetchedLogs.map((log) => ({
            ...log,
            isEnabled: true,
          }));
          setRelatedChangeLogs(mappedLogs);
          calculateDates(mappedLogs, adjustDates);
        } catch (error) {
          console.error("Failed to fetch related changelogs:", error);
          setError("Failed to load related changelogs.");
          setRelatedChangeLogs([]);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchRelatedChangeLogs();
  }, [isOpen, testPeriod, selectedChangeLog, adjustDates]);

  useEffect(() => {
    calculateDates(
      relatedChangeLogs.filter((log) => log.isEnabled),
      adjustDates
    );
  }, [relatedChangeLogs, adjustDates]);

  const calculateDates = (logs, adjust) => {
    if (adjust) {
      if (logs.length > 0) {
        const sortedLogs = logs.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        const earliestDate = moment(sortedLogs[0].created_at);
        const latestDate = moment(sortedLogs[logs.length - 1].created_at);

        setControlStartDate(
          earliestDate.clone().subtract(testPeriod, "days").format("YYYY-MM-DD")
        );
        setControlEndDate(
          earliestDate.clone().subtract(1, "days").format("YYYY-MM-DD")
        );
        setTestStartDate(
          latestDate.clone().add(1, "days").format("YYYY-MM-DD")
        );
        setTestEndDate(
          latestDate.clone().add(testPeriod, "days").format("YYYY-MM-DD")
        );
      }
    } else {
      const changeDate = moment(selectedChangeLog.created_at);
      setControlStartDate(
        changeDate.clone().subtract(testPeriod, "days").format("YYYY-MM-DD")
      );
      setControlEndDate(
        changeDate.clone().subtract(1, "days").format("YYYY-MM-DD")
      );
      setTestStartDate(changeDate.clone().add(1, "days").format("YYYY-MM-DD"));
      setTestEndDate(
        changeDate.clone().add(testPeriod, "days").format("YYYY-MM-DD")
      );
    }
  };

  const toggleChangeLogEnabled = (id) => {
    setRelatedChangeLogs((prevLogs) =>
      prevLogs.map((log) =>
        log.id === id ? { ...log, isEnabled: !log.isEnabled } : log
      )
    );
  };

  const handleChangeKeywords = (newSelectedKeywords) => {
    setSelectedKeywords(newSelectedKeywords);
  };

  const handleCreateExperiment = async () => {
    const values = await form.validateFields();
    const experimentData = {
      experiment_name: values.experimentName,
      hypothesis: hypothesis,
      control_start_date: controlStartDate,
      control_end_date: controlEndDate,
      test_start_date: testStartDate,
      test_end_date: testEndDate,
      target_keywords: selectedKeywords,
      changelogs: relatedChangeLogs
        .filter((log) => log.isEnabled)
        .map((log) => log.id),
      control_pages: [selectedChangeLog.page.url],
      test_pages: [selectedChangeLog.page.url],
      website_id: selectedWebsiteId,
    };
    console.log(experimentData);

    try {
      await createExperiment(experimentData);
      message.success("Experiment created successfully!");
      setExperimentName("");
      setHypothesis("");
      setControlStartDate("");
      setControlEndDate("");
      setTestStartDate("");
      setTestEndDate("");
      setSelectedKeywords([]);
      form.resetFields();
      onClose();
    } catch (error) {
      message.error("Failed to create experiment. Please try again.");
    }
  };

  return (
    <Modal
      title="Create Experiment"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={onClose}
      okText="Create Experiment"
      cancelText="Cancel"
      width={1000}
    >
      <Form form={form} onFinish={handleCreateExperiment}>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Card title="Selected Changelog">
            <Row>
              <Col xs={24} md={12}>
                <div>
                  <Typography.Text strong>Page URL: </Typography.Text>
                  <span>{selectedChangeLog?.page?.url}</span>
                </div>
                <div>
                  <Typography.Text strong>Date: </Typography.Text>
                  <span>
                    {moment(selectedChangeLog?.created_at).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div>
                  <Typography.Text strong>Change Type: </Typography.Text>
                  <span>{selectedChangeLog?.changetype?.name}</span>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div>
                  <Typography.Text strong>Index: </Typography.Text>
                  <span>{selectedChangeLog?.index}</span>
                </div>
                <div>
                  <Typography.Text strong>Old Value: </Typography.Text>
                  <span>{selectedChangeLog?.old_value}</span>
                </div>
                <div>
                  <Typography.Text strong>New Value: </Typography.Text>
                  <span>{selectedChangeLog?.new_value}</span>
                </div>
              </Col>
            </Row>
          </Card>
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>Experiment Period</span>
                <Switch
                  checkedChildren="Adjust Dates"
                  unCheckedChildren="Fixed Dates"
                  checked={adjustDates}
                  onChange={(checked) => setAdjustDates(checked)}
                />
              </div>
            }
            style={{ marginBottom: 16 }}
          >
            <Form.Item>
              <Radio.Group
                defaultValue={14}
                onChange={(e) => {
                  if (e.target.value === "custom") {
                    setCustomPeriod(true);
                  } else {
                    setCustomPeriod(false);
                    setTestPeriod(e.target.value);
                  }
                }}
                style={{ width: "100%" }}
              >
                <Radio.Button
                  value={7}
                  style={{ width: "25%", textAlign: "center" }}
                >
                  7 Days
                </Radio.Button>
                <Radio.Button
                  value={14}
                  style={{ width: "25%", textAlign: "center" }}
                >
                  14 Days
                </Radio.Button>
                <Radio.Button
                  value={30}
                  style={{ width: "25%", textAlign: "center" }}
                >
                  30 Days
                </Radio.Button>
                <Radio.Button
                  value="custom"
                  style={{ width: "25%", textAlign: "center" }}
                >
                  {customPeriod ? (
                    <Input
                      size="small"
                      type="number"
                      min={1}
                      value={testPeriod}
                      onChange={(e) => {
                        setTestPeriod(parseInt(e.target.value, 10));
                      }}
                      suffix="Days"
                      style={{ marginTop: 3 }}
                    />
                  ) : (
                    "Custom"
                  )}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div style={{ flex: "1 1 300px", textAlign: "center" }}>
                <Typography.Text strong>Control Period: </Typography.Text>
                <Typography.Text>
                  From {controlStartDate} to {controlEndDate}
                </Typography.Text>
              </div>
              <div style={{ flex: "1 1 300px", textAlign: "center" }}>
                <Typography.Text strong>Test Period: </Typography.Text>
                <Typography.Text>
                  From {testStartDate} to {testEndDate}
                </Typography.Text>
              </div>
            </div>
          </Card>
          <Card title="Related Changelogs for Page and Period">
            <Table
              columns={[
                {
                  title: "Date",
                  dataIndex: "created_at",
                  key: "date",
                  render: (text, record) => (
                    <span
                      style={record.isEnabled ? {} : { color: "lightgrey" }}
                    >
                      {moment(text).format("DD/MM/YYYY")}
                    </span>
                  ),
                },
                {
                  title: "Type",
                  dataIndex: ["changetype", "name"],
                  key: "type",
                  render: (text, record) => (
                    <span
                      style={{
                        color: record.isEnabled ? "inherit" : "lightgrey",
                      }}
                    >
                      {text}
                    </span>
                  ),
                },
                {
                  title: "Action",
                  key: "action",
                  render: (text, record) => {
                    if (
                      selectedChangeLog &&
                      record.id === selectedChangeLog.id
                    ) {
                      return null;
                    }
                    return (
                      <Button
                        type={record.isEnabled ? "danger" : "primary"}
                        onClick={() => toggleChangeLogEnabled(record.id)}
                      >
                        {record.isEnabled ? "Disable" : "Enable"}
                      </Button>
                    );
                  },
                },
              ]}
              dataSource={relatedChangeLogs.filter(
                (log) => log.id !== selectedChangeLog.id
              )}
              rowKey="id"
              rowStyle
              bordered
              pagination={false}
              expandedRowRender={(log) => (
                <div
                  style={{
                    color: log.isEnabled ? "inherit" : "lightgrey",
                  }}
                >
                  <Row>
                    <Col xs={24} md={12}>
                      <p>
                        <strong style={{ fontWeight: 500 }}>Page URL: </strong>
                        <span>{log.page.url}</span>
                      </p>
                      <p>
                        <strong style={{ fontWeight: 500 }}>Index: </strong>
                        <span>{log.index}</span>
                      </p>
                    </Col>
                    <Col xs={24} md={12}>
                      <p>
                        <strong style={{ fontWeight: 500 }}>Old Value: </strong>
                        <span>{log.old_value}</span>
                      </p>
                      <p>
                        <strong style={{ fontWeight: 500 }}>New Value: </strong>
                        <span>{log.new_value}</span>
                      </p>
                    </Col>
                  </Row>
                </div>
              )}
            />
          </Card>
          <Card title="Experiment Name and Goals">
            <Typography.Text strong>Experiment Name:</Typography.Text>
            <Form.Item
              name="experimentName"
              rules={[{ required: true, message: "Please enter a name!" }]}
            >
              <Input
                placeholder="Experiment name"
                style={{ marginBottom: 8 }}
              />
            </Form.Item>
            <Typography.Text strong>Hypothesis:</Typography.Text>
            <ReactQuill
              theme="snow"
              value={hypothesis}
              onChange={(content) => setHypothesis(content)}
              style={{ marginBottom: 8 }}
            />
            <Typography.Text strong>Keywords:</Typography.Text>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Select or add keywords"
              onChange={handleChangeKeywords}
              tokenSeparators={[","]}
              options={keywords.map((keyword) => ({
                label: keyword.keyword_text,
                value: keyword.keyword_text,
              }))}
              value={selectedKeywords}
              notFoundContent={notFoundContent}
            />
          </Card>
        </Space>
      </Form>
    </Modal>
  );
};

export default CreateExperimentModal;

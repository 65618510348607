const KeywordColumns = () => [
  {
    title: "Keyword",
    dataIndex: "keyword_text",
    key: "keyword_text",
    sorter: true,
    defaultSortOrder: "ascend",
  },
  {
    title: "Impressions",
    dataIndex: "total_impressions",
    key: "total_impressions",
    sorter: true,
    render: (value) => (value !== null ? value.toLocaleString() : "0"), // Using toLocaleString for better number formatting
  },
  {
    title: "Clicks",
    dataIndex: "total_clicks",
    key: "total_clicks",
    sorter: true,
    render: (value) => (value !== null ? value.toLocaleString() : "0"),
  },
  {
    title: "CTR",
    dataIndex: "average_ctr",
    key: "average_ctr",
    sorter: true,
    render: (value) => (value !== null ? `${value.toFixed(2)}%` : "0%"),
  },
  {
    title: "Position",
    dataIndex: "average_position",
    key: "average_position",
    sorter: true,
    render: (value) => (value !== null ? value.toFixed(2) : "N/A"),
  },
];

export default KeywordColumns;

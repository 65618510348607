import { instance, handleError } from "./apiUtils";

export const getWebsites = async () => {
  try {
    const response = await instance.get("websites/");
    console.log("Websites: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getWebsiteStatistics = async (
  websiteId,
  filters = {},
  data_type = "total",
  aggregation = "day"
) => {
  const url = `websites/${websiteId}/statistics/?filters=${encodeURIComponent(
    JSON.stringify(filters)
  )}&data_type=${data_type}&aggregation=${aggregation}`;
  try {
    const response = await instance.get(url);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const createWebsite = async (websiteData) => {
  console.log("data: ", websiteData);
  try {
    const response = await instance.post("websites/", websiteData);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const getWebsiteById = async (websiteId) => {
  try {
    const response = await instance.get(`websites/${websiteId}/`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const updateWebsiteById = async (websiteId, websiteData) => {
  try {
    const response = await instance.put(`websites/${websiteId}/`, websiteData);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteWebsiteById = async (websiteId) => {
  try {
    const response = await instance.delete(`websites/${websiteId}/`);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

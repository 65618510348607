import { instance, handleError } from "./apiUtils";

// Function to get a list of teams
export const getTeams = async () => {
  try {
    const response = await instance.get("teams/");
    console.log("Teams: ", response.data); // Log the response
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to get details of a specific team by ID
export const getTeamById = async (teamId) => {
  try {
    const response = await instance.get(`teams/${teamId}/`);
    console.log("Team Details: ", response.data); // Log the response
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to create a new team
export const createTeam = async (teamData) => {
  console.log("Create Team: ", teamData);
  try {
    const response = await instance.post("teams/", teamData);
    console.log("Created Team: ", response.data); // Log the response
    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};

// Function to update an existing team by ID
export const updateTeamById = async (teamId, teamData) => {
  try {
    const response = await instance.put(`teams/${teamId}/`, teamData);
    console.log("Updated Team: ", response.data); // Log the response
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to delete a team by ID
export const deleteTeamById = async (teamId) => {
  try {
    const response = await instance.delete(`teams/${teamId}/`);
    console.log("Deleted Team: ", response.data); // Log the response
    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};

// Function to get members of a specific team by ID
export const getTeamMembers = async (teamId) => {
  try {
    const response = await instance.get(`teams/${teamId}/members/`);
    console.log("Team Members: ", response.data); // Log the response
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to add a member to a team
export const addTeamMember = async (teamId, memberData) => {
  try {
    const response = await instance.post(
      `teams/${teamId}/members/add/`,
      memberData
    );
    console.log("Added Team Member: ", response.data); // Log the response
    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};

// Function to remove a member from a team
export const removeTeamMember = async (teamId, userId) => {
  try {
    const response = await instance.delete(
      `teams/${teamId}/members/${userId}/delete/`
    );
    console.log("Removed Team Member: ", response.data); // Log the response
    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};

import React from "react";

const ChangeLogDetails = ({ record, xxlDown, mdDown }) => {
  const details = [];

  if (xxlDown) {
    details.push({ label: "Page URL", value: record.page.url });
  }

  if (xxlDown) {
    details.push({ label: "Index", value: record.index });
  }

  if (mdDown) {
    details.push(
      { label: "Old Value", value: record.old_value },
      { label: "New Value", value: record.new_value }
    );
  }

  return (
    <div style={{ margin: "10px 0" }}>
      {details.map((detail, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          <strong>{detail.label}:</strong> {detail.value}
        </div>
      ))}
    </div>
  );
};

export default ChangeLogDetails;

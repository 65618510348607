import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
import Login from "./components/Login";
import Register from "./components/Register";
import PasswordResetRequest from "./components/PasswordResetRequest";
import PasswordResetConfirm from "./components/PasswordResetConfirm";
import HomeRedirect from "./routes/HomeRoute";
import SecureRoutes from "./routes/SecureRoutes";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route path="/" element={<HomeRedirect />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/password-reset" element={<PasswordResetRequest />} />
            <Route
              path="/password-reset/confirm/:userId/:token"
              element={<PasswordResetConfirm />}
            />
            <Route path="/*" element={<SecureRoutes />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;

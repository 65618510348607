import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserProfile,
  updateUserProfileInfo,
  changeUserPassword,
} from "../../store/userSlice";
import { Form, Input, Button, message, Tabs, Typography } from "antd";

const { TabPane } = Tabs;

function UserProfile() {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.profile);
  const loading = useSelector((state) => state.user.status) === "loading";
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();

  useEffect(() => {
    if (!userProfile) {
      dispatch(fetchUserProfile());
    } else {
      form.setFieldsValue(userProfile);
    }
  }, [dispatch, userProfile, form]);

  const handleSubmit = (values) => {
    dispatch(updateUserProfileInfo(values))
      .unwrap()
      .then(() => message.success("Profile updated successfully"))
      .catch((error) =>
        message.error(
          "Failed to update profile: " + (error.message || "Unknown error")
        )
      );
  };

  const handlePasswordChange = (values) => {
    if (values.new_password !== values.confirm_new_password) {
      message.error("The new passwords do not match.");
      return;
    }
    dispatch(
      changeUserPassword({
        current_password: values.current_password,
        new_password: values.new_password,
      })
    )
      .unwrap()
      .then(() => {
        message.success("Password changed successfully.");
        passwordForm.resetFields();
      })
      .catch((error) =>
        message.error(
          "Failed to change password: " + (error || "Unknown error")
        )
      );
  };

  return (
    <div>
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        User Profile
      </Typography.Title>
      <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}>
        <TabPane tab="Edit Profile" key="1">
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item label="Email" name="email">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update Profile
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="Change Password" key="2">
          <Form
            form={passwordForm}
            onFinish={handlePasswordChange}
            layout="vertical"
          >
            <Form.Item
              label="Current Password"
              name="current_password"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="new_password"
              rules={[
                { required: true, message: "Please enter your new password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirm_new_password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default UserProfile;

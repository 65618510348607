import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Badge,
  Button,
  Input,
  Segmented,
  Tree,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { template } from "lodash";

function ImportPageModal({ isOpen, onClose }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [treeSearchText, setTreeSearchText] = useState("");
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [visiblePageKeys, setVisiblePageKeys] = useState([]);
  const [selectedPageKeys, setSelectedPageKeys] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      message.success("Page(s) added successfully!");
      onClose();
    } catch (error) {
      message.error("Failed to add page(s). Please try again.");
    }
    setLoading(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    const uniqueKeys = [
      ...new Set([...selectedPageKeys, ...newSelectedRowKeys]),
    ];
    setSelectedPageKeys(uniqueKeys);
  };

  const onSelectDeselect = (record) => {
    const updatedKeys = selectedPageKeys.filter((key) => key !== record.key);
    setSelectedPageKeys(updatedKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedPageKeys,
    onSelect: (record, selected) => {
      if (selected === true) {
        onSelectChange([...selectedPageKeys, record.key]);
      } else {
        onSelectDeselect(record);
      }
    },
    onSelectAll: (selected, selectedRows) => {
      const currentVisibleKeys = filteredData.map((item) => item.key);
      if (selected) {
        const allKeys = [
          ...new Set([...selectedPageKeys, ...currentVisibleKeys]),
        ];
        setSelectedPageKeys(allKeys);
      } else {
        const updatedKeys = selectedPageKeys.filter(
          (key) => !currentVisibleKeys.includes(key)
        );
        setSelectedPageKeys(updatedKeys);
      }
    },
  };

  useEffect(() => {
    // Example data from Google Search Console
    const initialData = [
      {
        key: "1",
        url: "https://www.tools4ever.nl/software/helloid-cloud-identity-access-management/",
        clicks: 30,
        impressions: 330,
        ctr: 0.09090909090909091,
        position: 6.381818181818182,
      },
      {
        key: "2",
        url: "https://www.tools4ever.nl/",
        clicks: 21,
        impressions: 107,
        ctr: 0.19626168224299065,
        position: 8.962616822429908,
      },
      {
        key: "3",
        url: "https://www.tools4ever.nl/kennisbank/security-assertion-markup-language-saml/",
        clicks: 10,
        impressions: 176,
        ctr: 0.056818181818181816,
        position: 5.6875,
      },
    ];

    setData(initialData);
    setFilteredData(initialData);

    const treeData = buildTreeData(initialData);
    setExpandedKeys(treeData.map((node) => node.key));
    setFilteredTreeData(treeData);
    setVisiblePageKeys(expandedKeys);
  }, []);

  useEffect(() => {
    const keys = filteredData.map((item) => item.key);
    setVisiblePageKeys(keys);
  }, [filteredData]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search URL`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys([e.target.value])}
          onPressEnter={() => {
            confirm();
            setSearchText(selectedKeys[0]);
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => {
            confirm();
            setSearchText(selectedKeys[0]);
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toLowerCase().includes(value.toLowerCase()),
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  const columns = [
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      ...getColumnSearchProps("url"),
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      key: "ctr",
      sorter: (a, b) => a.ctr - b.ctr,
      render: (value) => {
        const percentage = value !== null ? value * 100 : 0;
        return `${percentage.toFixed(2)}%`;
      },
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      sorter: (a, b) => a.position - b.position,
      render: (value) => (value !== null ? value.toFixed(2) : "N/A"),
    },
  ];

  const buildTreeData = (urls) => {
    const treeData = [
      {
        title: "/",
        key: "/",
        children: [],
      },
    ];

    urls.forEach((item) => {
      const urlPath = new URL(item.url).pathname;
      const segments = urlPath.split("/").filter((s) => s !== "");

      let currentNode = treeData[0].children; // Start from the root

      segments.forEach((segment, index) => {
        let keyPath = "/" + segments.slice(0, index + 1).join("/"); // Prepend with "/"
        let existingNode = currentNode.find((node) => node.key === keyPath);

        if (!existingNode) {
          existingNode = {
            title: segment,
            key: keyPath,
            children: [],
          };
          currentNode.push(existingNode);
        }

        currentNode = existingNode.children; // Go to the next level
      });
    });

    return treeData;
  };

  const getAllParentKeys = (key, tree) => {
    let parentKeys = [];
    tree.forEach((node) => {
      if (node.children) {
        const childKeys = node.children.map((child) => child.key);

        if (childKeys.includes(key)) {
          parentKeys.push(node.key);
          parentKeys.push(...getAllParentKeys(node.key, tree));
        } else {
          const deeperKeys = getAllParentKeys(key, node.children);
          parentKeys.push(...deeperKeys);
        }
      }
    });
    return parentKeys;
  };

  const handleTreeSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setTreeSearchText(searchValue);

    const treeData = buildTreeData(data);

    const expandedKeys = [];

    const gatherMatchingKeys = (nodes) => {
      nodes.forEach((node) => {
        if (node.title.toLowerCase().includes(searchValue)) {
          expandedKeys.push(node.key);

          const parentKeys = getAllParentKeys(node.key, treeData);
          expandedKeys.push(...parentKeys);
        }

        if (node.children) {
          gatherMatchingKeys(node.children);
        }
      });
    };

    gatherMatchingKeys(treeData);

    setExpandedKeys([...new Set(expandedKeys)]);
    setAutoExpandParent(true);
  };
  const renderTreeNodes = (nodes) => {
    return nodes.map((node) => (
      <Tree.TreeNode key={node.key} title={node.title}>
        {node.children && renderTreeNodes(node.children)}
      </Tree.TreeNode>
    ));
  };

  const handleSegmentSelection = (selectedKeys) => {
    const filtered = data.filter((item) => {
      const urlPath = new URL(item.url).pathname;

      return selectedKeys.some((key) => {
        const keyWithLeadingSlash = key.startsWith("/") ? key : `/${key}`;
        return urlPath.startsWith(keyWithLeadingSlash);
      });
    });

    setFilteredData(filtered);
  };

  const selectedPagesColumns = [
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text) => <a href={text}>{text}</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Button type="danger" onClick={() => onSelectDeselect(record)}>
          Remove
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title="Import New Pages"
      open={isOpen}
      onCancel={onClose}
      okText="Add Pages"
      cancelText="Cancel"
      width={1000}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Filter by Website Structure:</h3>
          <Segmented
            options={[
              { label: "Last 7 days", value: 7 },
              { label: "Last 14 days", value: 14 },
              { label: "Last 30 days", value: 30 },
            ]}
            defaultValue={7}
            style={{ width: 300 }} // Adjust width as needed
          />
        </div>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search Structure"
          value={treeSearchText}
          onChange={handleTreeSearch}
          style={{ marginBottom: 10 }}
        />
        <Tree
          checkable
          defaultCheckedKeys={expandedKeys}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onExpand={(keys) => {
            setExpandedKeys(keys);
            setAutoExpandParent(false);
          }}
          onCheck={handleSegmentSelection}
        >
          {renderTreeNodes(buildTreeData(data))}
        </Tree>
      </div>
      <div style={{ marginTop: 20 }}>
        <h3>Found Pages:</h3>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ pageSize: 5 }}
          rowSelection={rowSelection}
          rowKey={(record) => record.key}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Selected Pages:</h3>
          <Badge
            count={selectedPageKeys.length}
            overflowCount={5000}
            style={{ backgroundColor: "#0652DD" }}
          />
        </div>
        <Table
          columns={selectedPagesColumns}
          dataSource={selectedPageKeys.map((key) => {
            const page = data.find((item) => item.key === key);
            return {
              key: page.key,
              url: page.url,
            };
          })}
          pagination={false}
          rowKey={(record) => record.key}
        />
      </div>
    </Modal>
  );
}

export default ImportPageModal;

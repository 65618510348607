import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const HomeRoute = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const tokenExpiresAt = useSelector((state) => state.user.tokenExpiresAt);
  const isTokenExpired = new Date().getTime() > tokenExpiresAt;

  /*console.log("isAuthenticated: ", isAuthenticated);
  console.log("tokenExpiresAt: ", tokenExpiresAt);
  console.log("isTokenExpired: ", isTokenExpired);*/

  if (!isAuthenticated || isTokenExpired) {
    return <Navigate to="/login" />;
  }

  return <Navigate to="/dashboard" />;
};

export default HomeRoute;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useResponsive } from "../../hooks/useResponsive";
import {
  Card,
  Timeline,
  Typography,
  Tooltip,
  Switch,
  Table,
  Segmented,
  Spin,
  Input,
} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Line } from "@ant-design/charts";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  getPageDetail,
  getPageChangeLogs,
  getPagesStatistics,
} from "../../api/Pages"; // Adjust the import path if needed
import PageChangeLogColumns from "./PageChangeLogColumns"; // Import the columns configuration
import PageChangeLogDetails from "./PageChangeLogDetails";
import CreateExperimentModal from "../Changelog/CreateExperimentModal";
import { useChangeTypes } from "../../hooks/useChangeTypes";
import { usePageRankedKeywords } from "../../hooks/usePageRankedKeywords";
const { Search } = Input;

const PageDetails = () => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const { pageId } = useParams();
  const [showTrendLine, setShowTrendLine] = useState(true);
  const [showCombinedChart, setShowCombinedChart] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [changeLogs, setChangelogs] = useState([]);
  const [statisticsData, setStatisticsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statsError, setStatsError] = useState(null);
  const [aggregation, setAggregation] = useState("day");
  const [selectedPeriod, setSelectedPeriod] = useState(7);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedChangeType, setSelectedChangeType] = useState([]);
  const [showInitial, setShowInitial] = useState(false);
  const { changeTypes, loadingChangeTypes } = useChangeTypes();
  const [selectedChangeLog, setSelectedChangeLog] = useState(null);
  const { mdDown, xxlDown, xxlUp } = useResponsive();
  const [isCreateExperimentModalVisible, setIsCreateExperimentModalVisible] =
    useState(false);

  const [rankedKeywordsPagination, setRankedKeywordsPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [rankedKeywordsFilters, setRankedKeywordsFilters] = useState({
    sortField: "keyword_text",
    sortOrder: "asc",
  });
  const { rankedKeywords, loadingRankedKeywords, totalRankedKeywords } =
    usePageRankedKeywords(
      pageId,
      rankedKeywordsPagination,
      rankedKeywordsFilters,
      selectedPeriod
    );

  const fetchChangelogs = async () => {
    const pageChangeLogs = await getPageChangeLogs(pageId, selectedWebsiteId, {
      page: pagination.current,
      page_size: pagination.pageSize,
      search: searchTerm,
      changetype: selectedChangeType,
      show_initial: showInitial ? "true" : "false",
      ordering: sortOrder
        ? `${sortOrder === "asc" ? "" : "-"}${sortField}`
        : undefined,
    });
    setChangelogs(pageChangeLogs.results);

    setPagination((prev) => ({
      ...prev,
      total: pageChangeLogs.count,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const [pageDetail, pageChangeLogs] = await Promise.all([
          getPageDetail(pageId),
        ]);
        fetchChangelogs();
        setPageData(pageDetail);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pageId]);

  useEffect(() => {
    fetchChangelogs();
  }, [
    pageId,
    pagination.current,
    pagination.pageSize,
    searchTerm,
    selectedChangeType,
    showInitial,
    sortOrder,
    sortField,
  ]);

  useEffect(() => {
    const fetchStatisticsData = async () => {
      setStatsLoading(true);
      setStatsError(null);
      try {
        const statsData = await getPagesStatistics(
          pageData.website.id,
          [pageId],
          { last_days: selectedPeriod },
          "record",
          aggregation,
          "true"
        );
        setStatisticsData(statsData);
      } catch (err) {
        setStatsError(err);
      } finally {
        setStatsLoading(false);
      }
    };

    if (pageData) {
      fetchStatisticsData();
    }
  }, [pageData, selectedPeriod]);

  const calculateTrendline = (data, field) => {
    const n = data.length;
    const sumX = data.reduce((acc, curr) => acc + curr.dateNum, 0);
    const sumY = data.reduce((acc, curr) => acc + curr[field], 0);
    const sumXY = data.reduce(
      (acc, curr) => acc + curr.dateNum * curr[field],
      0
    );
    const sumXX = data.reduce(
      (acc, curr) => acc + curr.dateNum * curr.dateNum,
      0
    );

    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    const trendDirection = slope >= 0 ? "Positive" : "Negative";

    const trendData = data.map((item) => ({
      ...item,
      [field]: slope * item.dateNum + intercept,
      line: "Trend",
      type: "Trend",
    }));

    return { trendDirection, trendData };
  };

  const getConfig = (field) => {
    let pagesStatisticsData = statisticsData.map((item) => ({
      ...item,
      type: item.type,
      line: "Data",
      dateNum: new Date(item.truncated_date).getTime(),
      [field]: item[field],
    }));

    let trendData = [];
    let trendDirection = "Positive";
    let trendColor = "#52c41a";

    if (showTrendLine) {
      const trendResults = calculateTrendline(
        pagesStatisticsData.filter((d) => d.type === "Actual"),
        field
      );
      trendDirection = trendResults.trendDirection;
      trendData = trendResults.trendData.map((item) => ({
        ...item,
        [field]: Math.max(item[field], 0),
      }));

      if (field === "position") {
        trendColor = trendDirection === "Positive" ? "#ff4d4f" : "#52c41a";
      } else {
        trendColor = trendDirection === "Positive" ? "#52c41a" : "#ff4d4f";
      }
    }

    return {
      data: [...pagesStatisticsData, ...trendData],
      xField: "truncated_date",
      yField: field,
      animate: true,
      smooth: true,
      seriesField: "type",
      colorField: "type",
      shapeField: "line",
      legend: false,
      style: {
        lineWidth: 2,
      },
      scale: {
        color: {
          domain: [
            "Actual",
            "Missing before",
            "Missing after",
            "Missing",
            "Trend",
          ],
          range: ["#1890ff", "#808080", "#808080", "#808080", trendColor],
        },
      },
    };
  };

  const handleRankedKeywordsSortChange = (pagination, filters, sorter) => {
    setRankedKeywordsPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    if (sorter.order) {
      const newSortField = Array.isArray(sorter.field)
        ? sorter.field.join("__")
        : sorter.field;
      const newSortOrder =
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : null;
      setRankedKeywordsFilters((prev) => ({
        ...prev,
        sortField: newSortField,
        sortOrder: newSortOrder,
      }));
    }
  };

  const rankedKeywordColumns = [
    {
      title: "Keyword",
      dataIndex: "keyword_text",
      key: "keyword_text",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: "Impressions",
      dataIndex: "total_impressions",
      key: "total_impressions",
      sorter: true,
    },
    {
      title: "Clicks",
      dataIndex: "total_clicks",
      key: "total_clicks",
      sorter: true,
    },
    {
      title: "CTR",
      dataIndex: "average_ctr",
      key: "average_ctr",
      render: (text) => parseFloat(text).toFixed(2),
      sorter: true,
    },
    {
      title: "Position",
      dataIndex: "average_position",
      key: "average_position",
      render: (text) => parseFloat(text).toFixed(2),
      sorter: true,
    },
  ];

  const handleFilterChange = useCallback((pagination, filters, sorter) => {
    setSelectedChangeType(filters.type || []);
    const newSortField = Array.isArray(sorter.field)
      ? sorter.field.join("__")
      : sorter.field;
    const newSortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : null;
    setSortField(newSortField);
    setSortOrder(newSortOrder);
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (error || !pageData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography.Text type="danger">
          {error
            ? "Something went wrong while fetching the page details."
            : "Page not found."}
        </Typography.Text>
      </div>
    );
  }

  return (
    <div>
      <Typography.Title level={2} style={{ marginTop: 0, marginBottom: 16 }}>
        Page Details
      </Typography.Title>

      <Card title="Page Details" style={{ marginBottom: 16 }}>
        <Typography.Paragraph>
          <strong>URL:</strong>{" "}
          <a href={pageData.url} target="_blank" rel="noopener noreferrer">
            {pageData.url}
          </a>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Title:</strong> {pageData.title}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Description:</strong> {pageData.meta_description}
        </Typography.Paragraph>
      </Card>

      <Card title="Changelog" style={{ marginBottom: 16 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <Search
            placeholder="Search logs"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ flexGrow: 1, marginRight: 8 }}
          />
          <Switch
            checked={showInitial}
            onChange={() => setShowInitial(!showInitial)}
            checkedChildren="Show Initial"
            unCheckedChildren="Hide Initial"
            style={{ width: "150px", marginRight: 8 }}
          />
        </div>
        <Table
          columns={PageChangeLogColumns({
            changeTypeFilters: changeTypes,
            setSelectedChangeLog,
            setIsCreateExperimentModalVisible,
            showInitial,
            mdDown,
            xxlUp,
          })}
          dataSource={changeLogs}
          rowKey="id"
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) =>
              setPagination({ current: page, pageSize }),
          }}
          onChange={handleFilterChange}
          expandedRowRender={
            !xxlUp
              ? (record) => (
                  <PageChangeLogDetails
                    record={record}
                    xxlDown={xxlDown}
                    mdDown={mdDown}
                    setSelectedChangeLog={setSelectedChangeLog}
                  />
                )
              : null
          }
        />
      </Card>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
          Page Statistics
        </Typography.Title>
        <Segmented
          options={[
            { label: "Last 7 days", value: 7 },
            { label: "Last 14 days", value: 14 },
            { label: "Last 30 days", value: 30 },
            { label: "Last 90 days", value: 90 },
            { label: "Last 180 days", value: 180 },
            { label: "Last 365 days", value: 365 },
          ]}
          defaultValue={7}
          style={{ width: 600, marginRight: 8 }}
          onChange={(value) => setSelectedPeriod(value)}
        />
      </div>

      <Card title="Keywords" style={{ marginBottom: 16 }}>
        <Table
          columns={rankedKeywordColumns}
          dataSource={rankedKeywords}
          rowKey="id"
          loading={loadingRankedKeywords}
          onChange={handleRankedKeywordsSortChange}
          pagination={{
            current: rankedKeywordsPagination.current,
            pagisize: rankedKeywordsPagination.pageSize,
            total: totalRankedKeywords,
            onChange: (page, pageSize) =>
              setRankedKeywordsPagination({ current: page, pageSize }),
          }}
        />
      </Card>

      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Charts</span>
            <div>
              <Switch
                checked={showTrendLine}
                onChange={() => setShowTrendLine(!showTrendLine)}
                checkedChildren="Show Trendline"
                unCheckedChildren="Hide Trendline"
                style={{ marginLeft: 16 }}
              />
              <Switch
                checked={showCombinedChart}
                onChange={() => setShowCombinedChart(!showCombinedChart)}
                checkedChildren="Combined"
                unCheckedChildren="Separate"
                style={{ marginLeft: 16 }}
              />
            </div>
          </div>
        }
      >
        {statsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : statsError ? (
          <Typography.Text type="danger">
            Something went wrong while fetching the statistics data.
          </Typography.Text>
        ) : (
          <>
            {showCombinedChart ? (
              <Line {...getConfig("combined")} />
            ) : (
              <>
                <div style={{ marginTop: 8 }}>
                  <Typography.Title level={4} style={{ marginTop: 0 }}>
                    Impressions
                  </Typography.Title>
                  <Line {...getConfig("impressions")} />
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography.Title level={4} style={{ marginTop: 0 }}>
                    Clicks
                  </Typography.Title>
                  <Line {...getConfig("clicks")} />
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography.Title level={4} style={{ marginTop: 0 }}>
                    CTR
                  </Typography.Title>
                  <Line {...getConfig("ctr")} />
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography.Title level={4} style={{ marginTop: 0 }}>
                    Position
                  </Typography.Title>
                  <Line {...getConfig("position")} />
                </div>
              </>
            )}
          </>
        )}
      </Card>
      <CreateExperimentModal
        isOpen={isCreateExperimentModalVisible}
        onClose={() => setIsCreateExperimentModalVisible(false)}
        changeLogs={changeLogs}
        selectedChangeLog={selectedChangeLog}
      />
    </div>
  );
};

export default PageDetails;

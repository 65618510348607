import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getChangeLogs } from "../api/Changelog";

export const useChangeLogs = (
  pagination,
  filters,
  showInitial,
  sortOrder,
  sortField
) => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [changeLogs, setChangeLogs] = useState([]);
  const [totalChangeLogs, setTotalChangeLogs] = useState(0);
  const [loadingChangeLogs, setLoadingChangeLogs] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const fetchChangelogs = useCallback(async () => {
    if (!selectedWebsiteId) return;

    setLoadingChangeLogs(true);

    const params = {
      page: pagination.current,
      page_size: pagination.pageSize,
      search: filters.searchTerm,
      changetype: filters.selectedChangeType,
      show_initial: showInitial ? "true" : "false",
      ordering: sortOrder
        ? `${sortOrder === "asc" ? "" : "-"}${sortField}`
        : undefined,
    };

    try {
      const data = await getChangeLogs(selectedWebsiteId, params);
      setChangeLogs(data.results);
      setTotalChangeLogs(data.count);
      setFilteredData(data.results);
    } catch (error) {
      console.error("Failed to fetch changelogs:", error);
    } finally {
      setLoadingChangeLogs(false);
    }
  }, [
    selectedWebsiteId,
    pagination.current,
    pagination.pageSize,
    filters.searchTerm,
    filters.selectedChangeType,
    showInitial,
    sortOrder,
    sortField,
  ]);

  useEffect(() => {
    fetchChangelogs();
  }, [fetchChangelogs]);

  return {
    changeLogs,
    loadingChangeLogs,
    totalChangeLogs,
    filteredData,
    setFilteredData,
  };
};

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getExperiments } from "../api/Experiments";

export const useExperiments = (pagination, filters) => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [experiments, setExperiments] = useState([]);
  const [totalExperiments, setTotalExperiments] = useState(0);
  const [loadingExperiments, setLoadingExperiments] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const fetchExperiments = useCallback(async () => {
    if (!selectedWebsiteId) return;

    setLoadingExperiments(true);

    const params = {
      page: pagination.current,
      page_size: pagination.pageSize,
      search: filters.searchTerm,
      ordering: filters.sortOrder
        ? `${filters.sortOrder === "asc" ? "" : "-"}${filters.sortField}`
        : undefined,
    };

    switch (filters.filterType) {
      case "running":
        params.running = true;
        break;
      case "successful":
        params.successful = true;
        break;
      case "unsuccessful":
        params.unsuccessful = true;
        break;
      case "unreviewed":
        params.unreviewed = true;
        break;
      default:
        break;
    }

    try {
      const data = await getExperiments(selectedWebsiteId, params);
      setExperiments(data.results);
      setTotalExperiments(data.count);
      setFilteredData(data.results);
    } catch (error) {
      console.error("Failed to fetch experiments:", error);
    } finally {
      setLoadingExperiments(false);
    }
  }, [
    selectedWebsiteId,
    pagination.current,
    pagination.pageSize,
    filters.searchTerm,
    filters.sortOrder,
    filters.sortField,
    filters.filterType,
  ]);

  useEffect(() => {
    fetchExperiments();
  }, [fetchExperiments]);

  return {
    experiments,
    loadingExperiments,
    totalExperiments,
    filteredData,
    setFilteredData,
    fetchExperiments,
  };
};

import { instance, handleError } from "./apiUtils";

export const getPages = async (websiteId, params) => {
  try {
    const response = await instance.get(`pages/`, {
      params: {
        website_id: websiteId,
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPagesStatistics = async (
  websiteId,
  pageIds = [],
  filters = {},
  data_type = "total",
  aggregation = "day",
  fill_missing_dates = "false"
) => {
  const url = `pages/statistics/?website_id=${websiteId}&data_type=${data_type}&aggregation=${aggregation}&fill_missing_dates=${fill_missing_dates}`;
  const params = new URLSearchParams();

  if (pageIds.length > 0) {
    pageIds.forEach((pageId) => params.append("page_ids[]", pageId));
  }
  params.append("filters", JSON.stringify(filters));

  try {
    const response = await instance.get(`${url}&${params.toString()}`);
    console.log("Response Pages Statistics: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPageDetail = async (pageId, websiteId) => {
  try {
    const response = await instance.get(`pages/${pageId}/`, {
      params: { website_id: websiteId },
    });
    console.log("Response Page Detail: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPageChangeLogs = async (pageId, websiteId, params = {}) => {
  try {
    const response = await instance.get(`pages/${pageId}/changelogs/`, {
      params: {
        website_id: websiteId,
        ...params,
      },
    });
    console.log("Response Page Changelogs: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPageRankedKeywords = async (pageId, params) => {
  try {
    if (params.filters) {
      params.filters = JSON.stringify(params.filters);
    }
    const response = await instance.get(`pages/${pageId}/ranked-keywords/`, {
      params: { ...params },
    });
    console.log("Ranked keywords: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getWebsites } from "../api/Website";

export const fetchWebsites = createAsyncThunk(
  "websites/fetchWebsites",
  async () => {
    const response = await getWebsites();
    console.log("response: ", response);
    return response;
  }
);

const websitesSlice = createSlice({
  name: "websites",
  initialState: {
    websites: [],
    selectedWebsiteId: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    setSelectedWebsiteId: (state, action) => {
      state.selectedWebsiteId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsites.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWebsites.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.websites = action.payload;

        if (action.payload.length > 0) {
          state.selectedWebsiteId = action.payload[0].id;
        }
      })
      .addCase(fetchWebsites.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setSelectedWebsiteId } = websitesSlice.actions;
export default websitesSlice.reducer;

import React, { useState } from "react";
import { Modal, Button, Typography, Row, Col } from "antd";
import {
  ExperimentOutlined,
  GroupOutlined,
  SplitCellsOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import CreateExperimentModal from "./CreateExperimentModal";

const { Title, Text } = Typography;

function NewExperimentModal({ isOpen, onClose }) {
  const [selectedExperiment, setSelectedExperiment] = useState(null);

  const experimentTypes = [
    {
      key: "single",
      icon: (
        <ExperimentOutlined style={{ fontSize: "24px", color: "#ffffff" }} />
      ),
      title: "Single Experiment",
      description:
        "Test a single URL where both the control and test groups are the same page.",
      color: "#ff4d4f",
    },
    {
      key: "group",
      icon: <GroupOutlined style={{ fontSize: "24px", color: "#ffffff" }} />,
      title: "Group Experiment",
      description:
        "Test the performance of multiple URLs where the control and test groups are the same for each group.",
      color: "#1890ff",
    },
    {
      key: "split",
      icon: (
        <SplitCellsOutlined style={{ fontSize: "24px", color: "#ffffff" }} />
      ),
      title: "Split Experiment",
      description:
        "Compare two groups of URLs to see how one group performs against the other.",
      color: "#52c41a",
    },
    {
      key: "switch",
      icon: <SwapOutlined style={{ fontSize: "24px", color: "#ffffff" }} />,
      title: "URL Switch Experiment",
      description:
        "Evaluate the performance of a new URL by comparing it against the old URLs.",
      color: "#faad14",
    },
  ];

  const handleExperimentClick = (experiment) => {
    setSelectedExperiment(experiment);
  };

  const handleSecondModalClose = () => {
    setSelectedExperiment(null);
  };

  return (
    <>
      <Modal
        title="New Experiment"
        open={isOpen}
        onCancel={onClose}
        footer={[
          <Button key="close" type="default" onClick={onClose}>
            Cancel
          </Button>,
        ]}
        width={1000}
      >
        <Row gutter={[16, 16]}>
          {experimentTypes.map((experiment) => (
            <Col span={12} key={experiment.key}>
              <Button
                type="default"
                block
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  padding: "20px",
                  height: "auto",
                  backgroundColor: experiment.color,
                  borderColor: experiment.color,
                  color: "#ffffff",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
                onClick={() => handleExperimentClick(experiment)}
              >
                {experiment.icon}
                <div style={{ marginLeft: "20px", textAlign: "left" }}>
                  <Title level={4} style={{ margin: 0, color: "#ffffff" }}>
                    {experiment.title}
                  </Title>
                  <Text style={{ color: "#ffffff" }}>
                    {experiment.description}
                  </Text>
                </div>
              </Button>
            </Col>
          ))}
        </Row>
      </Modal>

      {selectedExperiment && (
        <CreateExperimentModal
          isOpen={!!selectedExperiment}
          onClose={handleSecondModalClose}
          experimentType={selectedExperiment}
        />
      )}
    </>
  );
}

export default NewExperimentModal;

import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { getChangeTypes } from "../api/Changelog";

export const useChangeTypes = () => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [changeTypes, setChangeTypes] = useState([]);
  const [loadingChangeTypes, setLoadingChangeTypes] = useState(false);

  useEffect(() => {
    const fetchChangeTypes = async () => {
      setLoadingChangeTypes(true);
      try {
        const types = await getChangeTypes(selectedWebsiteId);
        setChangeTypes(types.map((type) => ({ text: type, value: type })));
      } catch (error) {
        console.error("Failed to fetch change types:", error);
      }
      setLoadingChangeTypes(false);
    };

    if (selectedWebsiteId) {
      fetchChangeTypes();
    }
  }, [selectedWebsiteId]);

  return {
    changeTypes: changeTypes,
    loadingTypes: loadingChangeTypes,
  };
};

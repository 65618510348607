import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useResponsive } from "../hooks/useResponsive";
import { useChangeTypes } from "../hooks/useChangeTypes";
import { useChangeLogs } from "../hooks/useChangeLogs";
import { getChangeLogsSummary } from "../api/Changelog";
import moment from "moment";
import {
  Button,
  Input,
  Switch,
  Table,
  Typography,
  Row,
  Col,
  Card,
  Statistic,
} from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import ChangeLogColumns from "./Changelog/ChangeLogColumns";
import ChangeLogDetails from "./Changelog/ChangeLogDetails";
import CreateExperimentModal from "./Changelog/CreateExperimentModal";
import ManageChangeTypesModal from "./Changelog/ManageChangeTypesModal";

const { Search } = Input;

const ChangeLog = () => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [showInitial, setShowInitial] = useState(false);
  const [selectedChangeType, setSelectedChangeType] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreateExperimentModalVisible, setIsCreateExperimentModalVisible] =
    useState(false);
  const [isManageChangeTypesModalVisible, setIsManageChangeTypesModalVisible] =
    useState(false);
  const [selectedChangeLog, setSelectedChangeLog] = useState(null);
  const { mdDown, xxlDown, xxlUp } = useResponsive();
  const [summary, setSummary] = useState({
    last_checked_date: null,
    last_change_date: null,
    new_pages_count: 0,
    modified_pages_count: 0,
    deleted_pages_count: 0,
  });
  const { changeTypes, loadingChangeTypes } = useChangeTypes();
  const {
    changeLogs,
    loadingChangeLogs,
    totalChangeLogs,
    filteredData,
    setFilteredData,
  } = useChangeLogs(
    pagination,
    { selectedChangeType, searchTerm },
    showInitial,
    sortOrder,
    sortField
  );
  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const data = await getChangeLogsSummary(selectedWebsiteId);
        setSummary(data);
      } catch (error) {
        console.error("Failed to fetch summary:", error);
      }
    };

    fetchSummary();
  }, [selectedWebsiteId]);
  useEffect(() => {
    const filtered =
      changeLogs &&
      changeLogs.filter((log) => {
        const changeType = log.changetype.name.toLowerCase();
        const pageUrl = log.page ? log.page.url.toLowerCase() : "";
        const oldVal = log.old_value || "";
        const newVal = log.new_value || "";
        return (
          (changeType.includes(searchTerm.toLowerCase()) ||
            pageUrl.includes(searchTerm.toLowerCase()) ||
            oldVal.toLowerCase().includes(searchTerm.toLowerCase()) ||
            newVal.toLowerCase().includes(searchTerm.toLowerCase())) &&
          (showInitial ? true : !log.is_initial)
        );
      });
    setFilteredData(filtered);
  }, [searchTerm, changeLogs, showInitial]);

  const handleFilterChange = useCallback((pagination, filters, sorter) => {
    setSelectedChangeType(filters.type || []);
    const newSortField = Array.isArray(sorter.field)
      ? sorter.field.join("__")
      : sorter.field;
    const newSortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : null;
    setSortField(newSortField);
    setSortOrder(newSortOrder);
  }, []);

  return (
    <div>
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        Changelog
      </Typography.Title>
      <Row
        gutter={16}
        style={{
          marginBottom: 16,
        }}
      >
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Last checked"
              value={
                summary.last_checked_date
                  ? moment(summary.last_checked_date).format("DD/MM/YYYY HH:mm")
                  : "N/A"
              }
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Last change"
              value={
                summary.last_change_date
                  ? moment(summary.last_change_date).format("DD/MM/YYYY HH:mm")
                  : "N/A"
              }
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic title="New pages" value={summary.new_pages_count} />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic
              title="Modified pages"
              value={summary.modified_pages_count}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic
              title="Deleted pages"
              value={summary.deleted_pages_count}
            />
          </Card>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Search
          placeholder="Search logs"
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ flexGrow: 1, marginRight: 8 }}
        />
        <Switch
          checked={showInitial}
          onChange={() => setShowInitial(!showInitial)}
          checkedChildren="Show Initial"
          unCheckedChildren="Hide Initial"
          style={{ width: "150px", marginRight: 8 }}
        />
        <Button
          type="primary"
          onClick={() => {
            setIsManageChangeTypesModalVisible(true);
          }}
        >
          Manage Change Types
        </Button>
      </div>
      <Table
        columns={ChangeLogColumns({
          changeTypeFilters: changeTypes,
          setSelectedChangeLog,
          setIsCreateExperimentModalVisible,
          showInitial,
          mdDown,
          xxlUp,
        })}
        dataSource={filteredData}
        rowKey="id"
        loading={loadingChangeLogs}
        bordered
        onChange={handleFilterChange}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: totalChangeLogs,
          onChange: (page, pageSize) =>
            setPagination({ current: page, pageSize }),
        }}
        expandedRowRender={
          !xxlUp
            ? (record) => (
                <ChangeLogDetails
                  record={record}
                  xxlDown={xxlDown}
                  mdDown={mdDown}
                  setSelectedChangeLog={setSelectedChangeLog}
                />
              )
            : null
        }
      />
      <ManageChangeTypesModal
        isOpen={isManageChangeTypesModalVisible}
        onClose={() => setIsManageChangeTypesModalVisible(false)}
      />
      <CreateExperimentModal
        isOpen={isCreateExperimentModalVisible}
        onClose={() => setIsCreateExperimentModalVisible(false)}
        changeLogs={changeLogs}
        selectedChangeLog={selectedChangeLog}
      />
    </div>
  );
};

export default ChangeLog;

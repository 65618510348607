import React from "react";

function Financial() {
  return (
    <div>
      <h3>Financial</h3>
      {/* Add the financial-related content here */}
    </div>
  );
}

export default Financial;

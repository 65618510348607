import axios from "axios";
import { message } from "antd";
console.log("API BASE: ", process.env.REACT_APP_API_BASE_URL);
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
});

export const getAuthHeaders = () => {
  const accessToken =
    localStorage.getItem("accessToken") ||
    sessionStorage.getItem("accessToken");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export const handleError = (error) => {
  console.log("HandleError: ", error);
  if (error.response) {
    console.error("Server responded with an error:", error.response.data);
    const errorData = error.response.data;

    // Display each error message
    if (Array.isArray(errorData)) {
      errorData.forEach((err, index) => {
        message.error({ content: err, key: index, duration: 5 });
      });
    } else if (typeof errorData === "object") {
      // Handle cases where errorData is an object
      for (const [key, value] of Object.entries(errorData)) {
        if (Array.isArray(value)) {
          value.forEach((msg, idx) => {
            message.error({
              content: `${msg}`,
              key: `${key}-${idx}`,
              duration: 5,
            });
          });
        } else {
          message.error({ content: `${value}`, key: key, duration: 5 });
        }
      }
    } else {
      message.error({ content: errorData, duration: 5 });
    }

    throw error;
  } else {
    console.error("Network error occurred:", error.message);
    message.error({ content: "Network error", duration: 5 });
    throw new Error("Network error");
  }
};

instance.interceptors.request.use(
  async (config) => {
    let tokenExpiresAt =
      localStorage.getItem("tokenExpiresAt") ||
      sessionStorage.getItem("tokenExpiresAt");
    let refreshToken =
      localStorage.getItem("refreshToken") ||
      sessionStorage.getItem("refreshToken");

    console.log(
      "Token expires at, local: ",
      localStorage.getItem("tokenExpiresAt"),
      " Session: ",
      sessionStorage.getItem("tokenExpiresAt")
    );

    if (new Date().getTime() > parseInt(tokenExpiresAt, 10) && refreshToken) {
      try {
        console.log("Token has expired, attempting to refresh token...");
        const response = await axios.post(`${API_BASE_URL}token/refresh/`, {
          refresh: refreshToken,
        });
        const storage = localStorage.getItem("refreshToken")
          ? localStorage
          : sessionStorage;

        storage.setItem("accessToken", response.data.access);
        const newExpiresAt =
          new Date().getTime() + response.data.expiresIn * 1000;
        storage.setItem("tokenExpiresAt", newExpiresAt);
        config.headers["Authorization"] = `Bearer ${response.data.access}`;
        console.log("Token refreshed successfully");
      } catch (error) {
        console.error("Error refreshing token:", error);
        localStorage.clear();
        sessionStorage.clear();
        return Promise.reject(error);
      }
    } else {
      config.headers = getAuthHeaders();
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config.url.includes("token/")
    ) {
      console.error("Unauthorized error, redirecting to login...");
      localStorage.clear();
      sessionStorage.clear();
      //window.location.href = "/login";
      console.log("Logged out! Error: ", error);
    }
    return Promise.reject(error);
  }
);

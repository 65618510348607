import React from "react";
import { Link } from "react-router-dom";
import { ExportOutlined, LinkOutlined } from "@ant-design/icons";
import { Space, Tooltip } from "antd";

const PageColumns = () => [
  {
    title: "Page",
    dataIndex: "url",
    key: "url",
    sorter: true,
    defaultSortOrder: "ascend",
    render: (text, record) => (
      <Space>
        <Link to={`/pages/${record.id}`}>{text}</Link>
        <Tooltip title="Go to page">
          <a href={text} target="_blank" rel="noopener noreferrer">
            <LinkOutlined />
          </a>
        </Tooltip>
      </Space>
    ),
  },
  {
    title: "Impressions",
    dataIndex: "total_impressions",
    key: "total_impressions",
    sorter: true,
    render: (value) => (value !== null ? value.toLocaleString() : "0"), // Using toLocaleString for better number formatting
  },
  {
    title: "Clicks",
    dataIndex: "total_clicks",
    key: "total_clicks",
    sorter: true,
    render: (value) => (value !== null ? value.toLocaleString() : "0"),
  },
  {
    title: "CTR",
    dataIndex: "average_ctr",
    key: "average_ctr",
    sorter: true,
    render: (value) => (value !== null ? `${value.toFixed(2)}%` : "0%"),
  },
];

export default PageColumns;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile, logout } from "../store/userSlice";
import { fetchWebsites, setSelectedWebsiteId } from "../store/websitesSlice";
import { debounce } from "lodash";
import { useResponsive } from "../hooks/useResponsive";
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  Select,
  Typography,
  theme,
} from "antd";
import {
  BarChartOutlined,
  DashboardOutlined,
  DownOutlined,
  ExperimentOutlined,
  FileTextOutlined,
  GlobalOutlined,
  HistoryOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.profile);
  const { websites, selectedWebsiteId, status } = useSelector(
    (state) => state.websites
  );
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const { mdUp, mdDown, lgDown, lgUp } = useResponsive();

  const navigate = useNavigate();

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchWebsites());
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (!userProfile) {
      dispatch(fetchUserProfile());
    }
  }, [dispatch, userProfile]);

  const handleSelectWebsite = (id) => {
    dispatch(setSelectedWebsiteId(id));
    console.log("Selected website: ", id);
  };

  useEffect(() => {
    const updateLayout = debounce(() => {
      if (lgDown && !mdDown) {
        setCollapsed(true);
        setCollapsedWidth(80);
      } else if (mdDown) {
        setCollapsed(true);
        setCollapsedWidth(0);
      } else {
        setCollapsed(false);
        setCollapsedWidth(80);
      }
    }, 300);

    updateLayout();

    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, [mdDown, lgDown, lgUp]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const toggleMenu = () => {
    if (mdDown) {
      setCollapsedWidth(collapsedWidth === 0 ? 80 : 0);
    } else {
      setCollapsed(!collapsed);
    }
  };

  const menuIcon = mdDown ? (
    collapsedWidth === 0 ? (
      <MenuUnfoldOutlined />
    ) : (
      <MenuFoldOutlined />
    )
  ) : collapsed ? (
    <MenuUnfoldOutlined />
  ) : (
    <MenuFoldOutlined />
  );

  const userMenu = (
    <Menu>
      <Menu.Item
        key="profile"
        icon={<UserOutlined />}
        onClick={() => navigate("/user/profile")}
      >
        Profile
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const avatarDropdown = (
    <Dropdown overlay={userMenu} trigger={["click"]}>
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Avatar style={{ backgroundColor: "#0652DD" }} size="large">
          {userProfile
            ? `${(userProfile.first_name?.charAt(0) || "").toUpperCase()}${(
                userProfile.last_name?.charAt(0) || ""
              ).toUpperCase()}`
            : "U"}
        </Avatar>
        {mdUp && (
          <span style={{ marginLeft: 8 }}>
            {userProfile
              ? `${userProfile.first_name || ""} ${
                  userProfile.last_name || ""
                }`.trim()
              : "User"}
            <DownOutlined style={{ marginLeft: 8 }} />
          </span>
        )}
      </div>
    </Dropdown>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        theme="dark"
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={collapsedWidth}
      >
        <img
          className="logo"
          src={collapsed ? "/images/icon-white.png" : "/images/logo-white.png"}
          style={{
            maxHeight: "48px",
            maxWidth: "100%",
            marginTop: "8px",
            marginBottom: "8px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
          onClick={() => navigate("/")}
          alt="Logo"
        />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          style={{ maxHeight: "100%", borderRight: 0 }}
        >
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<GlobalOutlined />}>
            <Link to="/websites">Websites</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<ExperimentOutlined />}>
            <Link to="/experiments">Experiments</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<FileTextOutlined />}>
            <Link to="/pages">Pages</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<TagOutlined />}>
            <Link to="/keywords">Keywords</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<HistoryOutlined />}>
            <Link to="/changelog">Changelog</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<BarChartOutlined />}>
            <Link to="/reports">Reports</Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<SettingOutlined />}>
            <Link to="/settings">Settings</Link>
          </Menu.Item>
          {/* Add more links as needed */}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 24px 0 0",
            background: colorBgContainer,
          }}
        >
          <div>
            <Button
              type="text"
              icon={menuIcon}
              onClick={toggleMenu}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </div>

          <div
            style={{
              flexGrow: 1,
              marginRight: 24,
              marginLeft: 24,
            }}
          >
            <Select
              value={selectedWebsiteId}
              onChange={handleSelectWebsite}
              style={{ width: "100%" }}
            >
              {websites.map((website) => (
                <Option key={website.id} value={website.id}>
                  <img
                    src={
                      website.favicon
                        ? `data:image/png;base64,${website.favicon}`
                        : "/images/placeholder-website.svg"
                    }
                    alt={`${website.name} favicon`}
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "8px",
                      marginBottom: "3px",
                      verticalAlign: "middle",
                    }}
                  />
                  {website.name}
                </Option>
              ))}
            </Select>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            {avatarDropdown}
          </div>
        </Header>

        <Layout style={{ padding: "24px" }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              backgroundColor: "white",
            }}
          >
            {children}
          </Content>
        </Layout>
        <Footer style={{ textAlign: "center" }}>
          Seo Ripple ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;

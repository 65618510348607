import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getWebsiteStatistics } from "../api/Website";
import { useResponsive } from "../hooks/useResponsive";
import { Button, Card, Input, Space, Statistic, Typography } from "antd";
import {
  SettingOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AddWebsiteModal from "./Websites/AddWebsiteModal";
import EditWebsiteModal from "./Websites/EditWebsiteModal";
import DeleteWebsiteModal from "./Websites/DeleteWebsiteModal";

const { Search } = Input;

function Websites() {
  const userProfile = useSelector((state) => state.user.profile);
  const { websites } = useSelector((state) => state.websites);
  const [websiteStats, setWebsiteStats] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const { mdUp, lgUp, xlUp } = useResponsive();

  useEffect(() => {
    if (websites.length) {
      console.log("Websites: ", websites);
      const fetchStatistics = async () => {
        const stats = {};
        const filters = { last_days: 30 };

        for (const website of websites) {
          const statistics = await getWebsiteStatistics(
            website.id,
            filters,
            "total"
          );
          stats[website.id] = statistics;
        }
        setWebsiteStats(stats);
      };

      fetchStatistics();
    }
  }, [websites]);

  const formatUrl = (url) => {
    return url.replace(/^(https?:\/\/)?(www\.)?/, "");
  };

  const filteredWebsites = websites.filter((website) => {
    return (
      website.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      website.url.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const groupedWebsites = filteredWebsites.reduce((acc, website) => {
    const teamName = website.team.name || "No Team";
    if (!acc[teamName]) {
      acc[teamName] = [];
    }
    acc[teamName].push(website);
    return acc;
  }, {});

  const gridStyle = {
    flex: "1 1 0",
    minWidth: "20%",
    width: "100%",
    maxWidth: "100%",
    boxShadow: "0",
  };

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: mdUp ? "row" : "column",
            justifyContent: "space-between",
            alignItems: mdUp ? "center" : "flex-start",
          }}
        >
          <Typography.Title level={2} style={{ marginTop: 0 }}>
            Websites
          </Typography.Title>
          <div
            style={{
              display: "flex",
              width: mdUp ? "auto" : "100%",
              justifyContent: mdUp ? "flex-end" : "space-between",
              alignItems: "center",
              marginTop: mdUp ? "0" : "20px",
            }}
          >
            <Search
              placeholder="Search by name or URL"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: mdUp ? "300px" : "auto",
                flexGrow: mdUp ? "0" : "1",
                marginRight: "8px",
              }}
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsAddModalVisible(true)}
              style={{ marginLeft: mdUp ? "8px" : "0" }}
            >
              Add New Website
            </Button>
          </div>
        </div>
        <Space
          direction="vertical"
          size="middle"
          style={{ width: "100%", marginTop: 8 }}
        >
          {Object.keys(groupedWebsites).length > 0 ? (
            Object.keys(groupedWebsites).map((teamName) => (
              <div key={teamName}>
                <Typography.Title level={4} style={{ marginTop: 16 }}>
                  {teamName}
                </Typography.Title>
                {groupedWebsites[teamName].map((website) => (
                  <Card
                    key={website.id}
                    style={{ borderRadius: "8px", marginBottom: 8 }}
                  >
                    <Card.Grid
                      hoverable={false}
                      style={{
                        ...gridStyle,
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px 0 0 8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={
                            website.favicon
                              ? `data:image/png;base64,${website.favicon}`
                              : "/images/placeholder-website.svg"
                          }
                          style={{ width: "48px", height: "48px" }}
                          alt={`${website.name} favicon`}
                        />
                        <p>
                          <span style={{ fontWeight: 500 }}>
                            {website.name}
                          </span>
                          <br />
                          <span>{formatUrl(website.url)}</span>
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          type="primary"
                          icon={<SettingOutlined />}
                          onClick={() => {
                            setSelectedWebsite(website);
                            setIsEditModalVisible(true);
                          }}
                          style={{ width: "49%" }}
                        >
                          {lgUp ? <span>Edit</span> : null}
                        </Button>
                        <Button
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            setSelectedWebsite(website);
                            setIsDeleteModalVisible(true);
                          }}
                          style={{ width: "49%" }}
                        >
                          {lgUp ? <span>Delete</span> : null}
                        </Button>
                      </div>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={gridStyle}>
                      <p>
                        <span style={{ fontWeight: 500 }}>Last Crawl:</span>
                        <br />
                        <span>
                          {website.last_scrape_date
                            ? new Date(website.last_scrape_date)
                                .toLocaleString("en-US", {
                                  dateStyle: "short",
                                  timeStyle: "short",
                                })
                                .replace(",", "")
                            : "N/A"}
                        </span>
                      </p>
                    </Card.Grid>
                    {mdUp ? (
                      <Card.Grid hoverable={false} style={gridStyle}>
                        <Statistic
                          title="Impressions"
                          value={websiteStats[website.id]?.impressions || 0}
                        />
                      </Card.Grid>
                    ) : (
                      []
                    )}
                    <Card.Grid hoverable={false} style={gridStyle}>
                      <Statistic
                        title="Clicks"
                        value={websiteStats[website.id]?.clicks || 0}
                      />
                    </Card.Grid>
                    {xlUp ? (
                      <Card.Grid hoverable={false} style={gridStyle}>
                        <Statistic
                          title="CTR"
                          value={`${
                            websiteStats[website.id]?.ctr?.toFixed(2) || 0
                          }%`}
                        />
                      </Card.Grid>
                    ) : (
                      []
                    )}
                  </Card>
                ))}
              </div>
            ))
          ) : (
            <p>No websites configured yet.</p>
          )}
        </Space>
      </div>
      <AddWebsiteModal
        isOpen={isAddModalVisible}
        onClose={() => setIsAddModalVisible(false)}
      />
      <EditWebsiteModal
        isOpen={isEditModalVisible}
        onClose={() => {
          setIsEditModalVisible(false);
          setSelectedWebsite(null);
        }}
        selectedWebsite={selectedWebsite}
      />
      <DeleteWebsiteModal
        isOpen={isDeleteModalVisible}
        onClose={() => {
          setIsDeleteModalVisible(false);
          setSelectedWebsite(null);
        }}
        selectedWebsite={selectedWebsite}
      />
    </div>
  );
}

export default Websites;

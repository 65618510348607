import { instance, handleError } from "./apiUtils";

export const getExperiments = async (websiteId, params) => {
  try {
    const response = await instance.get(`experiments/`, {
      params: {
        website_id: websiteId,
        ...params,
      },
    });
    console.log("experiment list: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getExperimentsSummary = async (websiteId) => {
  try {
    const response = await instance.get("experiments/summary/", {
      params: {
        website_id: websiteId,
      },
    });
    console.log("Experiment statistics: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const createExperiment = async (experimentData) => {
  try {
    const response = await instance.post("experiments/", experimentData);
    return response.data;
  } catch (error) {
    console.log("error: ", error);
    handleError(error);
  }
};

export const getExperimentById = async (experimentId) => {
  try {
    const response = await instance.get(`experiments/${experimentId}/`);
    console.log("Experiment: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const getExperimentTargetKeywordsById = async (experimentId, params) => {
  try {
    const response = await instance.get(
      `experiments/${experimentId}/target-keywords/`,
      { params: { ...params } }
    );
    console.log("Target keywords: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const getExperimentRankedKeywordsById = async (experimentId, params) => {
  try {
    const response = await instance.get(
      `experiments/${experimentId}/ranked-keywords/`,
      { params: { ...params } }
    );
    console.log("Ranked keywords: ", response);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const updateExperimentById = async (experimentId, experimentData) => {
  try {
    const response = await instance.put(
      `experiments/${experimentId}/`,
      experimentData
    );
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const deleteExperimentById = async (experimentId) => {
  try {
    const response = await instance.delete(`experiments/${experimentId}/`);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const updateExperimentSuccessById = async (
  experimentId,
  successStatus
) => {
  try {
    const response = await instance.post(
      `experiments/${experimentId}/success/`,
      { successful: successStatus }
    );
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Form,
  Input,
  Badge,
  Button,
  Tabs,
  message,
  Checkbox,
  Select,
  Divider,
} from "antd";
import { fetchWebsites } from "../../store/websitesSlice";
import { updateWebsiteById } from "../../api/Website";
import debounce from "lodash/debounce";

const { TabPane } = Tabs;
const { Option } = Select;

function EditWebsiteModal({ isOpen, onClose, selectedWebsite }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [generalValidationErrors, setGeneralValidationErrors] = useState(0);
  const [keywordValidationErrors, setKeywordValidationErrors] = useState(0);
  const [scrapingEnabled, setScrapingEnabled] = useState(false);
  const [faviconUrl, setFaviconUrl] = useState(null);
  const [activeTab, setActiveTab] = useState("1"); // State to manage active tab

  const defaultInformationalKeywords = [
    "what",
    "who",
    "when",
    "where",
    "which",
    "why",
    "how",
  ];
  const defaultTransactionalKeywords = [
    "buy",
    "order",
    "purchase",
    "price",
    "cheap",
  ];
  const defaultCommercialKeywords = [
    "top",
    "best",
    "review",
    "comparison",
    "compare",
    "versus",
    "vs",
  ];

  const [brandKeywordCount, setBrandKeywordCount] = useState(0);
  const [informationalKeywordCount, setInformationalKeywordCount] = useState(7);
  const [transactionalKeywordCount, setTransactionalKeywordCount] = useState(5);
  const [commercialKeywordCount, setCommercialKeywordCount] = useState(7);

  useEffect(() => {
    if (selectedWebsite && isOpen) {
      setLoading(true);
      form.setFieldsValue({
        ...selectedWebsite,
        keywords_brand: selectedWebsite.keywords_brand
          ? selectedWebsite.keywords_brand.split(", ").join("\n")
          : "",
        keywords_intent_informational:
          selectedWebsite.keywords_intent_informational
            ? selectedWebsite.keywords_intent_informational
                .split(", ")
                .join("\n")
            : "",
        keywords_intent_transactional:
          selectedWebsite.keywords_intent_transactional
            ? selectedWebsite.keywords_intent_transactional
                .split(", ")
                .join("\n")
            : "",
        keywords_intent_commercial: selectedWebsite.keywords_intent_commercial
          ? selectedWebsite.keywords_intent_commercial.split(", ").join("\n")
          : "",
      });
      fetchFavicon(selectedWebsite.url);
      setScrapingEnabled(selectedWebsite.scraping_enabled);
      setBrandKeywordCount(
        selectedWebsite.keywords_brand
          ? selectedWebsite.keywords_brand.split(", ").length
          : 0
      );
      setInformationalKeywordCount(
        selectedWebsite.keywords_intent_informational
          ? selectedWebsite.keywords_intent_informational.split(", ").length
          : 0
      );
      setTransactionalKeywordCount(
        selectedWebsite.keywords_intent_transactional
          ? selectedWebsite.keywords_intent_transactional.split(", ").length
          : 0
      );
      setCommercialKeywordCount(
        selectedWebsite.keywords_intent_commercial
          ? selectedWebsite.keywords_intent_commercial.split(", ").length
          : 0
      );
      setLoading(false);
    } else {
      form.resetFields();
      setFaviconUrl(null);
    }
  }, [selectedWebsite, isOpen, form]);

  const validateUrl = (_, value) => {
    const pattern = /^(https?:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;
    if (!pattern.test(value)) {
      return Promise.reject(
        "Please enter a valid URL starting with http:// or https://"
      );
    }

    try {
      new URL(value);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject("Please enter a valid URL");
    }
  };

  const fetchFavicon = async (url) => {
    if (url) {
      const urlObject = new URL(url);
      const protocol = urlObject.protocol;
      const domain = urlObject.hostname;
      const fullDomain = `${protocol}//${domain}`;
      const faviconUrl = `https://www.google.com/s2/favicons?domain=${fullDomain}&size=128`;
      setFaviconUrl(faviconUrl);
    } else {
      setFaviconUrl(null);
    }
  };

  const debouncedFetchFavicon = useCallback(
    debounce((url) => fetchFavicon(url), 500),
    []
  );

  const updateValidationErrors = () => {
    const generalErrors = form
      .getFieldsError(["name", "url"])
      .filter((field) => field.errors.length > 0).length;

    const keywordErrors = form
      .getFieldsError([
        "keywords_brand",
        "keywords_intent_informational",
        "keywords_intent_transactional",
        "keywords_intent_commercial",
      ])
      .filter((field) => field.errors.length > 0).length;

    setGeneralValidationErrors(generalErrors);
    setKeywordValidationErrors(keywordErrors);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      await form.validateFields();
      updateValidationErrors();

      const formattedValues = {
        ...values,
        keywords_intent_informational:
          values.keywords_intent_informational.replace(/\n/g, ", "),
        keywords_intent_transactional:
          values.keywords_intent_transactional.replace(/\n/g, ", "),
        keywords_intent_commercial: values.keywords_intent_commercial.replace(
          /\n/g,
          ", "
        ),
      };

      await updateWebsiteById(selectedWebsite.id, formattedValues);
      message.success("Website updated successfully!");

      form.resetFields();
      setActiveTab("1"); // Reset active tab
      dispatch(fetchWebsites());
      onClose();
    } catch (error) {
      console.log("Error2: ", error.errors);
      if (error.errors) {
        const backendErrors = error.errors;

        Object.keys(backendErrors).forEach((key) => {
          backendErrors[key].forEach((error) => {
            message.error(`Error: ${error}`);
          });
        });
      } else if (error.errorFields) {
        updateValidationErrors();
      } else {
        message.error("Failed to update website. Please try again.");
      }
    }
    setLoading(false);
  };

  const handleUrlBlur = async (event) => {
    try {
      const url = event.target.value;
      await form.validateFields(["url"]);
      debouncedFetchFavicon(url);
    } catch (error) {
      setFaviconUrl(null);
    }
  };

  const handleFieldChange = (changedFields) => {
    if (generalValidationErrors + keywordValidationErrors > 0) {
      updateValidationErrors();
    }
  };

  const handleTextAreaChange = (field, value) => {
    const valueWithNewlines = value.split(",").join("\n");

    const nonEmptyRowCount = valueWithNewlines
      .split("\n")
      .filter((row) => row.trim() !== "").length;

    switch (field) {
      case "keywords_brand":
        setBrandKeywordCount(nonEmptyRowCount);
        break;
      case "keywords_intent_informational":
        setInformationalKeywordCount(nonEmptyRowCount);
        break;
      case "keywords_intent_transactional":
        setTransactionalKeywordCount(nonEmptyRowCount);
        break;
      case "keywords_intent_commercial":
        setCommercialKeywordCount(nonEmptyRowCount);
        break;
      default:
        break;
    }
  };

  const handleGrantAccess = (integration) => {
    // Logic to grant read-only access for Google Search Console or Google Analytics
    message.info(`Requesting read-only access to ${integration}.`);
  };

  const handleScrapingChange = (e) => {
    setScrapingEnabled(e.target.checked);
  };

  return (
    <Modal
      title="Edit Website"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => {
        form.resetFields();
        setActiveTab("1"); // Reset active tab
        onClose();
      }}
      okText="Update Website"
      cancelText="Cancel"
    >
      <Form
        form={form}
        onFieldsChange={handleFieldChange}
        onFinish={handleFormSubmit}
        onFinishFailed={updateValidationErrors}
        layout="vertical"
        initialValues={{
          keywords_brand: defaultInformationalKeywords.join("\n"),
          keywords_intent_informational:
            defaultInformationalKeywords.join("\n"),
          keywords_intent_transactional:
            defaultTransactionalKeywords.join("\n"),
          keywords_intent_commercial: defaultCommercialKeywords.join("\n"),
        }}
      >
        <Tabs
          tabPosition="left"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          defaultActiveKey="1"
        >
          <TabPane
            tab={
              <>
                General
                {generalValidationErrors > 0 && (
                  <Badge
                    count={generalValidationErrors}
                    style={{ marginLeft: 8 }}
                  />
                )}
              </>
            }
            key="1"
            forceRender={true}
          >
            <Form.Item
              label="Website Name"
              name="name"
              rules={[
                { required: true, message: "Please enter a website name" },
              ]}
            >
              <Input placeholder="Website Name" />
            </Form.Item>
            <Form.Item
              label="Website URL"
              name="url"
              rules={[{ required: true, validator: validateUrl }]}
            >
              <Input
                placeholder="https://www.domain.ext"
                prefix={
                  faviconUrl ? (
                    <img
                      src={faviconUrl}
                      alt="Favicon"
                      style={{ width: 16, height: 16 }}
                    />
                  ) : null
                }
                onBlur={handleUrlBlur}
              />
            </Form.Item>
          </TabPane>

          <TabPane
            tab={
              <>
                Keywords
                {keywordValidationErrors > 0 && (
                  <Badge
                    count={keywordValidationErrors}
                    style={{ marginLeft: 8 }}
                  />
                )}
              </>
            }
            key="2"
            forceRender={true}
          >
            <Form.Item
              label={
                <>
                  <span>Brand Keywords</span>
                  <Badge
                    count={brandKeywordCount}
                    overflowCount={5000}
                    style={{ marginLeft: "10px", backgroundColor: "#0652DD" }}
                  />
                </>
              }
              name="keywords_brand"
              rules={[
                {
                  required: true,
                  message: "Please enter brand keywords",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                onChange={(e) =>
                  handleTextAreaChange("keywords_brand", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span>Informational Intent Keywords</span>
                  <Badge
                    count={informationalKeywordCount}
                    overflowCount={5000}
                    style={{ marginLeft: "10px", backgroundColor: "#0652DD" }}
                  />
                </>
              }
              name="keywords_intent_informational"
              rules={[
                {
                  required: true,
                  message: "Please enter informational intent keywords",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                onChange={(e) =>
                  handleTextAreaChange(
                    "keywords_intent_informational",
                    e.target.value
                  )
                }
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span>Transactional Intent Keywords</span>
                  <Badge
                    count={transactionalKeywordCount}
                    overflowCount={5000}
                    style={{ marginLeft: "10px", backgroundColor: "#0652DD" }}
                  />
                </>
              }
              name="keywords_intent_transactional"
              rules={[
                {
                  required: true,
                  message: "Please enter transactional intent keywords",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                onChange={(e) =>
                  handleTextAreaChange(
                    "keywords_intent_transactional",
                    e.target.value
                  )
                }
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span>Commercial Intent Keywords</span>
                  <Badge
                    count={commercialKeywordCount}
                    overflowCount={5000}
                    style={{ marginLeft: "10px", backgroundColor: "#0652DD" }}
                  />
                </>
              }
              name="keywords_intent_commercial"
              rules={[
                {
                  required: true,
                  message: "Please enter commercial intent keywords",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                onChange={(e) =>
                  handleTextAreaChange(
                    "keywords_intent_commercial",
                    e.target.value
                  )
                }
              />
            </Form.Item>
          </TabPane>
          <TabPane key="3" tab="Integrations">
            <Form.Item label="Google Search Console">
              <Button
                type="primary"
                onClick={() => handleGrantAccess("Google Search Console")}
              >
                Grant Read-Only Access
              </Button>
            </Form.Item>

            <Form.Item label="Google Analytics">
              <Button
                type="primary"
                onClick={() => handleGrantAccess("Google Analytics")}
              >
                Grant Read-Only Access
              </Button>
            </Form.Item>
            <Form.Item name="import_all_pages" valuePropName="checked">
              <Checkbox>Import all pages with statistics</Checkbox>
            </Form.Item>
            <Form.Item name="import_all_keywords" valuePropName="checked">
              <Checkbox>Import all keywords with statistics</Checkbox>
            </Form.Item>
          </TabPane>
          <TabPane key="4" tab="Scraping">
            <Form.Item label="Sitemap URL" name="sitemap_url">
              <Input placeholder="https://www.domain.ext/sitemap.xml" />
            </Form.Item>
            <Form.Item name="scraping_enabled" valuePropName="checked">
              <Checkbox onChange={handleScrapingChange}>
                Enable scraping
              </Checkbox>
            </Form.Item>
            {scrapingEnabled && (
              <>
                <div style={{ paddingLeft: "2em" }}>
                  <Form.Item
                    label="Scraping mode"
                    name="scraping_mode"
                    rules={[
                      {
                        required: true,
                        message: "Please select a scraping mode",
                      },
                    ]}
                  >
                    <Select>
                      <Option value="sitemap">Sitemap</Option>
                      <Option value="crawler">Crawler</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="scraping_render_js" valuePropName="checked">
                    <Checkbox>Render JavaScript</Checkbox>
                  </Form.Item>
                </div>
                <Divider />
              </>
            )}
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}

export default EditWebsiteModal;

import React from "react";
import { Link } from "react-router-dom";
import { Button, Space, Tooltip } from "antd";
import {
  ExperimentOutlined,
  ExportOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useResponsive } from "../../hooks/useResponsive";

const changeLogColumns = ({
  changeTypeFilters,
  setSelectedChangeLog,
  setIsCreateExperimentModalVisible,
  showInitial,
  mdDown,
  xxlUp,
}) => [
  {
    title: "Date",
    dataIndex: "created_at",
    key: "date",
    render: (text) => moment(text).format("DD/MM/YYYY"),
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    defaultSortOrder: "descend",
  },
  {
    title: "Type",
    dataIndex: ["changetype", "name"], // Aanpassen op basis van de structuur van je changelog data
    key: "type",
    filters: changeTypeFilters,
    onFilter: (value, record) => record.changetype.name === value,
    sorter: (a, b) => a.changetype.name.localeCompare(b.changetype.name),
  },
  ...(!mdDown && !xxlUp
    ? [
        {
          title: "From To",
          render: (record) => (
            <React.Fragment>
              <span>
                <strong style={{ fontWeight: 500 }}>Old Value:</strong>{" "}
                {record.old_value}
              </span>
              <br />
              <span>
                <strong style={{ fontWeight: 500 }}>New Value:</strong>{" "}
                {record.new_value}
              </span>
            </React.Fragment>
          ),
        },
      ]
    : []),
  ...(xxlUp
    ? [
        {
          title: "Index",
          dataIndex: "index",
          key: "index",
          sorter: (a, b) => a.index - b.index,
        },
        {
          title: "Old Value",
          dataIndex: "old_value",
          key: "oldValue",
        },
        {
          title: "New Value",
          dataIndex: "new_value",
          key: "newValue",
        },
      ]
    : []),
  {
    title: "Page",
    dataIndex: ["page", "url"],
    key: "page",
    sorter: (a, b) => a.page.url.localeCompare(b.page.url),
    responsive: ["xxl"],
    render: (text, record) => (
      <Space>
        <Link to={`/pages/${record.page.id}`}>{text}</Link>
        <Tooltip title="Go to page">
          <a href={text} target="_blank" rel="noopener noreferrer">
            <LinkOutlined />
          </a>
        </Tooltip>
      </Space>
    ),
  },
  ...(showInitial
    ? [
        {
          title: "Initial",
          key: "isInitial",
          render: (record) =>
            record.is_initial ? (
              <div style={{ color: "green", fontSize: "24px" }}>●</div>
            ) : (
              <div style={{ color: "red", fontSize: "24px" }}>●</div>
            ),
        },
      ]
    : []),
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Button
        icon={<ExperimentOutlined />}
        onClick={() => {
          setSelectedChangeLog(record);
          setIsCreateExperimentModalVisible(true);
        }}
      >
        {xxlUp ? <span>Create Experiment</span> : null}
      </Button>
    ),
  },
];

export default changeLogColumns;

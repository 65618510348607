import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Card, Typography, message } from "antd";
import { deleteExperimentById } from "../../api/Experiments";

function DeleteExperimentModal({
  isOpen,
  onClose,
  selectedExperiment,
  fetchExperiments,
}) {
  const [loading, setLoading] = useState(false);

  const handleDeleteExperiment = async () => {
    setLoading(true);
    try {
      await deleteExperimentById(selectedExperiment.id);
      message.success("Experiment deleted successfully!");
      fetchExperiments();
      onClose();
    } catch (error) {
      message.error("Failed to delete experiment. Please try again.");
    }
    setLoading(false);
  };

  return (
    <Modal
      title="Delete Experiment"
      open={isOpen}
      onOk={handleDeleteExperiment}
      onCancel={onClose}
      okText="Delete Experiment"
      cancelText="Cancel"
      confirmLoading={loading}
      okButtonProps={{ danger: true }}
    >
      Are you sure you want to permanently delete this experiment?
      <Card title="Selected Experiment" size="small" style={{ marginTop: 8 }}>
        <div>
          <Typography.Text strong>Experiment Name: </Typography.Text>
          <span>{selectedExperiment?.experiment_name}</span>
        </div>
        <div>
          <Typography.Text strong>Control Period: </Typography.Text>
          <span>{selectedExperiment?.control_start_date}</span>
          <span> to </span>
          <span>{selectedExperiment?.control_end_date}</span>
        </div>
        <div>
          <Typography.Text strong>Test Period: </Typography.Text>
          <span>{selectedExperiment?.test_start_date}</span>
          <span> to </span>
          <span>{selectedExperiment?.test_end_date}</span>
        </div>
      </Card>
    </Modal>
  );
}

export default DeleteExperimentModal;

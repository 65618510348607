import React, { useState } from "react";
import { Modal, Form, Input, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function UploadKeywordModal({ isOpen, onClose }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      message.success("Keyword(s) added successfully!");
      form.resetFields();
      onClose();
    } catch (error) {
      message.error("Failed to add keyword(s). Please try again.");
    }
    setLoading(false);
  };

  const beforeUpload = (file) => {
    const isCsv = file.type === "text/csv";

    if (!isCsv) {
      message.error("You can only upload CSV files!");
      return false;
    }

    return true;
  };

  return (
    <Modal
      title="Upload New Keywords"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={onClose}
      okText="Add Keyword"
      cancelText="Cancel"
    >
      <Form form={form} onFinish={handleFormSubmit} layout="vertical">
        <Form.Item
          label="Upload CSV File"
          name="upload"
          valuePropName="fileList"
          rules={[
            { required: true, message: "Please upload a CSV file." }, // Require an upload
          ]}
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e?.fileList.slice(-1); // Limit to one file
          }}
        >
          <Upload
            name="file"
            beforeUpload={beforeUpload} // Ensure only CSV files
            listType="text"
            maxCount={1} // Allow only one file
          >
            <Button icon={<UploadOutlined />}>Select CSV File</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UploadKeywordModal;

import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import UserProfile from "../components/User/UserProfile";
import Websites from "../components/Websites";
import ChangeLog from "../components/ChangeLog";
import Pages from "../components/Pages";
import PageDetails from "../components/Pages/PageDetails";
import Keywords from "../components/Keywords";
import Experiments from "../components/Experiments";
import ExperimentDetails from "../components/Experiments/ExperimentDetails";
import Settings from "../components/Settings";
import MainLayout from "../components/MainLayout";
import PrivateRoute from "./PrivateRoute";

function SecureRoutes() {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <MainLayout>
              <Dashboard />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/user/profile"
        element={
          <PrivateRoute>
            <MainLayout>
              <UserProfile />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/websites"
        element={
          <PrivateRoute>
            <MainLayout>
              <Websites />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/changelog"
        element={
          <PrivateRoute>
            <MainLayout>
              <ChangeLog />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/pages"
        element={
          <PrivateRoute>
            <MainLayout>
              <Pages />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/pages/:pageId"
        element={
          <PrivateRoute>
            <MainLayout>
              <PageDetails />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/keywords"
        element={
          <PrivateRoute>
            <MainLayout>
              <Keywords />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/experiments"
        element={
          <PrivateRoute>
            <MainLayout>
              <Experiments />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/experiments/:experimentId"
        element={
          <PrivateRoute>
            <MainLayout>
              <ExperimentDetails />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <MainLayout>
              <Settings />
            </MainLayout>
          </PrivateRoute>
        }
      />
      {/* Voeg meer beveiligde routes toe zoals nodig */}
    </Routes>
  );
}

export default SecureRoutes;

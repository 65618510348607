import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { confirmPasswordReset } from "../api/Auth"; // Make sure this path is correct
import { Form, Input, Button, Alert } from "antd";

function PasswordResetConfirm() {
  const { userId, token } = useParams(); // Extract userId and token from URL
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Handle the form submission
  const handleSubmit = async () => {
    try {
      // Attempt to reset the password using the token
      await confirmPasswordReset(userId, token, password);
      navigate("/login"); // Redirect to login page on success
    } catch (error) {
      setError("The password reset link is invalid or has expired."); // Show error message
    }
  };

  return (
    <div style={{ maxWidth: "300px", margin: "0 auto" }}>
      <h2>Set New Password</h2>
      <Form onFinish={handleSubmit}>
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            { required: true, message: "Please enter your new password!" },
          ]}
        >
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Reset Password
          </Button>
        </Form.Item>
        {error && <Alert message={error} type="error" />}
      </Form>
    </div>
  );
}

export default PasswordResetConfirm;

import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Select,
  Card,
  Typography,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UsergroupAddOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  getTeams,
  createTeam,
  updateTeamById,
  deleteTeamById,
  getTeamMembers,
  addTeamMember,
  removeTeamMember,
} from "../../api/Teams";

const { Option } = Select;

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMemberModalVisible, setIsMemberModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [teamToDelete, setTeamToDelete] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const data = await getTeams();
      setTeams(data.results);
    } catch (error) {
      message.error("Failed to fetch teams");
    } finally {
      setLoading(false);
    }
  };

  const fetchTeamMembers = async (teamId) => {
    setLoading(true);
    try {
      const data = await getTeamMembers(teamId);
      setTeamMembers(data.results);
    } catch (error) {
      message.error("Failed to fetch team members");
    } finally {
      setLoading(false);
    }
  };

  const handleAddTeam = () => {
    setCurrentTeam(null);
    setIsModalVisible(true);
  };

  const handleEditTeam = (team) => {
    setCurrentTeam(team);
    setIsModalVisible(true);
  };

  const handleDeleteTeam = (team) => {
    setTeamToDelete(team);
    setIsDeleteModalVisible(true);
  };

  const confirmDeleteTeam = async () => {
    try {
      await deleteTeamById(teamToDelete.id);
      message.success("Team deleted successfully");
      fetchTeams();
      setIsDeleteModalVisible(false);
    } catch (error) {
      message.error("Failed to delete team");
    }
  };

  const handleModalOk = async (values) => {
    try {
      if (currentTeam) {
        await updateTeamById(currentTeam.id, values);
        message.success("Team updated successfully");
      } else {
        await createTeam(values);
        message.success("Team created successfully");
      }
      fetchTeams();
      setIsModalVisible(false);
    } catch (error) {
      message.error("Failed to save team");
    }
  };

  const handleManageMembers = (team) => {
    setCurrentTeam(team);
    fetchTeamMembers(team.id);
    setIsMemberModalVisible(true);
  };

  const handleAddMember = async (values) => {
    try {
      await addTeamMember(currentTeam.id, values);
      message.success("Member added successfully");
      fetchTeamMembers(currentTeam.id);
    } catch (error) {
      message.error("Failed to add member");
    }
  };

  const handleRemoveMember = async (memberId) => {
    try {
      await removeTeamMember(currentTeam.id, memberId);
      message.success("Member removed successfully");
      fetchTeamMembers(currentTeam.id);
    } catch (error) {
      message.error("Failed to remove member");
    }
  };

  const teamColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Owner",
      key: "owner",
      render: (text, record) =>
        `${record.owner.first_name} ${record.owner.last_name}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditTeam(record)}
            style={{ marginRight: "8px" }}
          >
            Edit
          </Button>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteTeam(record)}
            style={{ marginRight: "8px" }}
          >
            Delete
          </Button>
          <Button
            icon={<UsergroupAddOutlined />}
            onClick={() => handleManageMembers(record)}
          >
            Manage Members
          </Button>
        </>
      ),
    },
  ];

  const memberColumns = [
    { title: "Email", dataIndex: ["user", "email"], key: "email" },
    {
      title: "First name",
      dataIndex: ["user", "first_name"],
      key: "first_name",
    },
    { title: "Last name", dataIndex: ["user", "last_name"], key: "last_name" },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => role.charAt(0).toUpperCase() + role.slice(1), // Capitalize the first letter
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveMember(record.user.id)}
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" icon={<PlusOutlined />} onClick={handleAddTeam}>
        Add Team
      </Button>
      <Table
        columns={teamColumns}
        dataSource={teams}
        rowKey="id"
        loading={loading}
        style={{ marginTop: 16 }}
      />
      <TeamModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleModalOk}
        team={currentTeam}
      />
      <TeamMemberModal
        visible={isMemberModalVisible}
        onCancel={() => setIsMemberModalVisible(false)}
        onOk={handleAddMember}
        teamMembers={teamMembers}
        team={currentTeam}
        loading={loading}
        columns={memberColumns}
      />
      <DeleteTeamModal
        visible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        onOk={confirmDeleteTeam}
        team={teamToDelete}
      />
    </div>
  );
};

const TeamModal = ({ visible, onCancel, onOk, team }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (team) {
      form.setFieldsValue(team);
    } else {
      form.resetFields();
    }
  }, [team, form]);

  return (
    <Modal
      title={team ? "Edit Team" : "Add Team"}
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onCancel}
      okText={team ? "Edit Team" : "Add Team"}
      cancelText="Cancel"
    >
      <Form form={form} onFinish={onOk}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the team name!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const TeamMemberModal = ({
  visible,
  onCancel,
  onOk,
  teamMembers,
  team,
  loading,
  columns,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title={`Manage Members - ${team ? team.name : ""}`}
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onCancel}
      width={800}
      okText="OK"
      cancelText="Cancel"
    >
      <Form form={form} onFinish={onOk} layout="inline">
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please input the email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="role"
          label="Role"
          rules={[{ required: true, message: "Please select the role!" }]}
        >
          <Select placeholder="Select a role" style={{ width: 120 }}>
            <Option value="viewer">Viewer</Option>
            <Option value="editor">Editor</Option>
            <Option value="admin">Admin</Option>
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
          Add Member
        </Button>
      </Form>
      <Table
        columns={columns}
        dataSource={teamMembers}
        rowKey="id"
        loading={loading}
        style={{ marginTop: 16 }}
      />
    </Modal>
  );
};

const DeleteTeamModal = ({ visible, onCancel, onOk, team }) => (
  <Modal
    title="Delete Team"
    visible={visible}
    onOk={onOk}
    onCancel={onCancel}
    okText="Delete Team"
    cancelText="Cancel"
    okButtonProps={{ danger: true }}
  >
    Are you sure you want to permanently delete this team?
    <Card title="Selected Team" size="small" style={{ marginTop: 8 }}>
      <div>
        <Typography.Text strong>Team Name: </Typography.Text>
        <span>{team?.name}</span>
      </div>
    </Card>
  </Modal>
);

export default Teams;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  registerUser,
  loginUser,
  getUserProfile,
  changePassword,
  updateUserProfile,
  logoutUser,
} from "../api/Auth";

// Async thunk for user login
export const userLogin = createAsyncThunk(
  "user/login",
  async ({ credentials, rememberMe }, { dispatch, rejectWithValue }) => {
    try {
      const data = await loginUser(credentials, rememberMe);
      dispatch(
        userSlice.actions.setCredentials({
          token: data.access,
          tokenExpiresAt: new Date().getTime() + data.expiresIn * 1000,
        })
      );
      return data; // Return user data or token details received
    } catch (error) {
      return rejectWithValue(error.message); // Error message handling
    }
  }
);

// Async thunk for user registration
export const userRegister = createAsyncThunk(
  "user/register",
  async (userData, { rejectWithValue }) => {
    try {
      const user = await registerUser(userData);
      return user; // Return user data or token details received
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : "Network error"
      );
    }
  }
);

// Async thunk for fetching user profile
export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserProfile();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for updating user profile
export const updateUserProfileInfo = createAsyncThunk(
  "user/updateUserProfile",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await updateUserProfile(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for changing user password
export const changeUserPassword = createAsyncThunk(
  "user/changePassword",
  async (passwordData, { rejectWithValue }) => {
    try {
      const response = await changePassword(passwordData);
      return response;
    } catch (error) {
      if (error.current_password) {
        return rejectWithValue("Wrong password.");
      } else {
        return rejectWithValue(
          "Failed to change password due to an unexpected error."
        );
      }
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    tokenExpiresAt: null,
    isAuthenticated: false,
    profile: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      state.token = action.payload.token;
      state.tokenExpiresAt = action.payload.tokenExpiresAt;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      logoutUser(); // Clear tokens and local storage/session storage
      state.token = null;
      state.tokenExpiresAt = null;
      state.isAuthenticated = false;
      state.profile = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(userRegister.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userRegister.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(userRegister.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.profile = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateUserProfileInfo.fulfilled, (state, action) => {
        state.profile = { ...state.profile, ...action.payload };
        state.status = "succeeded";
      })
      .addCase(updateUserProfileInfo.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(changeUserPassword.fulfilled, (state) => {
        // Optionally handle post-password change state
        state.status = "succeeded";
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;

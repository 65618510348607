import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Tabs,
  Table,
  Divider,
  Popconfirm,
  Space,
} from "antd";

const { TabPane } = Tabs;

const dummyStandardChangeTypes = [
  {
    id: 1,
    name: "HTTP Status Code",
    selector: "",
    createdAt: "May 5, 2024, 9:37 a.m.",
  },
  {
    id: 2,
    name: "Redirect",
    selector: "",
    createdAt: "May 4, 2024, 10:08 p.m.",
  },
  {
    id: 3,
    name: "Deleted Page",
    selector: "",
    createdAt: "May 3, 2024, 8:54 p.m.",
  },
  {
    id: 4,
    name: "New Page",
    selector: "",
    createdAt: "May 3, 2024, 7:04 p.m.",
  },
  { id: 5, name: "H2", selector: "h2", createdAt: "April 26, 2024, 8:20 p.m." },
  { id: 6, name: "H1", selector: "h1", createdAt: "April 26, 2024, 8:20 p.m." },
  {
    id: 7,
    name: "Meta description",
    selector: "meta[name=description]",
    createdAt: "April 26, 2024, 8:20 p.m.",
  },
  {
    id: 8,
    name: "Title",
    selector: "title",
    createdAt: "April 26, 2024, 8:19 p.m.",
  },
];

const dummyCustomChangeTypes = [
  {
    id: 1,
    name: "Link blank & LinkedIn",
    selector: 'a[target="_blank"][title="LinkedIn"]',
    createdAt: "April 26, 2024, 8:21 p.m.",
  },
];

function ManageChangeTypesModal({ isOpen, onClose }) {
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [customChangeTypes, setCustomChangeTypes] = useState(
    dummyCustomChangeTypes
  );
  const [editingKey, setEditingKey] = useState("");

  const handleAddCustomChangeType = (values) => {
    setLoading(true);
    const newCustomChangeType = {
      id: customChangeTypes.length + 1,
      name: values.name,
      selector: values.selector,
      createdAt: new Date().toLocaleString(),
    };
    setCustomChangeTypes([...customChangeTypes, newCustomChangeType]);
    form.resetFields();
    setLoading(false);
  };

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    editForm.setFieldsValue({ name: "", ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      const row = await editForm.validateFields();
      const newData = [...customChangeTypes];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setCustomChangeTypes(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setCustomChangeTypes(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: true,
    },
    {
      title: "Selector",
      dataIndex: "selector",
      editable: false,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button onClick={() => save(record.id)} type="link">
              Save
            </Button>
            <Button type="link" onClick={cancel}>
              Cancel
            </Button>
          </Space>
        ) : (
          <Space>
            <Button
              type="link"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Delete Change Type"
              description={
                <>
                  Are you sure you want to delete this change type?
                  <br />
                  This action will also delete all related changelogs for this
                  change type and cannot be undone.
                </>
              }
              onConfirm={() => handleDelete(record.id)}
              okText="Yes, delete"
              cancelText="No, cancel"
            >
              <Button type="link" disabled={editingKey !== ""}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "createdAt" ? "text" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    if (!editing || dataIndex === "selector") {
      return <td {...restProps}>{children}</td>;
    }

    return (
      <td {...restProps}>
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </td>
    );
  };

  const handleDelete = (id) => {
    const newData = customChangeTypes.filter((item) => item.id !== id);
    setCustomChangeTypes(newData);
  };

  return (
    <Modal
      title="Manage Change Types"
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      okText="Close"
      width={1000}
    >
      <Tabs tabPosition="left" defaultActiveKey="1">
        <TabPane tab="Standard" key="1">
          <Table
            dataSource={dummyStandardChangeTypes}
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Selector",
                dataIndex: "selector",
                key: "selector",
              },
            ]}
            rowKey="id"
          />
        </TabPane>
        <TabPane tab="Custom" key="2">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddCustomChangeType}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input the name!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="selector"
              label="Selector"
              rules={[
                { required: true, message: "Please input the selector!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Add Custom Change Type
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <Form form={editForm} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={customChangeTypes}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
              rowKey="id"
            />
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default ManageChangeTypesModal;

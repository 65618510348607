import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTeams, getTeamMembers } from "../api/Teams";

// Async thunk for fetching teams
export const fetchTeams = createAsyncThunk("teams/fetchTeams", async () => {
  const response = await getTeams();
  return response;
});

const teamsSlice = createSlice({
  name: "teams",
  initialState: {
    teams: [],
    teamMembers: {},
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    setSelectedTeamId: (state, action) => {
      state.selectedTeamId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch teams
      .addCase(fetchTeams.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teams = action.payload.results; // Assuming the response has a 'results' field
      })
      .addCase(fetchTeams.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setSelectedTeamId } = teamsSlice.actions;
export default teamsSlice.reducer;

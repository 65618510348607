import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Input,
  Table,
  Typography,
  Row,
  Col,
  Card,
  Statistic,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useExperiments } from "../hooks/useExperiments";
import { getExperimentsSummary } from "../api/Experiments";
import ExperimentColumns from "./Experiments/ExperimentColumns";
import NewExperimentModal from "./Experiments/NewExperimentModal";
import DeleteExperimentModal from "./Experiments/DeleteExperimentModal";
import EditExperimentModal from "./Experiments/EditExperimentModal";

const { Search } = Input;

const Experiments = () => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [isNewModalVisible, setIsNewModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [summary, setSummary] = useState({
    total_experiments: 0,
    successful_experiments: 0,
    running_experiments: 0,
    unsuccessful_experiments: 0,
    unreviewed_experiments: 0,
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [filters, setFilters] = useState({
    searchTerm: "",
    filterType: "total",
  });
  const [selectedExperiment, setSelectedExperiment] = useState(null);
  const {
    experiments,
    loadingExperiments,
    totalExperiments,
    fetchExperiments,
  } = useExperiments(pagination, filters);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const data = await getExperimentsSummary(selectedWebsiteId);
        setSummary(data);
      } catch (error) {
        console.error("Failed to fetch experiment summary", error);
      }
    };

    fetchSummary();
  }, [selectedWebsiteId]);

  const handleCardClick = (filterType) => {
    setFilters((prev) => ({ ...prev, filterType }));
    setPagination((prev) => ({ ...prev, current: 1 }));
    console.log("JAAAA");
  };

  const handleSearchChange = (e) => {
    setFilters((prev) => ({ ...prev, searchTerm: e.target.value }));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    if (sorter.order) {
      const newSortField = Array.isArray(sorter.field)
        ? sorter.field.join("__")
        : sorter.field;
      const newSortOrder =
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : null;
      setFilters((prev) => ({
        ...prev,
        sortField: newSortField,
        sortOrder: newSortOrder,
      }));
    }
  };

  return (
    <div>
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        Experiments
      </Typography.Title>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <Card
            onClick={() => handleCardClick("total")}
            style={{
              cursor: "pointer",
              border:
                filters.filterType === "total"
                  ? "2px solid rgb(6, 82, 221)"
                  : "1px solid #f0f0f0",
            }}
          >
            <Statistic
              title="Total experiments"
              value={summary.total_experiments}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            onClick={() => handleCardClick("running")}
            style={{
              cursor: "pointer",
              border:
                filters.filterType === "running"
                  ? "2px solid rgb(6, 82, 221)"
                  : "1px solid #f0f0f0",
            }}
          >
            <Statistic
              title="Running experiments"
              value={summary.running_experiments}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card
            onClick={() => handleCardClick("successful")}
            style={{
              cursor: "pointer",
              border:
                filters.filterType === "successful"
                  ? "2px solid rgb(6, 82, 221)"
                  : "1px solid #f0f0f0",
            }}
          >
            <Statistic
              title="Succesfull experiments"
              value={summary.successful_experiments}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card
            onClick={() => handleCardClick("unsuccessful")}
            style={{
              cursor: "pointer",
              border:
                filters.filterType === "unsuccessful"
                  ? "2px solid rgb(6, 82, 221)"
                  : "1px solid #f0f0f0",
            }}
          >
            <Statistic
              title="Unsuccessful experiments"
              value={summary.unsuccessful_experiments}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card
            onClick={() => handleCardClick("unreviewed")}
            style={{
              cursor: "pointer",
              border:
                filters.filterType === "unreviewed"
                  ? "2px solid rgb(6, 82, 221)"
                  : "1px solid #f0f0f0",
            }}
          >
            <Statistic
              title="Unreviewed experiments"
              value={summary.unreviewed_experiments}
            />
          </Card>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Search
          placeholder="Search experiments"
          onChange={handleSearchChange}
          style={{ flexGrow: 1, marginRight: 8 }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setIsNewModalVisible(true);
          }}
          style={{ marginRight: 8 }}
        >
          New Experiment
        </Button>
        <Button type="primary">Export</Button>
      </div>
      <Table
        columns={ExperimentColumns({
          setSelectedExperiment,
          setIsDeleteModalVisible,
          setIsEditModalVisible,
        })}
        dataSource={experiments}
        rowKey="id"
        loading={loadingExperiments}
        onChange={handleTableChange}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: totalExperiments,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => {
            console.log("Page size change:", current, size);
            setPagination({ current, pageSize: size });
          },
          onChange: (page, pageSize) =>
            setPagination({ current: page, pageSize }),
        }}
      />
      <NewExperimentModal
        isOpen={isNewModalVisible}
        onClose={() => setIsNewModalVisible(false)}
      />
      <DeleteExperimentModal
        isOpen={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        selectedExperiment={selectedExperiment}
        fetchExperiments={fetchExperiments}
      />
      <EditExperimentModal
        isOpen={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
        selectedExperimentId={selectedExperiment?.id}
        fetchExperiments={fetchExperiments}
      />
    </div>
  );
};

export default Experiments;

import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Input, Segmented, message, Badge } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

function ImportKeywordModal({ isOpen, onClose }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedKeywordKeys, setSelectedKeywordKeys] = useState([]);
  const [selectedBrandedOption, setSelectedBrandedOption] = useState("both");
  const [selectedIntent, setSelectedIntent] = useState("all");

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      message.success("Keywords added successfully!");
      onClose();
    } catch (error) {
      message.error("Failed to add keywords. Please try again.");
    }
    setLoading(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    const uniqueKeys = [
      ...new Set([...selectedKeywordKeys, ...newSelectedRowKeys]),
    ];
    setSelectedKeywordKeys(uniqueKeys);
  };

  const onSelectDeselect = (record) => {
    const updatedKeys = selectedKeywordKeys.filter((key) => key !== record.key);
    setSelectedKeywordKeys(updatedKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeywordKeys,
    onSelect: (record, selected) => {
      if (selected === true) {
        onSelectChange([...selectedKeywordKeys, record.key]);
      } else {
        onSelectDeselect(record);
      }
    },
    onSelectAll: (selected, selectedRows) => {
      const currentVisibleKeys = filteredData.map((item) => item.key);
      setSelectedKeywordKeys(
        selected
          ? [...new Set([...selectedKeywordKeys, ...currentVisibleKeys])]
          : selectedKeywordKeys.filter(
              (key) => !currentVisibleKeys.includes(key)
            )
      );
    },
  };

  const getBrandedFilter = () => {
    switch (selectedBrandedOption) {
      case "branded":
        return (item) => item.isBranded === true;
      case "non-branded":
        return (item) => item.isBranded === false;
      case "both":
      default:
        return () => true;
    }
  };

  const getIntentFilter = () => {
    switch (selectedIntent) {
      case "informational":
      case "commercial":
      case "transactional":
        return (item) => item.intent === selectedIntent;
      case "all":
      default:
        return () => true;
    }
  };

  const applyFilters = () => {
    const brandedFilter = getBrandedFilter();
    const intentFilter = getIntentFilter();
    const searchFilter = (item) =>
      item.keys.some((key) => key.toLowerCase().includes(searchText));

    const filtered = data.filter(
      (item) => brandedFilter(item) && intentFilter(item) && searchFilter(item)
    );

    setFilteredData(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedBrandedOption, selectedIntent, searchText, data]);

  const onBrandedChange = (value) => {
    setSelectedBrandedOption(value);
  };

  const onIntentChange = (value) => {
    setSelectedIntent(value);
  };
  const onSearch = (e) => {
    const text = e.target.value.toLowerCase();
    setSearchText(text);
  };

  useEffect(() => {
    const initialData = [
      {
        key: "1",
        keys: ["helloid"],
        clicks: 321,
        impressions: 1032,
        ctr: 0.311046511627907,
        position: 1.37,
        isBranded: true,
        intent: "informational",
      },
      {
        key: "2",
        keys: ["tools4ever"],
        clicks: 315,
        impressions: 769,
        ctr: 0.4096228868660598,
        position: 5.1,
        isBranded: true,
        intent: "transactional",
      },
      {
        key: "3",
        keys: ["hello id"],
        clicks: 231,
        impressions: 587,
        ctr: 0.393526405451448,
        position: 1.62,
        isBranded: false,
        intent: "informational",
      },
      // Add more sample data...
    ];

    setData(initialData);
    setFilteredData(initialData);
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search Keywords`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys([e.target.value])}
          onPressEnter={() => {
            confirm();
            setSearchText(selectedKeys[0]);
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => {
            confirm();
            setSearchText(selectedKeys[0]);
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toLowerCase().includes(value.toLowerCase()),
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  const columns = [
    {
      title: "Keyword(s)",
      dataIndex: "keys",
      key: "keys",
      render: (keys) => keys.join(", "),
      sorter: (a, b) => a.keys[0].localeCompare(b.keys[0]),
      ...getColumnSearchProps("keys"),
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      key: "ctr",
      sorter: (a, b) => a.ctr - b.ctr,
      render: (value) => `${(value * 100).toFixed(2)}%`,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      sorter: (a, b) => a.position - b.position,
      render: (value) => value.toFixed(2),
    },
  ];

  const selectedKeywordsColumns = [
    {
      title: "Keywords",
      dataIndex: "keys",
      key: "keys",
      render: (text) => <a href={text}>{text}</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Button type="danger" onClick={() => onSelectDeselect(record)}>
          Remove
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title="Import New Keywords"
      open={isOpen}
      onCancel={onClose}
      onOk={handleFormSubmit}
      okText="Add Keywords"
      cancelText="Cancel"
      width={1000}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Filters:</h3>
          <Segmented
            options={[
              { label: "Last 7 days", value: 7 },
              { label: "Last 14 days", value: 14 },
              { label: "Last 30 days", value: 30 },
            ]}
            defaultValue={7}
            style={{ width: 300 }} // Adjust width as needed
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Branded/Non-Branded:</h4>
          <Segmented
            options={[
              { label: "Branded", value: "branded" },
              { label: "Non-Branded", value: "non-branded" },
              { label: "Both", value: "both" },
            ]}
            defaultValue="both"
            onChange={onBrandedChange}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Keyword Intent:</h4>
          <Segmented
            options={[
              { label: "Informational", value: "informational" },
              { label: "Commercial", value: "commercial" },
              { label: "Transactional", value: "transactional" },
              { label: "All", value: "all" },
            ]}
            defaultValue="all"
            onChange={onIntentChange}
          />
        </div>

        <div style={{ marginTop: 20 }}>
          <h3>Found Keywords:</h3>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search Keywords"
            value={searchText}
            onChange={onSearch}
          />
        </div>

        <Table
          columns={columns}
          dataSource={filteredData}
          rowSelection={rowSelection}
          pagination={{ pageSize: 10 }}
          style={{ marginTop: 8 }}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Selected Keywords:</h3>
          <Badge
            count={selectedKeywordKeys.length}
            overflowCount={5000}
            style={{ backgroundColor: "#0652DD" }}
          />
        </div>

        <Table
          dataSource={selectedKeywordKeys.map((key) => {
            const keyword = data.find((item) => item.key === key);
            return { key: keyword.key, keys: keyword.keys.join(", ") };
          })}
          columns={selectedKeywordsColumns}
          rowKey={(record) => record.key}
          pagination={false}
        />
      </div>
    </Modal>
  );
}

export default ImportKeywordModal;

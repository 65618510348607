import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getKeywords } from "../api/Keywords";
import { size } from "lodash";

const calculateSizeInKB = (data) => {
  const jsonString = JSON.stringify(data); // Convert data to JSON string
  const byteSize = new Blob([jsonString]).size; // Calculate byte size
  const sizeInKB = byteSize / 1024; // Convert to kilobytes
  return sizeInKB;
};

const fetchAllKeywords = async (selectedWebsiteId, filters) => {
  let allKeywords = [];
  let page = 1;
  const pageSize = 1000; // Adjust this based on your backend limit

  while (true) {
    const params = {
      page,
      page_size: pageSize,
      search: filters.searchTerm,
      last_days: filters.lastDays,
      ordering: filters.sortOrder
        ? `${filters.sortOrder === "asc" ? "" : "-"}${filters.sortField}`
        : undefined,
    };

    try {
      const data = await getKeywords(selectedWebsiteId, params);
      allKeywords = allKeywords.concat(data.results); // Combine fetched data
      if (!data.next) {
        break; // No more pages
      }
      page++; // Move to the next page
    } catch (error) {
      console.error("Failed to fetch keywords:", error);
      break;
    }
  }

  const sizeInKB = calculateSizeInKB(allKeywords); // Calculate size of the response in KB
  console.log("Size in KB: ", sizeInKB);
  return allKeywords;
};

export const useKeywords = (pagination, filters, fetchAll = false) => {
  const selectedWebsiteId = useSelector(
    (state) => state.websites.selectedWebsiteId
  );
  const [keywords, setKeywords] = useState([]);
  const [totalKeywords, setTotalKeywords] = useState(0);
  const [loadingKeywords, setLoadingKeywords] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const fetchKeywords = useCallback(async () => {
    if (!selectedWebsiteId) return;

    setLoadingKeywords(true);

    if (fetchAll) {
      const allKeywords = await fetchAllKeywords(selectedWebsiteId, filters);
      setKeywords(allKeywords);
    } else {
      const params = {
        page: pagination.current,
        page_size: pagination.pageSize,
        search: filters.searchTerm,
        last_days: filters.lastDays, // Assuming you want to filter by the date range
        ordering: filters.sortOrder
          ? `${filters.sortOrder === "asc" ? "" : "-"}${filters.sortField}`
          : undefined,
      };

      try {
        const data = await getKeywords(selectedWebsiteId, params);
        setKeywords(data.results);
        setTotalKeywords(data.count);
        setFilteredData(data.results);
      } catch (error) {
        console.error("Failed to fetch keywords:", error);
      }
    }

    setLoadingKeywords(false);
  }, [
    selectedWebsiteId,
    pagination.current,
    pagination.pageSize,
    filters.searchTerm,
    filters.lastDays,
    filters.sortOrder,
    filters.sortField,
  ]);

  useEffect(() => {
    fetchKeywords();
  }, [fetchKeywords]);

  return {
    keywords,
    loadingKeywords,
    totalKeywords,
    filteredData,
    setFilteredData,
  };
};
